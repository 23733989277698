import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import Cookies from 'js-cookie'
import store from 'signup/redux/store'

import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'

import SignupActions from 'signup/redux/actions/signup'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'
import Div from 'ui/elements/Div'
import Loader from 'client/modules/App/components/Loader'
import P from 'ui/elements/P'
import H1 from 'ui/elements/H1'
import Hr from 'ui/elements/Hr'

const mapStateToProps = (state, props) => {
  return {
    companyName: state.signup.company_name,
    ready: state.signup.ready,
    slug: state.signup.slug
  }
}

class Completing extends Component {
  static displayName = 'Completing'
  static propTypes = {
    t: PropTypes.func.isRequired,
    // Dispatch
    checkIsReady: PropTypes.func
  }

  state = {
    step: 0,
    completed: false
  }

  componentDidMount = () => {
    if (!window.created) {
      return RoutingUtils.navigate('/')
    }

    EventTracker.track('Signup: Completing')
    EventTracker.page()

    // Track capterra
    const ct = document.createElement('script')
    const s = document.getElementsByTagName('script')[0]

    ct.type = 'text/javascript'
    ct.async = true
    ct.src = 'https://ct.capterra.com/capterra_tracker.js?vid=2098003&vkey=70f9285bef7ff22ef2e03196d2fe02f7'
    s.parentNode.insertBefore(ct, s)

    this.timer = () => {
      // Immediately check if sign up is ready
      if (this.state.step === 0) {
        this.props.checkIsReady(this.setReady)
      }

      if (this.state.step === 4) {
        // Do nothing
      } else {
        this.setState({ step: this.state.step + 1 })
        setTimeout(this.timer, window.testMode ? 1000 : 8000)
      }
    }
    this.timer()
  }

  setReady = () => {
    this.setState({ completed: true, step: 4 })
  }

  render = () => {
    const { t } = this.props

    return (
      <Div modifiers={'height-100vh display-flex align-center'}>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://dc.ads.linkedin.com/collect/?pid=333924&conversionId=343684&fmt=gif"
        />
        <Div modifiers={'text-center margin-x-auto'}>
          <H1>
            {t('signup.completing.just_a_sec')}
          </H1>
          <Loader />
          {this.state.step !== 4 &&
            <Fragment>
              <P modifiers={'font-weight-bold font-size-lg'}>
                {t('signup.completing.setting_up')}
              </P>
              <Hr />
              <P>
                {t('signup.completing.creating')}
              </P>
            </Fragment>
          }
          {this.state.step === 4 &&
            <Fragment>
              <P modifiers={'font-weight-bold font-size-lg'}>
                {t('signup.completing.ready')}
              </P>
              <Hr />
              <P>
                {t('signup.completing.redirected')}
              </P>
            </Fragment>
          }
        </Div>
      </Div>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const checkIsReady = (setReady) => {
    SignupActions.isReady(window.id)

    const state = store.getState()
    const ready = state.signup.ready
    const slug = state.signup.slug
    const email = state.signup.email
    const token = state.signup.authentication_token
    const companyId = state.signup.company_id
    const employeeId = state.signup.employee_id
    const trackingData = state.signup.tracking_data

    const customerLabel = state.signup.customer_label
    const orderLabel = state.signup.order_label
    const quoteLabel = state.signup.quote_label
    const contractLabel = state.signup.contract_label
    const packingSlipLabel = state.signup.packing_slip_label

    const marketingSource = state.signup.source
    const marketingMedium = state.signup.medium
    const marketingCampaign = state.signup.campaign
    const marketingKeyword = state.signup.keyword

    const finishSignup = () => {
      setReady({ completed: true, step: 4 })
      Cookies.remove('signup.identifier')

      const data = store.getState().signup.data.toJS()
      const url = data.authenticated_url

      if (url) {
        // Signup came from oauth source
        data.company = slug
        data.email = email
        const params = Object.keys(data).map((key) => {
          return key + '=' + data[key]
        }).join('&')

        window.location = `${url}?${params}`
      } else {
        // Regular signup
        window.location = `${window.booqableProtocol}://${slug}.${window.booqableUrl}/?auth_token=${token}&signup=true`
      }
    }

    if (ready) {
      const response = EventTracker.alias(employeeId, () => {
        EventTracker.identify(employeeId, trackingData.get('identify_data').toJS(), () => {
          EventTracker.group(companyId, trackingData.get('group_data').toJS(), () => {
            // email and companyId are required for Google Enhanced Conversions
            const trackingData = {
              email,
              orderId: companyId,
              customerLabel,
              orderLabel,
              quoteLabel,
              contractLabel,
              packingSlipLabel,
              industry: state.signup.market,
              locale: window.locale,
              marketingSource,
              marketingMedium,
              marketingCampaign,
              marketingKeyword
            }

            EventTracker.track('Signup: Completed', trackingData, () => {
              finishSignup()
            })
          })
        })
      })

      if (response === window.analytics) {
        // Analytics not loaded and silently failed
        // rescue signup
        finishSignup()
      }
    } else {
      setTimeout(checkIsReady.bind(null, setReady), 1000)
    }
  }

  return {
    checkIsReady
  }
}

const DecoratedComponent = reduxPureConnect(mapStateToProps, mapDispatchToProps)(Completing)

export default withTranslation('signup')(DecoratedComponent)
