// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Product from './Product'
import Menu from './Menu'
import Page from './Page'
import Collection from './Collection'
import Link from './Link'

// Libraries

const types = {
  null: Menu,
  products: Product,
  pages: Page,
  collections: Collection
}

const Option = (props) => {
  const isLink =
    props.children?.startsWith?.('Create') || (props.value.startsWith('booqable://') && !props.selectProps?.category)
  const Component = isLink ? Link : types[props.selectProps?.category] || Menu

  return <Component {...props} />
}

Option.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  selectProps: PropTypes.shape({
    category: PropTypes.string
  })
}

export default Option
