import { combineReducers } from 'redux'
import uiConnect from 'shared/redux/interface'
import signupReducer from './signup'

const rootReducer = combineReducers({
  signup: signupReducer,
  interface: uiConnect.reducer
})

export default rootReducer
