import styled, { css } from 'styled-components'
import { unit, color, size, fontSize } from 'ui/themes/utils'

import I from 'ui/elements/I'
import B from 'ui/elements/B'
import Div from 'ui/elements/Div'

export const Bar = styled(Div)`
  display: flex;
  align-items: center;
  gap: ${size('padding')};

  background: ${color('white')};
  font-size: ${fontSize()};
  color: ${color('Text/Primary')};
  text-align: ${props => props.textAlign || 'left'};

  ${(props) => props.margin !== false && css`
    margin-bottom: ${size('margin', 'md')};
  `}

  padding: ${size('padding')};
  border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};

  border-radius: ${size('borderRadius')};
  border: ${size('borderWidth')} solid ${color('Misc/Divider')};

  > ${I} {
    font-size: ${unit('6')};
    color: ${props => color(props.type || 'Accent1/Base')};
  }

  transition: 0.25s all ease-out;

  ${({ state }) => (state === 'exited') && css`
    opacity: 0;
    transform: scaleY(0);
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    margin: 0;
  `}

  ${({ notice }) => notice && css`
    color: ${color('Warning/Foreground1')};
    padding: ${size('padding', 'sm')};
    background: ${color('Warning/Base')};
    margin-bottom: ${size('margin', 'md')};
    border-radius: ${size('borderRadius')};
    display: flex;
    align-items: stretch;
  `}

  ${(props) => props.square && css`
    border-radius: 0;
    border: none;
  `}

  ${props => (props.error || props.danger) && css`
    background: ${color('Danger/Base')};
  `}

  ${props => props.success && css`
    background: ${color('Success/Base')};
  `}

  ${props => props.info && css`
    background: ${color('Accent1/Base')};
  `}

  ${props => props.info && props.outline && css`
    background: ${color('white')};
    color: ${color('Accent1/Base')};
    border: ${size('borderWidth', 'xs')} solid ${color('Accent1/Base')};

    i {
      color: ${color('Accent1/Base')};
    }
  `}

  ${props => props.warning && css`
    background: ${color('Warning/Base')};
  `}

  ${props => props.neutral && css`
    color: ${color('Text/Primary')};
    background: ${color('white')};
    border: ${size('borderWidth', 'xs')} solid ${color('Misc/Divider')};
  `}

  ${props => props.noMargin && css`
    margin: 0;
  `}

  ${props => props.center && css`
    justify-content: center;
    text-align: center;
  `}

  ${props => props.maxWidth && css`
    max-width: 620px;
  `}

  ${props => props.fixedHeight && css`
    height: ${props.fixedHeight};
  `}

  ${props => props.fixedWidth && css`
    width: ${props.fixedWidth};
  `}
`

export const Container = styled.div`
  flex: 1;
`

export const Title = styled(B)`
  display: block;
`

export const Description = styled.div`
  margin: 0;
`
