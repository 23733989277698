import styled, { css } from 'styled-components'
import { size, color } from 'ui/themes/utils'

import Div from 'ui/elements/Div'

export const Spacer = styled(Div)`
  &&& {
    width: 100%;
    margin-top: ${size('margin')};

    ${props => !props.vertical && css`
      display: block !important;
    `}

    ${props => props.vertical && css`
      display: inline-block;

      width: 0;

      margin-top: 0;
      margin-left: ${size('margin')};

      ${props => props.border && css`
        height: 1em;

        margin-left: calc(${size('margin')} / 2);
        margin-right: calc(${size('margin')} / 2);

        border-left: 1px solid ${color('Misc/Divider')};
      `}
    `}
  }
`
