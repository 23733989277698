// React
import { Component, createElement } from 'react'
import PropTypes from 'prop-types'

// Libraries
import debounce from 'lodash/debounce'
import omit from 'lodash/omit'
import toString from 'lodash/toString'

// Components

// Shared

export default class InputDebounceWrapper extends Component {
  static displayName = 'InputDebounceWrapper'

  static propTypes = {
    wait: PropTypes.number,
    debounceOptions: PropTypes.shape({
      leading: PropTypes.bool,
      maxWait: PropTypes.number,
      trailing: PropTypes.bool
    }),
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func,
      value: PropTypes.any
    }).isRequired,
    skipChangeOnBlur: PropTypes.bool,
    verifyChange: PropTypes.bool
  }

  static defaultProps = {
    wait: 500
  }

  constructor (props) {
    super(props)

    this.state = {
      value: props.field.value,
      debounceStarted: false
    }
  }

  get wait () {
    return window.testMode ? 0 : this.props.wait
  }

  componentDidMount = () => {
    this.initializeOnChange()
  }

  componentWillUnmount = () => {
    this.onChange.flush()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.field.value !== this.props.field.value && this.state.value !== this.props.field.value) {
      this.setState({ value: this.props.field.value })
    }
  }

  initializeOnChange = () => {
    const { debounceOptions } = this.props

    this.onChange = debounce(this.sendChange, this.wait, debounceOptions)
  }

  sendChange = (value) => {
    this.props.field.onChange(value)
    this.setState({ debounceStarted: false })

    // TODO: This only exists for webpack/client/v2/react/scenes/order/lines/planning.jsx
    // Consider finding another way to do this
    // If change failed go trough, revert state
    if (this.props.verifyChange) {
      setTimeout(() => {
        if (this.props.field.value !== this.state.value) {
          this.setState({ value: this.props.field.value })
        }
      }, 100)
    }
  }

  handleBlur = (e) => {
    const { onBlur, onChange } = this.props.field
    const { skipChangeOnBlur } = this.props

    // Get the target value here
    let value

    if (e.target && e.target.value !== undefined) {
      value = e.target.value
    } else {
      value = e
    }

    onBlur && onBlur(value)

    if (!skipChangeOnBlur) {
      if (this.state.debounceStarted) {
        this.onChange.cancel()

        // Only trigger change when it has not
        // been changed a regular debounce
        onChange(value)

        this.setState({ debounceStarted: false })
        this.initializeOnChange()
      }
    }
  }

  handleChange = (e) => {
    // Get the target value here
    let value

    if (e.target && e.target.value !== undefined) {
      value = e.target.value
    } else {
      value = e
    }

    this.setState({ value, debounceStarted: true })
    this.onChange(value)
  }

  render () {
    const { component } = this.props
    const { onChange, onBlur, value, ...otherFieldProps } = this.props.field

    // get props that should be passed down
    const props = omit(this.props, ['options', 'component', 'field', 'verifyChange'])

    props.wait = this.wait

    return createElement(
      component,
      {
        field: {
          onChange: this.handleChange,
          value: (component.name === 'Input' ? toString(this.state.value) : this.state.value),
          onBlur: this.handleBlur,
          ...otherFieldProps
        },
        'data-debounce': this.props.wait,
        ...props
      }
    )
  }
}
