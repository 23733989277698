import styled, { css } from 'styled-components'
import { color, size, fontSize } from 'ui/themes/utils'

import InputElement from 'ui/elements/Input'
import I from 'ui/elements/I'

export const QuantityInput = styled.div`
  min-width: 85px;
`

export const ControlContainer = styled.div`
  position: relative;
  width: 35px;
`

export const Control = styled.a`
  position: absolute;
  display: block;

  right: 0;

  padding-top: 1px;

  height: 50%;
  width: 35px;

  background: ${color('Accent2/Background2')};
  color: ${color('Accent2/Foreground1')};

  font-size: calc(${fontSize()} - 2px);
  text-align: center;

  cursor: pointer;

  &:hover {
    /* 99 is the alpha value in hex (0.6 in decimal) */
    background: ${color('Accent2/Background2')}99;
    color: ${color('Accent2/Foreground1')}99;
  }

  &:active {
    background: ${color('Accent2/Controlvariant1')};
    color: ${color('Accent2/Foreground1')};
  }

  ${I} {
    vertical-align: middle;

    /* Legacy CSS override */
    margin: 0 !important;
  }

  ${props => props.plus && css`
    top: 0;

    border-top-right-radius:  ${size('borderRadius')};
    border-top: 1px ${color('Misc/Divider')} solid !important;
    border-right: 1px ${color('Misc/Divider')} solid !important;
  `}

  ${props => props.minus && css`
    bottom: 0;

    border-bottom-right-radius:  ${size('borderRadius')};
    border-bottom: 1px ${color('Misc/Divider')} solid !important;
    border-right: 1px ${color('Misc/Divider')} solid !important;
  `}

  ${props => props.disabled && css`
    /* 33 is the alpha value in hex (0.2 in decimal) */
    background: ${color('light')}33;
    color: ${color('Text/Primary')}33;

    cursor: default;

    &:hover {
      background: ${color('light')}33;
      color: ${color('Text/Primary')}33;
    }

    &:active {
      background: ${color('light')}33;
      color: ${color('Text/Primary')}33;
    }
  `}
`

export const Input = styled(InputElement).attrs(props => ({
  type: 'number'
}))`
  /* Temoporary css specificity hack (overrides the same hack in ui/elements/Input) */
  &&& {
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
`
