// React
import React from 'react'
import { createRoot } from 'react-dom/client'
import store from 'signup/redux/store'

// Libraries
import Cookies from 'js-cookie'
import cuid from 'cuid'
import { ThemeProvider } from 'styled-components'

// Actions
import SignupActions from 'signup/redux/actions/signup'

// Components
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import Completing from './completing'

// Other
import withRouter from 'shared/decorators/withRouter'
import theme from 'ui/themes/app'
import 'shared/utils/i18n'
import { RouteSetter } from 'shared/utils/Routing'

class App extends React.Component {
  static displayName = 'BooqableSignup'

  render () {
    const steps = [
      {
        path: '/:locale',
        component: Step1
      },
      {
        path: '/:locale/step-2',
        component: Step2
      },
      {
        path: '/:locale/step-3',
        component: Step3
      },
      {
        path: '/:locale/step-4',
        component: Step4
      },
      {
        path: '/:locale/step-5',
        component: Step5
      },
      {
        path: '/:locale/step-6',
        component: Step6
      },
      {
        path: '/:locale/step-7',
        component: Step7
      },
      {
        path: '/:locale/step-8',
        component: Step8
      },
      {
        path: '/:locale/completing',
        component: Completing
      }
    ]

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <RouteSetter />

            <Routes>
              {steps.map(step => (
                <Route key={step.path} path={step.path} element={withRouter(step.component)()} />
              ))}
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    )
  }
}

window.onload = () => {
  if (Cookies.get('signup.identifier')) {
    SignupActions.fetch(Cookies.get('signup.identifier')).then(() => {
      window.created = true
    })
  }
  window.id = Cookies.get('signup.identifier') || cuid()
  Cookies.set('signup.identifier', window.id)

  // Setup oauth data if present
  const container = document.getElementById('root')

  let data = container.getAttribute('data')
  const component = React.createElement(App, {})

  if (data) {
    data = JSON.parse(data)
    SignupActions.setInitialValues({ data: data })
  }

  const root = createRoot(container)

  root.render(component)
}
