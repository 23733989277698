import styled, { css } from 'styled-components'

import { injectModifiers, color, size, fontWeight } from 'ui/themes/utils'
import modifiers from 'ui/themes/modifiers'

import H3 from 'ui/elements/H3'
import Div from 'ui/elements/Div'
import P from 'ui/elements/P'
import Table from 'ui/blocks/Table'

export const Title = styled(H3)`
  padding: ${size('padding', 'md')} ${size('padding', 'md')} 0 ${size('padding', 'md')};
`

export const TitleLink = styled.span`
  float: right;
  font-weight: ${fontWeight('normal')};
`

export const Description = styled(Div)`
  padding: ${size('padding', 'sm')} ${size('padding', 'md')} ${size('padding', 'md')};
  border-bottom: 1px solid ${color('Misc/Divider')};

  ${P}:first-child {
    margin-top: 0;
  }

  ${P}:last-child {
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  padding: ${size('padding', 'md')};

  ${P} {
    color: ${color('Text/Secondary')};
  }

  /* If the content block starts with a P, it needs to margin */
  > ${P}:first-child {
    margin-top: 0;
  }

  ${({ theme }) => injectModifiers(modifiers(theme))}
`

export const Footer = styled.div`
  padding: ${size('padding', 'md')};

  border-top: 1px solid ${color('Misc/Divider')};
`

export const ContentBlock = styled.div`
  width: 100%;

  ${props => !props.noMax && css`
    max-width: 620px;
  `}

  ${props => props.settingsWidth && css`
    max-width: 927.5px;
  `}

  ${props => props.width && css`
    width: ${props.width}px;
  `}

  background: ${props => color(props.backgroundColor || 'white')};
  border: 1px solid ${color('Misc/Divider')};
  border-radius: ${size('borderRadius')};

  text-align: ${props => props.textAlign || 'left'};

  ${props => props.clean && css`
    border: 0;

    > ${Content} {
      padding: 0;
    }
  `}

  ${props => props.paddedTitle && css`
    ${Title} {
      border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};
      margin-bottom: ${(size('margin', 'sm'))};
      padding: ${size('padding', 'md')} ${size('padding', 'md')} ${size('padding', 'sm')} ${size('padding', 'lg')};
    }
  `}

  ${props => props.tableFit && css`
    > ${Content} {
      padding: 0;
    }

    ${Title} {
      padding: ${size('padding', 'md')} ${size('padding', 'md')} 0 ${size('padding', 'md')};
    }

    ${Table.Cell}:first-child, ${Table.CellLink}:first-child {
      padding-left: ${size('padding', 'md')};
    }

    ${Table.Cell}:last-child, ${Table.CellLink}:last-child {
      padding-right: ${size('padding', 'md')};
    }
  `}

  ${props => props.noBackground && css`
    border: 0;
    background: transparent;
  `}

  ${({ backgroundImage }) => backgroundImage && css`
    background: url(${backgroundImage}) no-repeat;
    background-size: cover;

    ${({ backgroundColor }) => backgroundColor && css`
      background-color: ${color(backgroundColor)};
    `}
  `}

  ${({ curvedHeaderColor }) => curvedHeaderColor && css`
    ${Title} {
      padding: ${size('padding', 'md')} ${size('padding', 'md')} ${size('padding', 'lg')};

      background-color: ${color(curvedHeaderColor)};

      /* Curved corners */
      border-top-left-radius: calc(${size('borderRadius', 'md')} - 1px);
      border-top-right-radius: calc(${size('borderRadius', 'md')} - 1px);
      border-bottom-left-radius: 59% 22%;
      border-bottom-right-radius: 100% 72%;
    }
  `}

  ${({ curvedContentColor }) => curvedContentColor && css`
    ${Content} {
      background-image: radial-gradient(circle at -17%,
        ${color(curvedContentColor)} 0%,
        ${color(curvedContentColor)} 70%, #fff 70%
      );
    }
  `}

  ${props => props.maxHeight && css`
    min-height: 100%;
  `}

  ${props => props.height && css`
    height: ${props.height};
  `}

  ${props => props.noBorder && css`
    border: none;
  `}

  ${props => props.relative && css`
    position: relative;
  `}

  ${props => props.noOverflow && css`
    overflow: hidden;
  `}

  ${props => props.align && css`
    > ${Content} {
      text-align: ${props.align};
    }
  `}

  ${({ stickyFooter }) => stickyFooter && css`
    position: relative;

    > ${Footer} {
      position: sticky;

      background-color: ${color('white')};

      bottom: -90px;

      border-bottom-left-radius: ${size('borderRadius')};
      border-bottom-right-radius: ${size('borderRadius')};

      @media (min-width: 1280px) {
        bottom: -${size('padding', 'lg')}
      }
    }
  `}

  ${({ theme }) => injectModifiers(modifiers(theme))}

  ${Table.HeadCell}:first-child {
    border-top-left-radius: ${size('borderRadius')};
  }

  ${Table.HeadCell}:last-child {
    border-top-right-radius: ${size('borderRadius')};
  }

  ${Table.Row}:first-child {
    ${Table.Cell} {
      border-top: none;
    }
  }

  ${Table.Row}:last-child {
    ${Table.Cell}:first-child, ${Table.CellLink}:first-child {
      border-bottom-left-radius: ${size('borderRadius')};
    }

    ${Table.Cell}:last-child, ${Table.CellLink}:last-child {
      border-bottom-right-radius: ${size('borderRadius')};
    }
  }
`
