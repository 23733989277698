import styled, { css } from 'styled-components'
import { color, size, fontSize, fontWeight } from 'ui/themes/utils'

import TextAreaComponent from 'react-textarea-autosize'

const TextArea = styled(TextAreaComponent)`
  height: 100%;
  width: 100%;

  padding: ${size('padding')};

  color: ${color('Text/Primary')};
  background: ${color('white')};

  border: ${size('borderWidth')} solid ${color('Misc/Divider')};
  font-weight: ${fontWeight('lighter')};
  font-size: ${fontSize()};

  border-radius: ${size('borderRadius')};
  appearance: none;

  ${props => props.visibleOnHover && css`
    border-color: transparent;
    background: transparent;
  `}

  ${props => props.readOnly && css`
    color: ${color('Text/Tertiary')} !important;
    border-color: ${color('Misc/Divider')} !important;
  `}

  ${props => props.error && css`
    border-color: ${color('Danger/Base')} !important;
  `}

  ${props => !props.error && css`
    &:hover {
      border-color: ${color('borderHover')};
    }

    &:focus {
      border-color: ${color('Primary/Base')};

      &:hover {
        border-color: ${color('Primary/Base')};
      }
    }
  `}

  ${props => props.disabled && css`
    background: ${color('Background/Base')};

    pointer-events: none;
  `}

  &::placeholder {
    color: ${color('Text/Tertiary')} !important;
    font-size: ${fontSize()} !important;
  }

  ${props => props.monospace && css`
    font-family: monospace;
  `}
`

export default TextArea
