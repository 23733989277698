import styled, { css } from 'styled-components'
import { color } from 'ui/themes/utils'

export const NoticeBar = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;

  @media print {
    display: none;
  }

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`

export const Border = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;

  ${(props) => props && css`
    border: 4px solid ${color(props.color || 'Accent1/Base')};
  `}
`

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${color('white')};
  opacity: 0.6;
`
