// React
import React, { Component, Fragment, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { connect as reduxConnect } from 'react-redux'

// Libraries
import qs from 'qs'
import keys from 'lodash/keys'
import _uniq from 'lodash/uniq'
import _isEqual from 'lodash/isEqual'
import _max from 'lodash/max'
import { withTranslation } from 'react-i18next'

// Components
import Paginator from 'ui/components/Paginator'
import Cleanstate from 'ui/components/Cleanstate'
import Button from 'ui/components/Button'
import Spacer from 'ui/blocks/Spacer'

// Shared

/**
 * Collection
 *
 * Handles fetching data, pagination, filtering and sorting
 *
 * Child receives the following props:
 *
 *  records: Data returned from fetchCollection
 *
 *  sort: What the collection is currently being sorted on
 *
 *  filters: What the collection is currently being filtered on
 *
 *  onSort: sort callback
 *
 *  onFilter: filter callback
 *
 *  omUpdateCollection: sort and filter callback
 *
 * @example
 *   <Collection
 *    model="stock_items"
 *    fetchCollection={this.props.fetchStockItem}
 *    fetchAdditional={this.props.fetchStockItemPlannings}
 *    paginate
 *   >
 *    <StockItemTable />
 *   </Collection>
 */
export class Collection extends Component {
  static displayName = 'Collection'

  static propTypes = {
    children: PropTypes.node.isRequired,
    fetchCollection: PropTypes.func,
    onFetchedCollection: PropTypes.func,
    fetchAdditional: PropTypes.func,
    fetchOnMount: PropTypes.bool,
    fetchInterval: PropTypes.number,
    // Pagination
    strategy: PropTypes.oneOf(['default', 'append']),
    paginate: PropTypes.bool,
    paginatePosition: PropTypes.oneOf(['left', 'right']),
    per: PropTypes.number,
    page: PropTypes.number,
    renderCleanstate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    // Sorting/Filtering
    sort: PropTypes.string,
    preserveStateSorting: PropTypes.bool,
    filters: PropTypes.object,
    // Persist params in url
    persistParams: PropTypes.bool,
    // Data
    collection: PropTypes.object,
    // etc
    hideLoadMore: PropTypes.bool,
    modifiers: PropTypes.object,
    t: PropTypes.func
  }

  static defaultProps = {
    page: 1,
    per: 20,
    filters: {},
    sort: '-created_at',
    fetchOnMount: true,
    strategy: 'default'
  }

  constructor (props) {
    super(props)

    this.state = {
      page: props.page,
      total: 0,
      meta: {},
      sort: props.sort,
      ids: [],
      filters: props.filters,
      loading: false
    }
  }

  componentDidMount = () => {
    const { persistParams, fetchOnMount, page, sort, filters, fetchInterval } = this.props

    if (fetchInterval) {
      this.interval = setInterval(this.handleFetchCollection, fetchInterval)
    }

    if (persistParams) {
      // Fetch params from the URL in the next render tick
      // else it will get the previous URL
      setTimeout(() => {
        const params = qs.parse(window.location.href.split('?')[1])

        // Typecast filters
        if (params.filters) {
          Object.keys(params.filters).forEach((key) => {
            if (params.filters[key] === 'true') {
              params.filters[key] = true
            }

            if (params.filters[key] === 'false') {
              params.filters[key] = false
            }
          })
        }

        this.setState({
          page: params.page || page,
          sort: params.sort || sort,
          filters: params.filters || filters
        }, () => {
          if (fetchOnMount) {
            this.handleFetchCollection()
          }
        })
      }, 0)
    } else {
      if (fetchOnMount) {
        this.handleFetchCollection()
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const fetchCollectionChanged = prevProps.fetchCollection?.toString() !== this.props.fetchCollection?.toString()

    const childrenTypeChanged = prevProps.children?.type?.toString() !== this.props.children?.type?.toString()
    const filtersChanged = !_isEqual(prevProps.filters, this.props.filters)

    if (filtersChanged) {
      this.handleUpdateFilters()
    }

    if (this.props.children && (fetchCollectionChanged || childrenTypeChanged)) {
      this.handleFetchCollection()
    }
  }

  componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  get records () {
    const { collection, preserveStateSorting } = this.props
    const { ids } = this.state

    if (!collection) return []

    const filteredCollection = {}

    if (preserveStateSorting) {
      if (collection.getById) {
        // Filter collection to preserve state sorting
        return collection.filter(entry => ids.includes(entry.id))
      } else {
        // Iterate over record keys to preserve state sorting
        Object.keys(collection).forEach(id => {
          if (ids.includes(id)) {
            filteredCollection[id] = collection[id]
          }
        })

        return filteredCollection
      }
    } else {
      if (collection.getById) {
        // Get by id to preserve request sorting
        return collection.getById(ids)
      } else {
        // Iterate over ids to preserve request sorting
        ids.forEach(id => { filteredCollection[id] = collection[id] })

        return filteredCollection
      }
    }
  }

  get canLoadMore () {
    const { per, strategy } = this.props
    const { page, total } = this.state

    return page * per < total && strategy === 'append'
  }

  updateURL = () => {
    if (!this.props.persistParams) return

    const prevParams = qs.parse(window.location.href.split('?')[1])
    const params = (({ page, sort, filters }) => ({ ...prevParams, page, sort, filters }))(this.state)

    window.history.pushState({}, '', qs.stringify(params, { addQueryPrefix: true, skipNulls: true }))
  }

  handleSort = (sort) => {
    if (sort !== this.state.sort) {
      this.setState({ sort }, this.handleFetchCollection)
    }
  }

  handleUpdateFilters = () => {
    this.setState({ filters: this.props.filters, page: 1 }, this.handleFetchCollection)
  }

  handleFilter = (filters) => {
    // Make sure to reset page
    this.setState({ filters, page: 1 }, this.handleFetchCollection)
  }

  handlePageChange = (page) => {
    this.setState({ page }, this.handleFetchCollection)
  }

  handleUpdateCollection = ({ filters, sort }) => {
    const newState = {}

    if (filters) {
      newState.filters = filters

      // Make sure to reset page
      newState.page = 1
    }

    if (sort) {
      newState.sort = sort
    }

    this.setState(newState, this.handleFetchCollection)
  }

  handleScroll = (event) => {
    const el = event.target
    const maxScroll = el.scrollHeight - el.clientHeight
    // Percentage of scroll height to reduce trigger by
    // so we don't have to scroll exactly to the bottom
    const bufferPercentage = 5
    const buffer = 100 / maxScroll * 100 * bufferPercentage

    if (el.scrollTop >= maxScroll - buffer) {
      this.handleLoadMore()
    }
  }

  handleLoadMore = () => {
    const { per } = this.props
    const { ids, total, page, loading } = this.state

    // Only try to load the next page if there's anything left to show
    if (Math.min(ids.length, page * per) < total && !loading) {
      this.setState({ page: page + 1 }, this.handleFetchCollection)
    }
  }

  handleInsertRecords = (newIds) => {
    const ids = _uniq(this.state.ids.concat(newIds))

    return this.setState({ ids })
  }

  handleFetchCollection = () => {
    const { page, sort, filters } = this.state
    const { per, fetchAdditional, fetchCollection, strategy, onFetchedCollection } = this.props

    if (!fetchCollection) return

    this.updateURL()

    this.setState({ loading: true })

    return fetchCollection?.({ page, per, sort, filters }).then((data) => {
      let ids = []

      if (data.payload.response?.data) {
        // Support for JSON API data
        ids = [data.payload.response.data].flat().map((d) => d.id)
      } else if (data.payload.records) {
        ids = keys(data.payload.records)
      }

      const meta = data.payload.response.meta || {}
      const total = _max([meta.total_count, meta.stats?.total?.count])

      // Append results if the strategy is append
      // and we're not on the first page (as that indicates filtering/new collection)
      if (strategy === 'append' && page !== 1) {
        ids = _uniq(this.state.ids.concat(ids))
      }

      this.setState({ ids, total, meta, loading: false }, () => {
        onFetchedCollection && onFetchedCollection(this.records)
      })

      if (fetchAdditional) {
        fetchAdditional(ids)
      }
    })
  }

  renderCleanstate = () => {
    if (typeof this.props.renderCleanstate === 'function') {
      return this.props.renderCleanstate()
    } else if (this.props.renderCleanstate) {
      return (
        <Cleanstate
          icon="search"
          loading={this.state.loading}
        >
          {!this.state.loading && this.props.t('common.nothing_found')}
        </Cleanstate>
      )
    }

    return null
  }

  renderLoadMore = () => {
    const { per } = this.props
    const { page, total } = this.state
    const totalMoreCount = total - (page * per)
    const moreCount = Math.min(totalMoreCount, per)

    if (!this.canLoadMore) return null

    return (
      <Fragment>
        <Spacer />
        <Button
          block
          color="white"
          data-tid="Collection load more"
          onClick={this.handleLoadMore}
        >
          {this.props.t('common.load_count_more', { count: moreCount })}
        </Button>
      </Fragment>
    )
  }

  renderPagination = (props) => {
    const { per, paginatePosition, modifiers } = this.props
    const { page, total } = this.state

    return (
      <Paginator
        paginatePosition={paginatePosition}
        page={page}
        per={per}
        total={total}
        onPageChange={this.handlePageChange}
        modifiers={modifiers?.paginator}
        {...props}
      />
    )
  }

  render () {
    const { children, paginate, per, hideLoadMore } = this.props
    const { sort, filters, page, total, loading, meta } = this.state
    const cleanstate = total <= 0 && this.renderCleanstate()

    const totalMoreCount = total - (page * per)
    const moreCount = Math.min(totalMoreCount, per)

    if (!children) return null

    return (
      <Fragment>
        {
          cleanstate ||
          cloneElement(children, {
            records: this.records,
            sort,
            filters,
            onSort: this.handleSort,
            onFilter: this.handleFilter,
            onPageChange: this.handlePageChange,
            onScroll: this.handleScroll,
            onLoadMore: this.handleLoadMore,
            refresh: this.handleFetchCollection,
            insertRecords: this.handleInsertRecords,
            updateCollection: this.handleFetchCollection,
            renderLoadMore: this.renderLoadMore,
            renderPagination: this.renderPagination,
            onUpdateCollection: this.handleUpdateCollection,
            canLoadMore: this.canLoadMore,
            moreCount,
            total,
            loading,
            ...meta
          })
        }
        {paginate && this.renderPagination()}
        {!hideLoadMore && this.renderLoadMore()}
      </Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  let collection

  if (props.getCollectionFromState) {
    collection = props.getCollectionFromState(state)
  } else {
    collection = state.orm[props.model]
  }

  return {
    collection
  }
}

export default withTranslation()(reduxConnect(mapStateToProps, null)(Collection))
