import axios from 'axios'
import store from 'signup/redux/store'
import { Promise } from 'bluebird'

const instance = axios.create()

// Bluebird config
Promise.config({
  warnings: false
})

Promise.onPossiblyUnhandledRejection((error) => {
  if (error && error?.message !== 'requestError') {
    throw error
  }
})

const handleResponse = (response) => {
  if (response.headers['x-read-only-mode'] === 'true') {
    store.dispatch({ type: 'SET_READ_ONLY_MODE', payload: { read_only_mode: true } })
  }
}

const handleSuccess = (response) => {
  handleResponse(response)

  return response
}

const handleError = (error) => {
  handleResponse(error.response)

  return Promise.reject(error)
}

instance.interceptors.response.use(handleSuccess, handleError)

export default instance
