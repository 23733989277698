import {
  Popover,
  Content,
  Inner,
  Arrow,
  TippyArrow,
  Wrapper
} from './styles'

Popover.Content = Content
Popover.Inner = Inner
Popover.Arrow = Arrow
Popover.TippyArrow = TippyArrow
Popover.Wrapper = Wrapper

export default Popover
