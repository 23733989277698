// React
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Libraries
import { HexColorPicker } from 'react-colorful'

// Components
import BColorPicker from 'ui/blocks/ColorPicker'
import Popover from 'ui/components/Popover'
import Icon from 'ui/components/Icon'
import Hr from 'ui/elements/Hr'
import Spacer from 'ui/blocks/Spacer'

// Shared
import { isValidHexColor } from 'ui/themes/utils'

/**
 * ColorPicker component.
 *
 * @example
 *   <ColorPicker field={field} />
 */
export default class ColorPicker extends Component {
  static displayName = 'ColorPicker'

  static propTypes = {
    field: PropTypes.object,
    error: PropTypes.any,
    size: PropTypes.oneOf(['sm', 'md', 'lg'])
  }

  static defaultProps = {
    size: 'md',
    field: {}
  }

  get defaultColors () {
    return ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#DCE775', '#FF8A65', '#BA68C8']
  }

  get valid () {
    return isValidHexColor(this.props.field.value)
  }

  handlePickColor = (color) => {
    const { field } = this.props

    field.onChange &&
      field.onChange({ target: { value: color.toUpperCase() } })
  }

  handleBlur = (e) => {
    const { onBlur, onChange, value } = this.props.field

    // Valid hex color but missing the #
    if (isValidHexColor('#' + value)) {
      // Prepend the #
      onChange('#' + value)
    }

    // Convert 3 character hex codes to 6 character
    if (isValidHexColor(value) && value.length === 4) {
      const baseValue = value.replace('#', '')
      // Double each character to get valid 6 character equivalent
      const sixCharacterValue = baseValue.split('').map(color => color + color).join('')

      onChange('#' + sixCharacterValue?.toUpperCase())
    }

    onBlur(e)
  }

  render () {
    const { field, ...otherProps } = this.props

    // Necessary to tell react this input is controlled
    const value = field.value || ''

    return (
      <BColorPicker>
        <BColorPicker.Input
          {...field}
          onBlur={this.handleBlur}
          value={value}
          {...otherProps}
          type="text"
        />
        <Popover direction="right" fallbackPlacements={['left']} content={this.renderColorPicker} method="click">
          <Fragment>
            <BColorPicker.Preview value={value} />
            {!this.valid && <Icon icon="eye-dropper" />}
          </Fragment>
        </Popover>
      </BColorPicker>
    )
  }

  renderColorPicker = () => (
    <BColorPicker.Picker>
      <HexColorPicker
        color={this.props.field.value}
        onChange={this.handlePickColor}
      />
      <Spacer size="sm" />
      <Hr noMargin />
      <Spacer size="sm" />
      <BColorPicker.Palette>
        {this.defaultColors.map((color) => (
          <BColorPicker.Color
            key={color}
            value={color}
            title={color}
            onClick={this.handlePickColor.bind(null, color)}
          />
        ))}
      </BColorPicker.Palette>
    </BColorPicker.Picker>
  )
}
