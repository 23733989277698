import styled, { css } from 'styled-components'
import { color, fontSize, fontWeight } from 'ui/themes/utils'

import Div from 'ui/elements/Div'

export const Description = styled(Div)`
  margin: 0;

  color: ${color('Text/Secondary')};

  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('lighter')};
  line-height: 20px;

  ${props => props.inline && css`
    display: inline;
  `}
`

export default Description
