// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import cuid from 'cuid'
import { Formik, Form as FormikForm } from 'formik'
import _isArray from 'lodash/isArray'
import _get from 'lodash/get'
import _template from 'lodash/template'
import _mapValues from 'lodash/mapValues'

// Components

// Shared

const scanString = (string, regex) => {
  if (!string.replace) {
    return []
  }

  const result = []

  string.replace(regex, function () {
    result.push(Array.prototype.slice.call(arguments, 1, -2))
  })

  return result
}

const processNestedAttributeError = (error, errors, values, data) => {
  const message = data[error]
  const index = scanString(error, /\[(.*)\]/g)[0][0]
  const attribute = error.split('.')[1]

  let nestedAttribute = scanString(error, /(.*)\[/g)[0][0]

  nestedAttribute = `${nestedAttribute}_attributes`

  if (!errors[nestedAttribute]) {
    errors[nestedAttribute] = []

    values[nestedAttribute].forEach(() => {
      errors[nestedAttribute].push({})
    })
  }

  const fullMessage = errors[nestedAttribute][index] || {}

  fullMessage[attribute] = message
  errors[nestedAttribute][index] = fullMessage
}

const onSubmit = (sendToServer, options, values, bag, initialValues, setReinitialize, t) => {
  const onResolve = (data) => {
    options.handleReset()
    setReinitialize(true)

    if (options.serverSuccess) {
      options.serverSuccess(values, data)
    }
  }

  const onReject = (data) => {
    // Throw error if it's a named error
    if (data instanceof Error && data.name !== 'Error') {
      throw data
    }

    if (data.response) data = data.response.data

    if (options.serverFailure) {
      options.serverFailure(values, data)
    }
    const errors = {}

    // JSONAPI logic, makes data backwards compatible
    if (data instanceof Array) {
      const _data = {}

      // This is a not a simple field validation error
      if (data[0].code && !_get(data[0], 'source.pointer')) {
        if (data[0].meta) {
          for (const error in data[0]) {
            errors[error] = data[0][error]
          }

          console.log(errors)

          bag.setErrors(errors)
        }

        return Promise.reject(data)
      }

      // Adjust form errors
      data.forEach((error) => {
        if (error.source || error.code) {
          const field = error.source.pointer.split('/').reverse()[0] || error.code

          if (!_data[field]) {
            _data[field] = []
          }

          _data[field].push(error.meta.message)
        }
      })

      data = _data
    }

    if (data.errors) data = data.errors

    if (_isArray(data)) {
      data.forEach((error) => {
        const pointer = _get(error, 'source.pointer')

        if (!pointer) return

        const attribute = pointer.replace('relationships/', '').split('/').pop()

        if (errors[attribute]) return

        let message

        if (options.translations) {
          const translationKeys = []

          // Try to translate using the error code
          if (error.code) translationKeys.push(`user.public_errors.${error.code}`)

          // Fallback to the error title
          translationKeys.push(error.meta.message)

          // If the first key has no translation, the fallback message is shown
          // handled by parseMissingKeyHandler
          message = t(translationKeys)

          // Try to translate using the error title
          // Do string interpolation for variables
          const template = _template(message, {
            interpolate: /%{([\s\S]+?)}/g
          })

          message = template(error.meta)
        } else {
          message = error.meta.message
        }

        errors[attribute] = message
      })
    } else {
      for (const error in data) {
        if (error.code && error.title) {
        } else if (scanString(error, /\[.*\]/g).length === 0) {
          errors[error] = data[error]
        } else {
          processNestedAttributeError(error, errors, values, data)
        }
      }
    }

    bag.setErrors(errors)

    return Promise.reject(errors)
  }

  return sendToServer(values, bag, initialValues).then(onResolve, onReject)
}

/**
 * Example on how to use a form with state manipulated by handleSubmit.
 * Note that `props.submitForm` is actually `props.handleSubmit` decorated with
 * validation and other form logic. Make sure to always call `props.submitForm`
 * when submitting a form.
 *
 * @example
 *   class SomeComponent extends Components {
 *     state = {
 *       editing: false
 *     }
 *
 *     handleSubmit = (e) => {
 *       e && e.preventDefault()
 *
 *       const promise = props.submitForm()
 *
 *       promise.then(() => this.setState({ editing: false }))
 *
 *       return promise
 *     }
 *
 *     render () {
 *       <Form onSubmit={this.handleSubmit}>
 *         {...}
 *       </Form>
 *     }
 *   }
 *
 *   const mapDispatchToProps = (state, props) => {
 *     return {
 *       handleSubmit: (values) => {
 *         return SomeAction.update({
 *           id: props.id,
 *           attributes: values
 *         })
 *       }
 *     }
 *   }
 *
 *   const decoratedComponent = withForm()(SomeComponent)
 *
 *   export default reduxConnect(mapStateToProps, mapDispatchToProps)(decoratedComponent)
 */
const withForm = (formOptions = {}, options = {}) => (WrappedComponent) => {
  return class extends Component {
    static propTypes = {
      initialValues: PropTypes.object.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      serverFailure: PropTypes.func,
      serverSuccess: PropTypes.func,
      validate: PropTypes.func,
      validationSchema: PropTypes.any,
      isInitialValid: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
      ]),
      resetForm: PropTypes.func,
      // Won't set the dirty state in window.dirtyForms
      ignoreDirty: PropTypes.bool,
      enableReinitialize: PropTypes.bool,
      validateOnMount: PropTypes.bool,
      validateOnBlur: PropTypes.bool,
      validateOnChange: PropTypes.bool,
      initialTouched: PropTypes.object,
      t: PropTypes.func
    }

    constructor (props) {
      super(props)

      this.state = {
        id: cuid(),
        submitPromise: null,
        enableReinitialize: true
      }
    }

    componentDidMount = () => {
      // Initiate dirty state (used by batman routing)
      // should only be set to undefined to ignore it (e.g. prepare for route change)
      // null is fine as undefined !== null
      window.dirtyForms = window.dirtyForms || {}
      window.dirtyForms[this.state.id] = null
    }

    componentWillUnmount = () => {
      // Make sure we clean up the reference
      // to the form's dirty state.
      if (window.dirtyForms) delete window.dirtyForms[this.state.id]
    }

    setReinitialize = (state) => {
      this.setState({ enableReinitialize: state })
    }

    handleSubmit = (values, bag) => {
      const { initialValues, handleSubmit, serverFailure, serverSuccess } = this.props

      this.setReinitialize(false)

      const promise = onSubmit(
        handleSubmit,
        { serverSuccess, serverFailure, handleReset: this.handleReset, translations: options.translations },
        Object.assign({}, values),
        bag,
        initialValues,
        this.setReinitialize,
        this.props.t
      )

      this.setState({ submitPromise: promise })

      return promise
    }

    handleReset = () => {
      if (window.dirtyForms) delete window.dirtyForms[this.state.id]
      this.setState({ submitPromise: null })
    }

    renderForm = (props) => {
      if (!window.dirtyForms) window.dirtyForms = {}

      // Make sure the form dirty state hasn't been unset in handleReset
      if (window.dirtyForms && !this.props.ignoreDirty &&
        window.dirtyForms[this.state.id] !== undefined &&
        // and it's different than the formik dirty state
        window.dirtyForms[this.state.id] !== props.dirty
      ) {
        // Then set the new dirty state
        window.dirtyForms[this.state.id] = props.dirty
      }

      return <WrappedComponent
        {...this.props}
        {...props}
        {...formOptions}
        submitPromise={this.state.submitPromise}
      />
    }

    computedFormOptions = () => _mapValues(formOptions, (option) => {
      // Form options can be passed as literal values, or as generator functions.
      // Here we compute the final values for options passed as functions.
      return (option instanceof Function) ? option(this.props) : option
    })

    render () {
      const {
        initialValues,
        validate,
        isInitialValid,
        validationSchema,
        enableReinitialize,
        validateOnMount,
        validateOnBlur,
        validateOnChange,
        initialTouched
      } = this.props

      return <Formik
        initialValues={initialValues || {}}
        isInitialValid={isInitialValid}
        validationSchema={validationSchema}
        validate={validate}
        onSubmit={this.handleSubmit}
        onReset={this.handleReset}
        enableReinitialize={enableReinitialize === undefined
          ? this.state.enableReinitialize
          : enableReinitialize
        }
        validateOnMount={validateOnMount}
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
        initialTouched={initialTouched || {}}
        {...this.computedFormOptions()}
      >{(props) => this.renderForm(props)}</Formik>
    }
  }
}

export const Form = ({ children, onSubmit, noValidate }) => {
  const props = {}

  if (onSubmit) {
    props.onSubmit = onSubmit
  }

  if (noValidate) {
    props.noValidate = noValidate
  }

  return <FormikForm {...props}>
    {children}
    <button type="submit" style={{ display: 'none' }} />
  </FormikForm>
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  // Disable HTML5 browser validation
  noValidate: PropTypes.bool
}

export default withForm
export { FieldArray } from 'formik'
export { default as Field } from './field'
export { buildFormPropTypes } from './prop_types'
export { encodeFieldName, decodeFieldName } from './utils'
