// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect as reduxConnect } from 'react-redux'

// Components
import Select from 'ui/components/Select'

// Libraries

// Shared
import moment from 'shared/lib/moment'

class TimeSelect extends Component {
  static displayName = 'TimeSelect'

  static propTypes = {
    value: PropTypes.any,
    intervalInMinutes: PropTypes.number,
    format: PropTypes.string,
    disabledTimes: PropTypes.shape({
      before: PropTypes.any,
      after: PropTypes.any
    }),
    isTimeSelectable: PropTypes.func,
    useAMOrPM: PropTypes.bool,
    everythingIsSelectable: PropTypes.bool,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    intervalInMinutes: 15
  }

  state = {
    selectOptions: []
  }

  componentDidMount = () => {
    this.selectOptions()
  }

  componentDidUpdate = (prevProps) => {
    const { currentSelection, intervalInMinutes } = this.props

    // Picker value changed, recalculate options
    if (
      prevProps[currentSelection]?.getTime() !== this.props[currentSelection]?.getTime() ||
      prevProps.intervalInMinutes !== intervalInMinutes ||
      prevProps.operatingRules !== this.props.operatingRules ||
      prevProps.disabled !== this.props.disabled ||
      prevProps.isTimeSelectable !== this.props.isTimeSelectable
      ) {
      this.selectOptions()
    }
  }

  get forcedValue () {
    const { field } = this.props

    if (!field.value) return undefined

    return {
      label: this.getLabel(field.value),
      value: field.value
    }
  }

  get momentFormat () {
    return this.props.useAMOrPM ? 'hh:mm A' : 'HH:mm'
  }

  getLabel = (date) => {
    if (!moment.isMoment(date)) date = moment(date, 'HH:mm')

    return date.format(this.momentFormat)
  }

  selectOptions = () => {
    const { everythingIsSelectable, isTimeSelectable, disabledTimes, disabled, field } = this.props
    const options = []

    if (disabled && field?.value) {
      // Field is disabled but has a value
      // Add value to options so it gets displayed
      options.push(this.forcedValue)

      this.setState({ selectOptions: options })
    }

    if (disabled) return options

    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes = minutes + this.props.intervalInMinutes) {
        const time = moment().hour(hour).minute(minutes)

        if (disabledTimes && disabledTimes.before) {
          const time = moment(disabledTimes.before).hour(hour).minute(minutes)
          const beforeTime = moment(disabledTimes.before)

          if (time < beforeTime) {
            continue
          }
        }

        if (disabledTimes && disabledTimes.after) {
          const time = moment(disabledTimes.after).hour(hour).minute(minutes)
          const afterTime = moment(disabledTimes.after)

          if (time > afterTime) {
            continue
          }
        }

        const label = this.getLabel(time)
        const value = time.format('HH:mm')

        if (everythingIsSelectable) {
          options.push({ label, value })
        }

        if (isTimeSelectable && isTimeSelectable(value)) {
          options.push({ label, value })
        }
      }
    }

    this.setState({ selectOptions: options })
  }

  render () {
    const selectProps = {}

    const isNotDefaultInterval = this.props.intervalInMinutes !== 15

    if (isNotDefaultInterval) {
      selectProps.forceValue = true
      selectProps.value = this.forcedValue
    }

    return (
      <Select
        {...this.props}
        {...selectProps}
        options={this.state.selectOptions}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    operatingRules: state.orm.operating_rules
  }
}

export default reduxConnect(mapStateToProps)(TimeSelect)
