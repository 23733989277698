// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BRadioGroup from 'ui/blocks/RadioGroup'
import RadioBox from './RadioBox'
import ButtonBox from './ButtonBox'
import Icon from 'ui/components/Icon'

// Shared

/**
 * Group of radio buttons with optional children.
 *
 * @example
 *   <RadioGroup field={} options={[]} exclusive />
 *
 * @param {bool} exclusive - Only renders the children of the selected radio
 * if set to true.
 */
export default class RadioGroup extends Component {
  static displayName = 'RadioGroup'

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
      ]),
      children: PropTypes.node
    })).isRequired,
    onChange: PropTypes.func,
    field: PropTypes.object,
    exclusive: PropTypes.bool,
    centered: PropTypes.bool,
    type: PropTypes.string,
    form: PropTypes.object,
    size: PropTypes.string,
    displayGrid: PropTypes.bool,
    bordered: PropTypes.bool,
    // error directly when the form has errors
    errorOnSubmit: PropTypes.bool,
    'data-tid': PropTypes.string,
    modifiers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  }

  onChange = (value) => {
    const { field, form } = this.props

    field.onChange(value)

    // Use setTimeout so touch gets set on next render tick (after field gets it's value)
    setTimeout(() => {
      form && form.setFieldTouched(field.name, true, true)
    }, 1)
  }

  renderOptions = (options) => {
    const { exclusive, field, type, size, bordered, modifiers, ...otherProps } = this.props
    const { value } = field

    if (type === 'buttonGroup') {
      return (
        <BRadioGroup.Buttons {...otherProps}>
          {options.map((option) =>
            <ButtonBox
              size={size}
              {...option}
              checked={value === option.value}
              key={option.value}
              onClick={this.onChange.bind(this, option.value)}
              modifiers={modifiers}
            />
          )}
        </BRadioGroup.Buttons>
      )
    }

    return options.map((option) =>
      <RadioBox
        {...option}
        checked={value === option.value}
        key={option.value}
        onClick={this.onChange.bind(this, option.value)}
        exclusive={exclusive}
        bordered={bordered}
        data-tid={`Checked ${value === option.value}`}
        modifiers={modifiers}
      />
    )
  }

  render () {
    const {
      options,
      type,
      initialError,
      error,
      form,
      touched,
      hideErrorLabel,
      errorOnSubmit,
      bordered,
      'data-tid': dataTid,
      modifiers,
      displayGrid
    } = this.props

    const formDirty = form && form.dirty
    const hasSubmitted = form && form.submitCount > 0

    const displayError = (errorOnSubmit && error && hasSubmitted) || (
      initialError && formDirty && error) ||
      (hasSubmitted && !touched && error)

    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    return (
      <BRadioGroup
        type={type}
        centered={false}
        error={displayError && error}
        bordered={bordered}
        data-tid={dataTid}
        modifiers={nestedModifiers.self}
        displayGrid={displayGrid}
      >
        {this.renderOptions(options)}
        {displayError && !hideErrorLabel && (
          <BRadioGroup.Error><Icon icon="exclamation-triangle" style="far" /> {error}</BRadioGroup.Error>
        )}
      </BRadioGroup>
    )
  }
}
