import styled, { css, keyframes } from 'styled-components'

import { color } from 'ui/themes/utils'

import Div from 'ui/elements/Div'

export const Loader = styled(Div)`
  width: 100%;

  ${props => !props.fullScreen && css`
    height: 140px;
  `}

  ${props => props.fullScreen && css`
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
    background: ${color('Background/Base')};
  `}
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Logo = styled.img`
  width: 140px;

  ${props => !props.fullScreen && css`
    margin-top: 32px;
  `}

  ${props => props.fullScreen && css`
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    margin-top: -37px;
    margin-left: -70px;
  `}

  animation: ${spin} 2s infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
`
