import styled, { css } from 'styled-components'
import { injectModifiers, color } from 'ui/themes/utils'
import modifiers from 'ui/themes/modifiers'

const P = styled.p`
  ${({ theme }) => injectModifiers(modifiers(theme))}
  ${(props) => props.color === 'light' && css`
    color: ${color('Text/Secondary')};
  `}
`

export default P
