import { InputField, Label, Error, Valid, Description, LabelContainer, Link } from './styles'

InputField.Label = Label
InputField.Error = Error
InputField.Valid = Valid
InputField.Description = Description
InputField.LabelContainer = LabelContainer
InputField.Link = Link

export default InputField
