import RedactorX from 'shared/lib/redactorx'

export default function initAlignPlugin () {
  if (window.alignPluginInitialized) return

  RedactorX.add('plugin', 'alignment', {
    translations: {
      en: {
        alignment: {
          alignment: 'Alignment'
        }
      }
    },
    defaults: {
      align: {
        left: 'align-left',
        center: 'align-center',
        right: 'align-right',
        justify: 'align-justify'
      }
    },
    start () {
      const button = {
        title: '## alignment.alignment ##',
        command: 'alignment.popup',
        blocks: {
          all: 'editable'
        }
      }

      this.app.toolbar.add('alignment', button)
    },
    popup (params, button) {
      const segments = {}
      const obj = this.opts.alignment.align

      for (const key in obj) {
        if (!obj[key]) continue
        segments[key] = { name: obj[key], prefix: 'align' }
      }

      // create
      this.app.popup.create('alignment', {
        setter: 'alignment.setAlign',
        getter: 'alignment.getAlign',
        form: {
          align: {
            type: 'segment',
            label: '## alignment.alignment ##',
            segments
          }
        }
      })

      // open
      this.app.popup.open({ button })
    },
    getAlign () {
      const obj = this.opts.alignment.align

      if (!obj) return false

      const instance = this.app.block.get()
      const $block = instance.getBlock()

      let value = 'left'

      for (const key in obj) {
        if ($block.hasClass(obj[key])) {
          value = key
        }
      }

      return { align: value }
    },
    setAlign (popup) {
      this.app.popup.close()

      // get data
      const data = popup.getData()
      const instance = this.app.block.get()

      instance.setClassFromObj(this.opts.alignment.align, data.align)
    }
  })

  window.alignPluginInitialized = true
}
