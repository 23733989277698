class Period {
  static LENGTHS = {
    years: 31557600,
    months: 2592000,
    weeks: 604800,
    days: 86400,
    hours: 3600,
    minutes: 60
  }

  static ALIASES = {
    year: 'years',
    month: 'months',
    week: 'weeks',
    day: 'days',
    hour: 'hours',
    minute: 'minutes'
  }

  static fromSeconds = (seconds, preferredUnit = 'auto') => {
    return new Period(seconds).calculate(preferredUnit)
  }

  static fromPeriod = (value, unit) => {
    return Period._fetchUnitLength(unit) * value
  }

  static _fetchUnitLength = (unit) => {
    const alias = Period.ALIASES[unit]

    if (alias) {
      unit = alias
    }

    return Period.LENGTHS[unit]
  }

  constructor (seconds) {
    this.seconds = seconds
  }

  calculate = (unit = 'auto') => {
    let candidates = ['years', 'months', 'weeks', 'days', 'hours', 'minutes']

    if (typeof unit === 'string') {
      if (unit === 'auto') {
        unit = this.unitForLength(this.seconds)
      }

      this.period = Period.ALIASES[unit] || unit
      this.value = this.seconds / Period._fetchUnitLength(unit)
    } else {
      if (Array.isArray(unit)) {
        candidates = candidates.filter((period) => unit.includes(period))
      }

      candidates.forEach((period) => {
        if (!this.period && this._matches(period)) {
          this.period = period
        }
      })
    }

    if (!this.period) {
      this.period = 'seconds'
      this.value = this.seconds
    }

    return {
      period: this.period,
      value: Math.round(this.value)
    }
  }

  unitForLength = (length) => {
    for (const unit in Period.LENGTHS) {
      const unitLength = Period.LENGTHS[unit]

      if (length >= unitLength && Number.isInteger(length / unitLength)) {
        return unit
      }
    }
  }

  _matches = (period) => {
    const value = this.seconds / Period._fetchUnitLength(period)
    if (Math.round(value) === value) {
      this.value = value

      return true
    }

    return false
  }
}

export default Period
