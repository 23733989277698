import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * Native anchor links don't work well on initial page load as translations are not loaded yet so
 * IDs that depend on them are not loaded yet.
 *
 * To fix this, use this element which will:
 * 1. Set up an element with ID attribute set for native anchor tag to work
 * 2. Manually scroll into view elements on load
 */
function Anchor ({ id, children }) {
  const selfRef = useRef(null)

  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const anchorElementId = currentUrl.hash.replace('#', '')

    if (anchorElementId === id) {
      selfRef.current.scrollIntoView()
    }
  }, [id])

  return (
    <span ref={selfRef} id={id}>
      {children}
    </span>
  )
}

Anchor.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired
}

export default Anchor
