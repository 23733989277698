// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import BLoader from '../blocks/Loader'

// Assets
import logo from '@assets/images/logo-small.svg'

export default class Loader extends Component {
  static propTypes = {
    modifiers: PropTypes.string,
    fullScreen: PropTypes.bool
  }

  render () {
    const { fullScreen, modifiers } = this.props

    return (
      <BLoader fullScreen={fullScreen} modifiers={modifiers}>
        <BLoader.Logo src={logo} fullScreen={fullScreen} />
      </BLoader>
    )
  }
}
