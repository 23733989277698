import {
  Table,
  Row,
  CellLink,
  Cell,
  Head,
  HeadCell,
  Body,
  VisibleOnHover,
  Dragger,
  PositionContainer,
  DraggableTable,
  GroupContainer,
  GroupHeader,
  GroupHeaderTitle,
  GroupHeaderSubtitle
} from './styles'

Table.Row = Row
Table.Cell = Cell
Table.CellLink = CellLink
Table.HeadCell = HeadCell
Table.Head = Head
Table.Body = Body
Table.VisibleOnHover = VisibleOnHover
Table.Dragger = Dragger
Table.PositionContainer = PositionContainer
Table.DraggableTable = DraggableTable
Table.GroupContainer = GroupContainer
Table.GroupHeader = GroupHeader
Table.GroupHeaderTitle = GroupHeaderTitle
Table.GroupHeaderSubtitle = GroupHeaderSubtitle

export default Table
