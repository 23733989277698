import React from 'react'
import PropTypes from 'prop-types'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'

// Components
import { Row, Col } from 'ui/components/Grid'
import Div from 'ui/elements/Div'
import ReadyOnlyModeBar from './ReadOnlyModeBar'
import Logo from './Logo'
import SignIn from './SignIn'
import LocaleSwitcher from './LocaleSwitcher'
import Img from 'ui/elements/Img'
import H2 from 'ui/elements/H2'
import H3 from 'ui/elements/H3'
import H4 from 'ui/elements/H4'
import P from 'ui/elements/P'
import Strong from 'ui/elements/Strong'
import Badge from 'ui/components/Badge'
import B from 'ui/elements/B'

// Shared
import customerReview from 'shared/utils/reviews'

// Assets
import starFull from '@assets/images/signup/star-full.svg'
import starHalf from '@assets/images/signup/star-half.svg'
import logoCapterra from '@assets/images/signup/capterra-logo.svg'
import logoGetapp from '@assets/images/signup/getapp-logo.svg'

class SignupLayout extends React.Component {
  static displayName = 'SignupLayout'

  static propTypes = {
    step: PropTypes.number.isRequired,
    socialProof: PropTypes.bool,
    t: PropTypes.func.isRequired,
    Trans: PropTypes.func.isRequired,
    children: PropTypes.node,
    industry: PropTypes.string,
    subtitleBadge: PropTypes.bool,
    stepIndicator: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }

  state = {
    review: {}
  }

  componentDidMount = () => {
    // Sets the customer review based on the industry url parameter
    const industry = this.props.industry
    const context = industry ? 'general' : 'signup'

    this.setState({ review: customerReview(context, industry) })
  }

  render () {
    const { step, socialProof, subtitleBadge, stepIndicator, t, Trans } = this.props
    const { reviewKey, reviewName, reviewCompany, reviewImage } = this.state.review

    return (
      <Div className="signup-setup step-two" id="signup">
        <ReadyOnlyModeBar />

        <Div id="main-content">
          <Div className="main-content-wrapper">
            <Row id="main-row" modifiers={'no-margin'}>

              <Col lg={socialProof ? 8 : 12} sm="12" id="signup-col" modifiers={'no-padding'}>
                <Div className="form">
                  <Logo />

                  <LocaleSwitcher />

                  <SignIn />

                  <Div className="form-wrapper">

                    <Div className="text-container">
                      {stepIndicator &&
                        <Div modifiers={'text-Primary/Base text-uppercase margin-bottom-sm'}>
                          {stepIndicator}
                        </Div>
                      }
                      <H2 className="title" modifiers={'margin-bottom-sm'}>
                        {t(`signup.step_${step}.title`)}
                      </H2>
                      {subtitleBadge &&
                        <Badge
                          flatColor="light"
                          modifiers={'text-Text/Secondary font-size-md font-weight-normal'}
                          multiLine
                        >
                          <Trans
                            i18nKey={`signup.step_${step}.subtitle`}
                            components={{ Bold: <B /> }}
                          />
                        </Badge>
                      }
                      {!subtitleBadge &&
                        <H3 modifiers={'font-size-md font-weight-lighter'}>
                          {t(`signup.step_${step}.subtitle`)}
                        </H3>
                      }
                    </Div>

                    {this.props.children}
                  </Div>
                </Div>
              </Col>

              {socialProof &&
                <Col lg="4" sm="12" id="social-proof-col" modifiers={'no-padding'}>
                  <Div className="social-proof">
                    <Div className="copy">
                      <H4>{t('common.social_proof.you_are_in_good_company')}</H4>
                      <P>
                        “{t(`common.social_proof.reviews.${reviewKey}`)}“
                      </P>

                      <Div className="review">
                        <Img src={reviewImage} alt={reviewName} modifiers={'rounded-circle'} />
                        <Div className="text">
                          <Strong>{reviewName}</Strong><br />
                          {reviewCompany}
                        </Div>
                      </Div>

                      <Div className="rating">
                        <Div className="stars-with-text">
                          <Div className="stars">
                            <Img src={starFull} alt="Rating star" />
                            <Img src={starFull} alt="Rating star" />
                            <Img src={starFull} alt="Rating star" />
                            <Img src={starFull} alt="Rating star" />
                            <Img src={starHalf} alt="Rating star" />
                          </Div>
                          <Div className="text">
                            <Strong>4.8</Strong> {t('signup.social_proof.overall_rating')}<br />
                            {t('signup.social_proof.based_on_reviews')}
                          </Div>
                        </Div>
                        <Div className="logos">
                          <Img src={logoCapterra} alt="Capterra" className="capterra" />
                          <Img src={logoGetapp} alt="Getapp" className="getapp" />
                        </Div>
                      </Div>

                    </Div>
                  </Div>
                </Col>
              }

            </Row>
          </Div>
        </Div>
      </Div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    industry: props.searchParams?.get('industry')
  }
}

const decoratedComponent = reduxPureConnect(mapStateToProps, null)(SignupLayout)

export default withTranslation('signup')(decoratedComponent)
