// React
import React from 'react'
import PropTypes from 'prop-types'

// Libraries
import { components } from 'react-select'
import { withTranslation } from 'shared/utils/withTranslation'

// Components
import BUrlSelect from 'ui/blocks/UrlSelect'
import Icon from 'ui/components/Icon'
import Loader from 'ui/components/Loader'
import P from 'ui/elements/P'

const Menu = (props) => {
  const isLoading = props.selectProps?.loading
  const onBackClick = props.selectProps?.onBackClick
  const optionsAmount = props.selectProps.total || props.options?.length
  const optionsLabel = isLoading ? '...' : `${optionsAmount} item${optionsAmount !== 1 ? 's' : ''}`

  return (
    <BUrlSelect.Menu>
      <components.Menu {...props}>
        <BUrlSelect.MenuHeader onClick={onBackClick}>
          <Icon icon="chevron-left" />
          <P>{props.t('common.actions.back')}</P>
          <P>{optionsLabel}</P>
        </BUrlSelect.MenuHeader>
        {isLoading ? (
          <BUrlSelect.LoaderContainer>
            <Loader color="primary" size="lg" />
          </BUrlSelect.LoaderContainer>
        ) : (
          <components.MenuList {...props} />
        )}
      </components.Menu>
    </BUrlSelect.Menu>
  )
}

Menu.propTypes = {
  selectProps: PropTypes.shape({
    loading: PropTypes.bool,
    onBackClick: PropTypes.func,
    total: PropTypes.number
  }),
  options: PropTypes.array,
  children: PropTypes.node,
  t: PropTypes.func,
  // react-select internal props
  innerProps: PropTypes.object
}

export default withTranslation()(Menu)
