// React
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'
import Yup from 'shared/utils/yup'

// Components
import Layout from 'signup/components/Layout'
import Button from 'ui/components/Button'
import Div from 'ui/elements/Div'
import Select from 'signup/components/Select'

// Shared
import withForm, { Form } from 'shared/form'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import SignupActions from 'signup/redux/actions/signup'
import { implementationTimelineData, yesNoData } from './static/marketingData'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'

export class Step8 extends React.Component {
  static displayName = 'Step8'
  static propTypes = {
    t: PropTypes.func.isRequired,
    values: PropTypes.shape({
      implementation_timeline: PropTypes.string,
      import_data: PropTypes.string
    }),
    submitPromise: PropTypes.instanceOf(Promise)
  }

  componentDidMount = () => {
    EventTracker.page()
    EventTracker.track('Signup: Step 8')
  }

  render = () => {
    const { submitPromise, t } = this.props

    return (
      <Layout step={8} stepIndicator={t('signup.step_8.step_indicator')}>
        <Form>
          <Select
            identifier="implementation_timeline"
            options={implementationTimelineData(t)}
          />

          <Select
            identifier="import_data"
            options={yesNoData(t)}
          />

          <Div modifiers={'margin-top-lg margin-top-md-xl'}>
            <Button
              size="lg"
              type="submit"
              promise={submitPromise}
              block
              borderRadius={'mdplus'}
              modifiers={'margin-bottom-xl'}
            >
              {t('signup.enter_your_account')}
            </Button>
          </Div>
        </Form>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      implementation_timeline: state.signup.implementation_timeline || '',
      import_data: state.signup.import_data || ''
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (values) => {
      return SignupActions.complete(window.id, values)
    },
    validationSchema: Yup.object().shape({
      implementation_timeline: Yup
        .string()
        .required(),
      import_data: Yup
        .string()
        .required()
        .oneOf(['yes', 'no'])
    }),
    serverSuccess: () => {
      RoutingUtils.navigate(`/${window.locale}/completing`)
    }
  }
}

export default compose(
  withTranslation('signup'),
  reduxPureConnect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Step8)
