// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import Popover from 'ui/components/Popover'
import BPopover from 'ui/blocks/Popover'

// Shared

/**
 * Block: Tooltip.
 *
 * @example
 *   <Tooltip direction="right" content={'Imagine good copy here'}><Children /></Tooltip>
 */
export default class Tooltip extends Component {
  static displayName = 'Tooltip'

  static propTypes = {
    direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node,
    center: PropTypes.bool,
    disabled: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    offset: PropTypes.number,
  }

  static defaultProps = {
    offset: 10
  }

  render () {
    const {
      children,
      direction,
      content,
      disabled,
      maxWidth,
      onMouseEnter,
      onMouseLeave,
      center,
      offset
    } = this.props

    return (
      <Popover
        useContentWrapper={false}
        interactive={false}
        direction={direction}
        method="hover"
        offset={offset}
        content={content && (
          <BPopover.Content
            disabled={disabled}
            direction={direction}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            tippy
            notFixed
          >
            <BPopover.Inner maxWidth={maxWidth} center={center}>
              {content}
              <BPopover.Arrow direction={direction} />
            </BPopover.Inner>
          </BPopover.Content>
        )}
      >
        {children}
      </Popover>
    )
  }
}
