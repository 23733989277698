// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import Cookies from 'js-cookie'

// Components
import BPhoneInput from 'ui/blocks/PhoneInput'
import 'react-phone-number-input/style.css'
import PhoneNumberInput from 'react-phone-number-input'

// Shared
import privateDataProps from 'shared/privateDataProps'

/**
 * Input for phone number fields
 *
 * @example
 *   <PhoneNumberInput field={field} />
 */
export default class PhoneInput extends Component {
  static displayName = 'PhoneNumberInput'

  static propTypes = {
    field: PropTypes.object,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    error: PropTypes.bool,
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    countryCode: PropTypes.string
  }

  static defaultProps = {
    size: 'md',
    field: {}
  }

  state = {
    countryCode: null
  }

  componentDidMount = () => {
    this.handleIdentification()
  }

  handleIdentification = async () => {
    const locationCookies = Cookies.get('identification.location')

    if (!locationCookies) {
      let response

      try {
        response = await fetch('https://identify.booqable.com/location.json')
      } catch(e) {
        return
      }

      if (response.ok) {
        const json = await response.json()

        Cookies.set('identification.location', json.country_code, { expires: 1 })
        this.setState({ countryCode: json.country_code })
      }
    } else {
      this.setState({ countryCode: locationCookies })
    }
  }

  render () {
    const {
      field,
      placeholder,
      readOnly,
      error,
      valid,
      disabled
    } = this.props
    const { countryCode } = this.state

    return (
      <BPhoneInput
        readonly={readOnly}
        error={error}
        valid={valid}
        disabled={disabled}
      >
        <PhoneNumberInput
          placeholder={placeholder}
          onFocus={this.handlefocus}
          onBlur={this.handleBlur}
          defaultCountry={countryCode}
          smartCaret={false}
          {...privateDataProps}
          {...field}
        />
      </BPhoneInput>
    )
  }
}
