
// React
import React from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import DatePickerInput from 'ui/components/DatePickerInput'

// Shared
import SettingsUtils from 'shared/utils/settings'
import moment from 'shared/lib/moment'

/**
 * Timeinput: Wrapper to allow using DateTimePicker for
 * picking pure time values with no associated date
 *
 * @example
 *   <TimeInput
 *     field={{ value: "12:00", onChange: () => {} }}
 *     format="HH:mm A"
 *   />
 */

export default class TimeInput extends React.Component {
  static displayName = 'TimeInput'

  static propTypes = {
    format: PropTypes.string,
    field: PropTypes.object
  }

  static defaultProps = {
    field: {}
  }

  formatValue = () => {
    // We need a valid date to pass to DatePicker
    const fakeTime = moment(`2000-01-01 ${this.props.field.value}`)

    return fakeTime.toISOString()
  }

  onChange = (value) => {
    // Convert date back into hours
    const date = moment(value)

    this.props.field.onChange(`${date.format('HH:mm')}`)
  }

  render () {
    const { field, format } = this.props

    const timeFormat = format ||
      SettingsUtils.get('dates.use_am_pm') ? 'hh:mm A' : 'HH:mm'
    const value = this.formatValue()

    const fieldProps = {
      onChange: this.onChange,
      onBlur: field.onBlur,
      value: value
    }

    return (
      <DatePickerInput
        timeOnly
        format={timeFormat}
        field={fieldProps}
      />
    )
  }
}
