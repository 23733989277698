// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import Link from 'ui/components/Link'

// Shared
import { Routing as RoutingUtils } from 'client/v2/utils/utils'

/**
 * HelpCenterLink component
 *
 * @example
 *   <HelpCenterLink href="https://help.booqable.com" />
 */
export default class HelpCenterLink extends Component {
  static displayName = 'HelpCenterLink'

  static propTypes = {
    href: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.array
    ])
  }

  render () {
    const { children, href, ...otherProps } = this.props

    return (
      <Link
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        data-track-event="Help center link clicked"
        onClick={RoutingUtils.navigateLink}
        {...otherProps}
      >
        {children.length !== 0 && <span>{children}</span>}
      </Link>
    )
  }
}
