// React
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import BFontSelect from 'ui/blocks/FontSelect'

// Libraries
import { components } from 'react-select'

const BASE_URL = 'https://fonts.bunny.net/css?family='

const Option = (props) => (
  <Fragment>
    <link href={BASE_URL + props.value} rel="stylesheet" />
    <BFontSelect font={props.label}>
      <components.Option {...props} />
    </BFontSelect>
  </Fragment>
)

Option.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string
}

export default Option
