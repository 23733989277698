// React
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Component
import BSlider from 'ui/blocks/Slider'
import Input from 'ui/elements/Input'
import Label from 'ui/elements/Label'
import Span from 'ui/elements/Span'

const Slider = ({ field, label, unit = '%', min = 0, max = 100, ...otherProps }) => {
  const progress = ((field.value - min) / (max - min)) * 100

  const handleChange = (e) => {
    field.onChange(e.target.value)
  }

  return (
    <Fragment>
      {label && (
        <Label>
          {label}
        </Label>
      )}
      <BSlider>
        <BSlider.Range progress={progress}>
          <Input type="range" {...otherProps} value={field.value} onChange={handleChange} min={min} max={max} />
        </BSlider.Range>
        <Span>
          {field.value} {unit}
        </Span>
      </BSlider>
    </Fragment>
  )
}

Slider.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  unit: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number
}

export default Slider
