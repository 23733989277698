// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BContentBlock from 'ui/blocks/ContentBlock'

// Shared

/**
 * General component description.
 *
 * @example
 *   <ContentBlock footer={<ActionGroup><Button /><Button /></ActionGroup>}><SomeForm/></ContentBlock>
 */

export default class ContentBlock extends Component {
  static displayName = 'ContentBlock'

  static propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.object
    ]),
    description: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.object
    ]),
    titleLink: PropTypes.node,
    children: PropTypes.node,
    footer: PropTypes.node,
    clean: PropTypes.bool,
    tableFit: PropTypes.bool,
    'data-tid': PropTypes.string,
    modifiers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  }

  render () {
    const {
      title,
      titleLink,
      description,
      children,
      footer,
      clean,
      tableFit,
      'data-tid': dataTid,
      modifiers,
      ...otherProps
    } = this.props

    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    return (
      <BContentBlock
        clean={clean}
        data-tid={dataTid}
        tableFit={tableFit}
        modifiers={nestedModifiers.self}
        {...otherProps}
      >
        {title &&
          <BContentBlock.Title description={description} modifiers={nestedModifiers.title}>
            {title}
            {titleLink &&
              <BContentBlock.TitleLink>
                {titleLink}
              </BContentBlock.TitleLink>
            }
          </BContentBlock.Title>
        }
        {description &&
          <BContentBlock.Description modifiers={nestedModifiers.description}>
            {description}
          </BContentBlock.Description>
        }
        <BContentBlock.Content modifiers={nestedModifiers.content}>
          {children}
        </BContentBlock.Content>
        {footer && (
          <BContentBlock.Footer>{footer}</BContentBlock.Footer>
        )}
      </BContentBlock>
    )
  }
}
