import {
  RadioGroup,
  Radio,
  Label,
  Description,
  ChildContainer,
  Buttons,
  Error
} from './styles'

RadioGroup.Radio = Radio
RadioGroup.Label = Label
RadioGroup.Description = Description
RadioGroup.ChildContainer = ChildContainer
RadioGroup.Buttons = Buttons
RadioGroup.Error = Error

export default RadioGroup
