// React
import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'

// Components
import BPopover from 'ui/blocks/Popover'

const Content = ({ content, contentProps, target, isMobile, useArrow, noOverflow, color, defaultPopoverStyling }) => (
  <BPopover.Content data-tid={target} interactive tippy notFixed>
    <BPopover.Inner color={color} noOverflow={noOverflow} interactive defaultPopoverStyling={defaultPopoverStyling}>
      {typeof content === 'function' ? content(contentProps) : cloneElement(content, contentProps)}
      {!isMobile && useArrow && !noOverflow && (
        <BPopover.TippyArrow
          color={color}
          className="tippy-arrow"
          data-popper-arrow=""
        />
      )}
    </BPopover.Inner>
    {!isMobile && useArrow && noOverflow && (
      <BPopover.TippyArrow
        color={color}
        className="tippy-arrow"
        data-popper-arrow=""
      />
    )}
  </BPopover.Content>
)

Content.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]),
  contentProps: PropTypes.object,
  useArrow: PropTypes.bool,
  isMobile: PropTypes.bool,
  target: PropTypes.string
}

export default Content
