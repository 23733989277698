// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import BUrlSelect from 'ui/blocks/UrlSelect'
import Icon from 'ui/components/Icon'

// Libraries
import { components } from 'react-select'

// Shared

const Collection = (props) => {
  return (
    <BUrlSelect.Option
      {...props.innerProps}
      isSelected={props.isSelected}
      isFocused={props.isFocused}
      columns={['30px', '1fr']}
      isCollection
      borderBottom={props.value === 'booqable://collections/'}
    >
      <BUrlSelect.OptionCell>
        <Icon icon="box" />
      </BUrlSelect.OptionCell>
      <BUrlSelect.OptionCell>
        <components.Option {...props} />
      </BUrlSelect.OptionCell>
    </BUrlSelect.Option>
  )
}

Collection.propTypes = {
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  value: PropTypes.string,
  // react-select internal props
  innerProps: PropTypes.object
}

export default Collection
