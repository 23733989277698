import styled, { css } from 'styled-components'
import { size } from 'ui/themes/utils'

import Col from 'ui/elements/Col'
import Div from 'ui/elements/Div'

const Row = styled(Div)`
  /* TODO: ditch smallGutters and equalHeight after Bootstrap upgrade */
  ${(props) => props.smallGutters && css`
    margin-left: -${size('padding', 'xs')};
    margin-right: -${size('padding', 'xs')};

    > ${Col} {
      padding-left: ${size('padding', 'xs')};
      padding-right: ${size('padding', 'xs')};
    }
  `}

  ${(props) => props.noGutters && css`
    margin-left: 0;
    margin-right: 0;

    > ${Col} {
      padding-left: 0;
      padding-right: 0;
    }
  `}

   ${(props) => props.equalHeight && css`
     @media (min-width: 992px) {
       display: flex;
       flex-wrap: wrap;
     }

     ${Col} {
       @media (min-width: 992px) {
         display: flex;
         flex-direction: column;
       }
     }
  `}

  ${(props) => props.marginBottom && css`
    ${Col} {
      margin-bottom: ${size('margin', 'md')};
    }
  `}
`

export default Row
