// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BCheckboxGroup from 'ui/blocks/CheckboxGroup'
import Checkbox from 'ui/components/Checkbox'

// Shared

/**
 * Component: CheckboxGroup renders a group of checkboxes
 * which return an array of values on OnChange
 *
 * @example
 *   <CheckboxGroup
 *     {..field}
 *     options={[{
 *       value: 'option_1',
 *       label: 'Option 1'
 *     }, {
 *       value: 'option_2',
 *       label: 'Option 2'
 *     }, {
 *       value: 'option_3',
 *       label: 'Option 3'
 *     }]}
 *   />
 */

export default class CheckboxGroup extends Component {
  static displayName = 'CheckboxGroup'

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]).isRequired,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
      ]).isRequired,
      labelImage: PropTypes.node,
      description: PropTypes.string,
      children: PropTypes.node
    })).isRequired,
    onChange: PropTypes.func,
    field: PropTypes.object,
    noInline: PropTypes.bool,
    disabled: PropTypes.bool,
    bordered: PropTypes.bool,
    withOptionDescriptions: PropTypes.bool,
    compact: PropTypes.bool,
    modifiers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  }

  handleChange = (value, e) => {
    const { onChange, onBlur } = this.props.field
    const newValues = [...this.props.field.value]
    const index = newValues.indexOf(value)

    if (index === -1) {
      newValues.push(value)
    } else {
      newValues.splice(index, 1)
    }

    onBlur && onBlur({
      target: {
        name: this.props.field.name
      }
    })
    onChange && onChange(newValues)
  }

  renderOptions = (options) => {
    const { field, disabled, modifiers } = this.props
    const { name, value } = field

    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    return options.map((option) => {
      const field = {
        value: (value || []).indexOf(option.value) !== -1,
        onChange: this.handleChange.bind(null, option.value)
      }

      return (
        <Checkbox
          label={option.label}
          labelImage={option.labelImage}
          description={option.description}
          children={option.children}
          field={field}
          id={`${name}_${option.value}`}
          key={option.value}
          disabled={disabled}
          data-tid={`Checkbox ${option.value}`}
          modifiers={{ label: nestedModifiers.checkboxLabel }}
        />
      )
    })
  }

  render () {
    const { options, noInline, bordered, withOptionDescriptions, compact } = this.props

    return (
      <BCheckboxGroup noInline={noInline} compact={compact} bordered={bordered} withOptionDescriptions={withOptionDescriptions}>
        {this.renderOptions(options)}
      </BCheckboxGroup>
    )
  }
}
