import React from 'react'
import PropTypes from 'prop-types'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'

// Components
import Div from 'ui/elements/Div'
import Link from 'ui/components/Link'
import Span from 'ui/elements/Span'


class SignIn extends React.Component {
  static displayName = 'SignIn'

  static propTypes = {
    t: PropTypes.func.isRequired
  }

  trackLinkClick = () => {
    EventTracker.track('Signup: Clicked login link', {}, () => {
      window.location = `${window.booqableProtocol}://login.${window.booqableUrl}`
    })
  }

  render () {
    const { t } = this.props

    return (
      <Div modifiers={'float-right margin-top-xl margin-right-sm margin-right-md-xxl'}>
        <Span modifiers={'display-none display-md-inline-block'}>
          {t('signup.already_registered')}
        </Span>
        {' '}
        <Link onClick={this.trackLinkClick}>
          {t('signup.login')}
        </Link>
      </Div>
    )
  }
}

export default withTranslation('signup')(SignIn)
