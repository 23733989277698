import i18n from 'i18next'
import arrayToSelect from 'shared/utils/arrayToSelect'

const arrayToSelectWithOriginal = (array, t) => {
  // Renders an arrayToSelect with the English translation as the value
  // So we only save the English value to our database
  const en = i18n.getFixedT('en', 'signup')

  return array.map((value) => { return { value: en(value), label: t(value) } })
}

export const productCountData = (t) => {
  return [
    {
      value: '< 10',
      label: t('signup.fields.product_count.options.option_1')
    },
    {
      value: '10-50',
      label: t('signup.fields.product_count.options.option_2')
    },
    {
      value: '50-100',
      label: t('signup.fields.product_count.options.option_3')
    },
    {
      value: '100-500',
      label: t('signup.fields.product_count.options.option_4')
    },
    {
      value: '500-1000',
      label: t('signup.fields.product_count.options.option_5')
    },
    {
      value: '1000-2000',
      label: t('signup.fields.product_count.options.option_6')
    },
    {
      value: '2000+',
      label: t('signup.fields.product_count.options.option_7')
    }
  ]
}

export const orderCountData = (t) => {
  return [
    {
      value: '< 10',
      label: t('signup.fields.order_count.options.option_1')
    },
    {
      value: '10-50',
      label: t('signup.fields.order_count.options.option_2')
    },
    {
      value: '50-100',
      label: t('signup.fields.order_count.options.option_3')
    },
    {
      value: '100-500',
      label: t('signup.fields.order_count.options.option_4')
    },
    {
      value: '500+',
      label: t('signup.fields.order_count.options.option_5')
    }
  ]
}

export const percentageOnlineOrdersData = () => {
  return arrayToSelect([
    '0-20%',
    '20-40%',
    '40-60%',
    '60-80%',
    '80-100%'
  ])
}


export const teamSizeData = () => {
  return arrayToSelect([
    '1',
    '2',
    '3-5',
    '6-10',
    '11-20',
    '20+'
  ])
}

export const locationSizeData = () => {
  return arrayToSelect([
    '1',
    '2',
    '3-5',
    '6-10',
    '11-20',
    '20+'
  ])
}

export const projectedRevenueData = () => {
  return arrayToSelect([
    '$0-$20,000',
    '$20,000-$50,000',
    '$50,000-$100,000',
    '$100,000-$250,000',
    '$250,000-$500,000',
    '$500,000-$1,250,000',
    '$1,250,000+'
  ])
}

export const marketData = (t) => {
  return arrayToSelectWithOriginal([
    'signup.markets.av_camera',
    'signup.markets.event_party',
    'signup.markets.bikes_scooters',
    'signup.markets.sports_activities',
    'signup.markets.boats_kayaks',
    'signup.markets.tools_construction',
    'signup.markets.motor_trailers',
    'signup.markets.technology_it',
    'signup.markets.house_storage',
    'signup.markets.clothing',
    'signup.markets.medical_healthcare',
    'signup.markets.baby_child_care'
  ], t)
}

export const productData = (t) => {
  const en = i18n.getFixedT('en', 'signup')
  const productData = {}

  productData[en('signup.markets.av_camera')] = arrayToSelectWithOriginal([
    'signup.products.cameras_lenses',
    'signup.products.sound_equipment',
    'signup.products.lighting_gear',
    'signup.products.dj_gear',
    'signup.products.drones',
    'signup.products.staging_equipment',
    'signup.products.led_and_projectors',
    'signup.products.studio'
  ], t)

  productData[en('signup.markets.event_party')] = arrayToSelectWithOriginal([
    'signup.products.event_decor',
    'signup.products.tents',
    'signup.products.event_booths',
    'signup.products.inflatables',
    'signup.products.photo_booths',
    'signup.products.neon_signs',
    'signup.products.silent_disco',
    'signup.products.catering_and_bb_qs',
    'signup.products.hot_tubs',
    'signup.products.portable_toilet_and_restroom_trailers',
    'signup.products.lawn_signs',
    'signup.products.venues'
  ], t)

  productData[en('signup.markets.bikes_scooters')] = arrayToSelectWithOriginal([
    'signup.products.bikes',
    'signup.products.e_bikes',
    'signup.products.mountain_bikes',
    'signup.products.road_bikes',
    'signup.products.surrey_bikes',
    'signup.products.mopeds_and_scooters',
    'signup.products.mobility_scooters',
    'signup.products.tours'
  ], t)

  productData[en('signup.markets.sports_activities')] = arrayToSelectWithOriginal([
    'signup.products.sports_gear',
    'signup.products.hiking_gear',
    'signup.products.camping_equipment',
    'signup.products.skis_and_snowboards',
    'signup.products.surf_equipment',
    'signup.products.diving_equipment',
    'signup.products.sup_boards',
    'signup.products.fishing_gear',
    'signup.products.team_games',
    'signup.products.fitness_equipment',
    'signup.products.beach_equipment',
    'signup.products.tours'
  ], t)

  productData[en('signup.markets.boats_kayaks')] = arrayToSelectWithOriginal([
    'signup.products.row_and_cycle_boats',
    'signup.products.small_motor_boats',
    'signup.products.kayaks_and_canoes',
    'signup.products.sail_boats',
    'signup.products.speed_boats',
    'signup.products.pontoons',
    'signup.products.yachts',
    'signup.products.submarines',
    'signup.products.jet_skis',
    'signup.products.tours'
  ], t)

  productData[en('signup.markets.tools_construction')] = arrayToSelectWithOriginal([
    'signup.products.power_tools',
    'signup.products.dumpsters_and_waste_disposal',
    'signup.products.portable_toilet_and_restroom_trailers',
    'signup.products.scaffolding_and_access_equipment',
    'signup.products.generators_and_lighting',
    'signup.products.earthmoving_equipment',
    'signup.products.heavy_machinery',
    'signup.products.garden_tools'
  ], t)

  productData[en('signup.markets.motor_trailers')] = arrayToSelectWithOriginal([
    'signup.products.cars',
    'signup.products.limousines',
    'signup.products.camper_vans',
    'signup.products.motorcycles',
    'signup.products.at_vs',
    'signup.products.trailers',
    'signup.products.snowmobiles',
    'signup.products.golf_carts'
  ], t)

  productData[en('signup.markets.technology_it')] = arrayToSelectWithOriginal([
    'signup.products.computers',
    'signup.products.laptops',
    'signup.products.mobile_phones',
    'signup.products.tablets',
    'signup.products.gaming_equipment',
    'signup.products.virtual_reality_equipment',
    'signup.products.wifi_access_points',
    'signup.products.servers'
  ], t)

  productData[en('signup.markets.house_storage')] = arrayToSelectWithOriginal([
    'signup.products.furniture',
    'signup.products.white_goods',
    'signup.products.linen',
    'signup.products.storage',
    'signup.products.moving_equipment'
  ], t)

  productData[en('signup.markets.clothing')] = arrayToSelectWithOriginal([
    'signup.products.wedding_suits_and_dresses',
    'signup.products.tuxedos',
    'signup.products.fashion_items',
    'signup.products.party_costumes',
    'signup.products.maternity_dresses'
  ], t)

  productData[en('signup.markets.medical_healthcare')] = arrayToSelectWithOriginal([
    'signup.products.wheelchairs',
    'signup.products.hospital_beds',
    'signup.products.mobility_scooters',
    'signup.products.knee_walkers',
    'signup.products.stairlifts',
    'signup.products.ramps',
    'signup.products.over_bed_tables'
  ], t)

  productData[en('signup.markets.baby_child_care')] = arrayToSelectWithOriginal([
    'signup.products.strollers',
    'signup.products.car_seats',
    'signup.products.cots',
    'signup.products.breast_pumps',
    'signup.products.toys',
    'signup.products.bouncers',
    'signup.products.monitoring_and_safety',
    'signup.products.bath_tubs',
    'signup.products.potties'
  ], t)

  return productData
}

export const currentWebsiteData = (t) => {
  return [
    {
      label: t('signup.fields.current_website.options.no_website'),
      value: 'no_website'
    },
    {
      label: t('signup.fields.current_website.options.no_online_bookings'),
      value: 'no_online_bookings'
    },
    {
      label: t('signup.fields.current_website.options.online_bookings'),
      value: 'online_bookings'
    }
  ]
}

export const receiveOnlineOrdersData = (t) => {
  return [
    {
      label: t('signup.fields.online_orders.options.existing_store'),
      value: 'existing_store'
    },
    {
      label: t('signup.fields.online_orders.options.new_store'),
      value: 'new_store'
    },
    {
      label: t('signup.fields.online_orders.options.no_online_bookings'),
      value: 'no_online_bookings'
    },
    {
      label: t('signup.fields.online_orders.options.still_deciding'),
      value: 'still_deciding'
    }
  ]
}

export const personaDescriptionData = (t) => {
  return [
    {
      label: t('signup.fields.persona_description.options.testing'),
      value: 'testing'
    },
    {
      label: t('signup.fields.persona_description.options.grow_revenue_expand'),
      value: 'grow_revenue_expand'
    },
    {
      label: t('signup.fields.persona_description.options.run_comfortably'),
      value: 'run_comfortably'
    },
    {
      label: t('signup.fields.persona_description.options.large_organization'),
      value: 'large_organization'
    }
  ]
}

export const valuePriorityData = (t) => {
  return [
    {
      label: t('signup.fields.value_priority.options.increase_revenue'),
      value: 'increase_revenue'
    },
    {
      label: t('signup.fields.value_priority.options.save_time'),
      value: 'save_time'
    },
    {
      label: t('signup.fields.value_priority.options.reduce_mistakes'),
      value: 'reduce_mistakes'
    },
    {
      label: t('signup.fields.value_priority.options.improve_satisfaction'),
      value: 'improve_satisfaction'
    },
    {
      label: t('signup.fields.value_priority.options.provide_insights'),
      value: 'provide_insights'
    }
  ]
}

export const implementationTimelineData = (t) => {
  return [
    {
      label: t('signup.fields.implementation_timeline.options.just_looking'),
      value: 'just_looking'
    },
    {
      label: t('signup.fields.implementation_timeline.options.30_days'),
      value: '30_days'
    },
    {
      label: t('signup.fields.implementation_timeline.options.3_months'),
      value: '3_months'
    },
    {
      label: t('signup.fields.implementation_timeline.options.6_months'),
      value: '6_months'
    },
    {
      label: t('signup.fields.implementation_timeline.options.immediately'),
      value: 'immediately'
    }
  ]
}

export const pickupMethodsData = (t) => {
  return [
    {
      label: t('signup.fields.pickup_methods.options.in_person'),
      value: 'in_person'
    },
    {
      label: t('signup.fields.pickup_methods.options.delivery'),
      value: 'delivery'
    },
    {
      label: t('signup.fields.pickup_methods.options.third_party_shipping'),
      value: 'third_party_shipping'
    },
    {
      label: t('signup.fields.pickup_methods.options.other'),
      value: 'other'
    }
  ]
}

export const yesNoData = (t) => {
  return [
    {
      value: 'yes',
      label: t('common.label_yes')
    },
    {
      value: 'no',
      label: t('common.label_no')
    }
  ]
}

export const roleData = (t) => {
  return [
    {
      label: t('signup.fields.role.options.owner'),
      value: 'owner'
    },
    {
      label: t('signup.fields.role.options.manager'),
      value: 'manager'
    },
    {
      label: t('signup.fields.role.options.employee'),
      value: 'employee'
    },
    {
      label: t('signup.fields.role.options.agency'),
      value: 'agency'
    }
  ]
}

export const yearBusinessStartData = (t) => {
  const data = [
    {
      label: t('signup.fields.year_business_start.options.not_started'),
      value: 'not_started'
    }
  ]

  let year = new Date().getFullYear()
  const endYear = 1950

  while (year >= endYear) {
    data.push({
      label: `${year}`,
      value: `${year}`
    })

    year--
  }

  return data
}
