export default [
  {
    label: 'Nederlands',
    short: 'NL',
    value: 'nl'
  },
  {
    label: 'English',
    short: 'EN',
    value: 'en'
  },
  {
    label: 'Español',
    short: 'ES',
    value: 'es'
  },
  {
    label: 'Deutsch',
    short: 'DE',
    value: 'de'
  },
  {
    label: 'Français',
    short: 'FR',
    value: 'fr'
  },
  {
    label: 'Português',
    short: 'PT',
    value: 'pt'
  },
  {
    label: 'Português (Brasil)',
    short: 'PT (br)',
    value: 'pt_BR'
  }
]
