import styled, { css } from 'styled-components'
import { color, size, fontSize, fontWeight } from 'ui/themes/utils'

import LabelElement from 'ui/elements/Label'
import DescriptionElement from 'ui/elements/Description'
import ButtonElement from 'ui/blocks/Button'
import I from 'ui/elements/I'
import Div from 'ui/elements/Div'

export const Buttons = styled.div`
  ${ButtonElement} {
    ${ButtonElement.Button} {
      border-radius: 0;
    }

    &:first-child {
      ${ButtonElement.Button} {
        border-top-left-radius: ${size('borderRadius', 'sm')};
        border-bottom-left-radius: ${size('borderRadius', 'sm')};
      }
    }

    &:last-child {
      ${ButtonElement.Button} {
        border-top-right-radius: ${size('borderRadius', 'sm')};
        border-bottom-right-radius: ${size('borderRadius', 'sm')};
      }
    }
  }

  ${props => props.fullWidth && css`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    ${ButtonElement} {
      width: 100%;

      ${ButtonElement.Button} {
        width: 100%;
      }
    }
  `}
`

export const Radio = styled(Div)`
  position: relative;

  padding: ${size('padding', 'sm')} ${size('padding')};

  padding-left: calc(2.5em - 5px);

  font-size: ${fontSize()};

  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  > ${I} {
    position: absolute;

    top: -3px;
    left: -4px;

    width: 2em;
    height: 2em;

    background: ${color('white')};

    border: 1px ${color('Misc/Divider')} solid;
    border-radius: 50%;

    font-weight: ${fontWeight('bold')};
    text-align: center;
    line-height: 2em;

    /* Scaling with font-size makes the FontAwesome circle oblong for some reason */
    /* Using transform scale instead */
    transform: scale(0.7);

    cursor: pointer;
  }

  &:hover {
    > ${I} {
      background: ${color('light')};
    }
  }

  ${props => !props.checked && css`
    > ${I}::before {
      content: '';
    }
  `}

  ${props => props.checked && css`
    > ${I} {
      color: ${color('Text/Inverted')};
      background: ${color('Primary/Base')};

      border: 0;
    }

    &:hover > ${I} {
      background: ${color('Primary/Base')};
    }
  `}

  ${props => props.bordered && css`
    padding: ${size('padding', 'sm')}
            ${size('padding', 'md')}
            ${size('padding', 'sm')}
            calc(5px + ${size('padding', 'xl')}) !important;
    border: 1px ${color('Misc/Divider')} solid !important;
    border-radius: ${size('borderRadius', 'sm')};
    display: inline-block;
    margin-right: ${size('margin', 'sm')};

    ${props => props.checked && css`
      border-color: ${color('Primary/Base')} !important;
    `}

    > ${I} {
      top: calc(${size('padding', 'sm')} - 3px);
      left: calc(${size('padding', 'sm')} - 5px);
    }
  `}

`

export const RadioGroup = styled.div`
  width: 100%;

  ${props => props.displayGrid && css`
    @media (min-width: ${size('screenSizes', 'lg')}) {
      display: grid;
      grid-template-columns: auto auto;
      gap: ${size('margin', 'md')};

      ${props => props.displayGrid === 'equalWidth' && css`
        grid-template-columns: 50% 50%;
      `}
    }
  `}

  ${Radio} {
    padding-top: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  ${props => props.type === 'buttonGroup' && props.centered !== false && css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  `}

  ${({ error }) => error && css`
    ${Radio} > ${I} {
      border-color: ${color('Danger/Base')}
    }
  `}

  ${props => props.bordered && props.error && css`
    ${Radio} {
      border-color: ${color('Danger/Base')} !important;
    }
  `}
`

export const Label = styled(LabelElement)`
  display: block;

  margin: 0;

  font-weight: ${fontWeight('semibold')};
  font-size: ${fontSize()};
  line-height: 1.6em;

  cursor: pointer;
`

export const Description = styled(DescriptionElement)`
  display: block;

  margin: 4px 0 0;

  cursor: pointer;
`

export const ChildContainer = styled.div`
  margin-top: ${size('margin', 'xs')};
  cursor: initial;
`

export const Error = styled.div`
  margin-top: ${size('margin', 'sm')};

  color: ${color('Danger/Base')};
`
