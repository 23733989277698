import { Record } from 'immutable'
import pick from 'lodash/pick'

const SignupRecord = Record({
  id: undefined,
  created: undefined,
  identifier: undefined,
  company_available: undefined,
  ready: false,
  slug: undefined,
  authentication_token: undefined,
  is_eu: undefined,
  employee_id: undefined,
  company_id: undefined,
  tracking_data: undefined,
  read_only_mode: false,
  theme: undefined,

  // Marketing data
  source: undefined,
  medium: undefined,
  campaign: undefined,
  keyword: undefined,

  // Set by SignupSaver
  timezone: undefined,
  currency: undefined,

  // Step 1
  email: undefined,
  password: undefined,
  company_name: undefined,

  // Step 2
  firstname: undefined,
  lastname: undefined,
  country: undefined,
  role: undefined,

  // Step 3
  market: undefined,
  products: undefined,
  year_business_start: undefined,
  team_size: undefined,
  location_size: undefined,

  // Step 4
  product_count: undefined,
  order_count: undefined,
  percentage_online_orders: undefined,
  charge_orders: undefined,
  projected_revenue: undefined,

  // Step 5
  current_website: undefined,
  online_orders: undefined,
  pickup_methods: undefined,

  // Step 6
  persona_description: undefined,
  value_priority: undefined,

  // Step 7
  customer_label: undefined,
  order_label: undefined,
  quote_label: undefined,
  contract_label: undefined,
  packing_slip_label: undefined,

  // Step 8
  implementation_timeline: undefined,
  import_data: undefined,

  data: {}
})

const initialState = new SignupRecord()

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNUP_FETCH_COMPLETE':
      state.forEach((_, key) => {
        const newValue = action.payload[key]

        // Do not overwrite existing state
        if (newValue !== undefined && state.has(key)) {
          state = state.set(key, newValue)
        }
      })

      return state
    case 'SIGNUP_SET_INITIAL_VALUES_SUCCESS':
    case 'SIGNUP_FETCH_COMPANY_AVAILABILITY_SUCCESS':
    case 'SIGNUP_CREATE_SUCCESS':
    case 'SIGNUP_UPDATE_SUCCESS':
    case 'SIGNUP_COMPLETE_SUCCESS':
    case 'SIGNUP_READY_SUCCESS':
    case 'SIGNUP_FETCH_IS_EU_SUCCESS':
      return state.merge(pick(action.payload, state._keys))
    case 'SET_READ_ONLY_MODE':
      return state.set('read_only_mode', action.payload.read_only_mode)
    default:
      return state
  }
}
