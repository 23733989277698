// React
import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

// Libraries
import { components } from 'react-select'

// Components
import Select from 'ui/components/Select'
import { Option, Label, Remove } from './Option'

const createOption = (label) => ({
  value: label.trim(),
  label: label.trim()
})

const MultiSelectInput = ({ field, delimiter = ',', useOptions = false, ...otherProps }) => {
  const [value, setValue] = useState(field.value ? field.value.split(delimiter).map(createOption) : [])
  const [inputValue, setInputValue] = useState('')

  const selectComponents = useMemo(
    () => ({
      DropdownIndicator: useOptions ? components.DropdownIndicator : null,
      MultiValueContainer: Option,
      MultiValueLabel: Label,
      MultiValueRemove: Remove
    }),
    [otherProps.options]
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (!inputValue) return

      // If the user presses enter, comma, space or tab create an option
      if ([' ', 'Enter', 'Tab', ','].includes(event.key)) {
        event.preventDefault()

        if (field.value) {
          field.onChange(`${field.value},${inputValue}`)
        } else {
          field.onChange(inputValue)
        }

        setValue((prev) => [...prev, createOption(inputValue)])
        setInputValue('')
      }
    },
    [field.value, inputValue]
  )

  const handleChange = useCallback((val) => {
    const newValue = val?.map(createOption) || []

    field.onChange(newValue?.map((option) => option.value).join(delimiter) || '')
    setValue(newValue)
  }, [])

  return (
    <Select
      {...otherProps}
      components={selectComponents}
      inputValue={inputValue}
      onKeyDown={handleKeyDown}
      field={field}
      value={value}
      onChange={handleChange}
      onInputChange={setInputValue}
      {...(!useOptions && { menuIsOpen: false })}
      name={field.name}
      form={field.name}
      styles={{
        valueContainer: (base) => ({
          ...base,
          gap: 6
        })
      }}
      forceOnChange
      forceValue
      isMulti
      isClearable
    />
  )
}

MultiSelectInput.propTypes = {
  field: PropTypes.object,
  delimiter: PropTypes.string,
  useOptions: PropTypes.bool
}

export default MultiSelectInput
