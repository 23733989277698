import styled, { css } from 'styled-components'
import { size } from 'ui/themes/utils'

import Div from 'ui/elements/Div'
import Button from 'ui/blocks/Button'
import InputField from 'ui/blocks/InputField'

export const InputGroup = styled(Div)`
  display: flex;

  > ${InputField} {
    margin-bottom: 0;
  }

  > :first-child:not(:last-child) {
    ${props => props.divider && props.preserveBorders && css`
      margin-right: ${size('borderWidth')};
    `}

    ${props => !props.noFlex && css`
      flex: 1 1 auto;
      width: 1%;
    `}

    &, *, .Select__control {
      border-top-right-radius: 0%;
      border-bottom-right-radius: 0%;

      ${props => !props.preserveBorders && css`
        border-right: 0;
      `}
    }
  }

  > :last-child:not(:first-child) {
    &, *, ${Button}, .Select__control {
      border-top-left-radius: 0%;
      border-bottom-left-radius: 0%;
    }
  }

  > :not(:first-child):not(:last-child) {
    &, *, ${Button}, .Select__control {
      border-radius: 0;

      ${props => !props.preserveBorders && css`
        border-right: 0;
      `}
    }
  }

  ${(props) => {
    const { sizes } = props

    let styles = ''

    if (sizes) {
      sizes.forEach((size, index) => {
        styles += `
          > *:nth-child(${index + 1}) {
            flex-basis: ${size};
          }
        `
      })

      return css`${styles}`
    }
  }}
`
