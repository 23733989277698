import styled from 'styled-components'
import { color, fontSize, fontWeight, injectModifiers } from 'ui/themes/utils'
import modifiers from 'ui/themes/modifiers'

const H4 = styled.h4`
  margin: 0;

  color: ${color('Text/Primary')};
  font-family: ${(props) => props.theme.font.body};
  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('semibold')};

  ${({ theme }) => injectModifiers(modifiers(theme))}
`

export default H4
