// React
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'

// Components
import Layout from 'signup/components/Layout'
import Button from 'ui/components/Button'
import LabelPersonalizationFields from 'client/modules/settings/components/LabelPersonalization/Fields'
import Div from 'ui/elements/Div'

// Shared
import withForm, { Form } from 'shared/form'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import SignupActions from 'signup/redux/actions/signup'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'

export class Step7 extends React.Component {
  static displayName = 'Step7'
  static propTypes = {
    t: PropTypes.func.isRequired,
    submitPromise: PropTypes.instanceOf(Promise)
  }

  componentDidMount = () => {
    EventTracker.page()
    EventTracker.track('Signup: Step 7')
  }

  render = () => {
    const { submitPromise, t } = this.props

    return (
      <Layout step={7} stepIndicator={t('signup.step_7.step_indicator')}>
        <Form>
          <LabelPersonalizationFields
            showIfValid
            fieldNameSuffix={'_label'}
            excludeLabels={['start', 'stop']}
          />

          <Div modifiers={'margin-top-lg margin-top-md-xl'}>
            <Button
              size="lg"
              type="submit"
              promise={submitPromise}
              block
              borderRadius={'mdplus'}
              modifiers={'margin-bottom-xl'}
            >
              {t('signup.continue')}
            </Button>
          </Div>
        </Form>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      customer_label: state.signup.customer_label || 'customer',
      order_label: state.signup.order_label || 'order',
      quote_label: state.signup.quote_label || 'quote',
      contract_label: state.signup.contract_label || 'contract',
      packing_slip_label: state.signup.packing_slip_label || 'packing_slip'
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (values) => {
      return SignupActions.update(window.id, values)
    },
    serverSuccess: () => {
      RoutingUtils.navigate(`/${window.locale}/step-8`)
    }
  }
}

export default compose(
  withTranslation('signup'),
  reduxPureConnect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Step7)
