import React from 'react'
import PropTypes from 'prop-types'

import BBadge from 'ui/blocks/Badge'
import Icon from 'ui/components/Icon'

const PRESETS = {
  archived: {
    flatColor: 'grey',
    size: 'sm',
    borderRadius: 'lg',
    modifiers: [
      'no-margin',
      'margin-left-md',
      'font-weight-semibold',
      'font-size-md',
      'padding-left-md',
      'padding-right-md'
    ].join(' ')
  }
}

/**
 * Render a badge.
 *
 * @example
 *   <Badge size="lg" />
 */
const Badge = ({
  onClick,
  size = 'xs',
  color = 'dark',
  children,
  icon,
  disabled,
  modifiers,
  preset,
  ...otherProps
}) => {
  const presetProps = PRESETS[preset] || {}

  return (
    <BBadge
      onClick={onClick}
      size={size}
      color={color}
      disabled={disabled}
      modifiers={modifiers}
      {...otherProps}
      {...presetProps}
    >
      {icon && <Icon icon={icon} />}
      {children}
    </BBadge>
  )
}

Badge.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  modifiers: PropTypes.string,
  preset: PropTypes.oneOf(Object.keys(PRESETS))
}

export default Badge
