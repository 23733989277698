export function selectOptions (values) {
  const options = []

  for (const option in values) {
    if (Array.isArray(values)) {
      options.push({
        label: values[option],
        value: values[option]
      })
    } else {
      options.push({
        label: values[option],
        value: option
      })
    }
  }

  return options
}
