// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import { singularize } from 'inflected'

// Components
import BUrlSelect from 'ui/blocks/UrlSelect'
import Select from 'ui/components/Select'
import ValueContainer from './ValueContainer'
import Menu from './Menu'
import Option from './Option'

// Shared
import { stringIsLink } from 'client/v2/utils/utils'

class UrlSelect extends Component {
  static displayName = 'UrlSelect'

  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    records: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    total: PropTypes.number,
    field: PropTypes.object,
    placeholder: PropTypes.string,
    category: PropTypes.string,
    type: PropTypes.string,
    photos: PropTypes.object,
    options: PropTypes.array,
    handleSetValues: PropTypes.func,
    onCreate: PropTypes.func,
    onGoBack: PropTypes.func,
    onFilter: PropTypes.func,
    loading: PropTypes.bool,
    canLoadMore: PropTypes.bool,
    onLoadMore: PropTypes.func,
    t: PropTypes.func
  }

  state = {
    closeOnClick: false
  }

  toggleCloseOnClick = (value) => {
    if (this.state.closeOnClick !== value) {
      this.setState({ closeOnClick: value })
    }
  }

  get value () {
    const { field, type } = this.props

    if (!field.value) return undefined

    if (type === 'link') {
      return {
        label: field.value,
        value: field.value
      }
    }

    return this.options.find((el) => el.value === field.value)
  }

  get options () {
    const { records, category, type, field, t } = this.props

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!records.size) {
      const defaultOptions = this.getCategoryDefaultOptions(category)

      return [
        ...defaultOptions,
        ...records.map((el) => this.getCategoryOption(category, el))
      ]
    }

    return [
      ...(!!field.value && type === 'link'
        ? [
            {
              label: field.value,
              value: field.value,
              type: 'link'
            }
          ]
        : []),
      {
        label: t('settings.menus.homepage'),
        type: 'link',
        value: 'booqable://root/',
        divider: true
      },
      {
        label: t('common.resources.product_other'),
        value: 'products'
      },
      {
        label: t('common.resources.collection_other'),
        value: 'collections'
      },
      {
        label: t('common.resources.page_other'),
        value: 'pages'
      }
    ]
  }

  get components () {
    const { category } = this.props
    const components = {
      Option: (props) => {
        return <Option
          {...props}
          onEnterOption={this.toggleCloseOnClick.bind(null, true)}
          onLeaveOption={this.toggleCloseOnClick.bind(null, false)}
        />
      }
    }
    const canGoBack = !!category

    if (canGoBack) {
      components.Menu = Menu
    }

    if (!!this.value || !!this.props.value) {
      components.ValueContainer = ValueContainer
    }

    return components
  }

  getCategoryOption = (category, data) => {
    const { photos } = this.props

    switch (category) {
      case 'products': {
        const photo = photos.getById(data.photo)

        return {
          label: data.name,
          value: `booqable://${category}/${data.slug}`,
          type: 'item',
          photo
        }
      }
      case 'pages':
        return {
          label: data.title,
          value: `booqable://${category}/${data.slug}`,
          type: 'page'
        }
      case 'collections':
        return {
          label: data.name,
          value: `booqable://${category}/${data.slug}`,
          type: 'collection'
        }
    }
  }

  getCategoryDefaultOptions = (category) => {
    const { t } = this.props

    switch (category) {
      case 'pages':
        return []
      case 'products':
        return [
          {
            label: t('common.resource_actions.all', { resource: t('common.resources.product_other') }),
            value: 'booqable://products/',
            type: 'menu-item'
          }
        ]
      case 'collections':
        return [
          {
            label: t('common.resource_actions.all', { resource: t('common.resources.collection_other') }),
            value: 'booqable://collections/',
            type: 'menu-item'
          }
        ]
      default:
        return [{
          label: t('common.resource_actions.all', { resource: t(`common.resources.${singularize(category)}_other`) }),
          value: `booqable://${category}/`,
          type: 'menu-item'
        }]
    }
  }

  getNoOptionsMessage = () => {
    const { t, category } = this.props

    switch (category) {
      case 'pages':
      case 'products':
      case 'collections':
        return t('settings.menus.no_options_resource', {
          resource: t(`common.resources.${singularize(category)}_other`)
        })
      default:
        return t('settings.menus.no_options')
    }
  }

  componentDidUpdate = () => {
    if (!!this.props.field.value && this.props.field.value !== this.props.value?.value && !!this.options.size) {
      this.props.handleSetValues({ value: this.value })
    }
  }

  handleChange = (option) => {
    const { category } = this.props

    if (!option) {
      this.props.field?.onChange(null)

      return this.props.handleSetValues({ category: null, type: null, value: null })
    }

    const value = this.options.find((el) => el.value === option)

    if (!category && value.type !== 'link') {
      return this.props.handleSetValues({ category: option })
    }

    this.props.field.onChange?.(value.value)

    return this.props.handleSetValues({ type: value.value.startsWith('booqable://') ? 'option' : 'link', value })
  }

  handleOptionValid = (input) => {
    if (!input) return false

    return stringIsLink(input)
  }

  handleSearch = (query) => {
    const { onFilter } = this.props

    onFilter({
      name: {
        match: query
      }
    })
  }

  render () {
    const {
      category, field, type, onGoBack,
      onCreate, loading, total, placeholder,
      canLoadMore, onLoadMore, records
    } = this.props
    const { closeOnClick } = this.state

    const components = this.components

    return (
      <BUrlSelect>
        <Select
          {...field}
          value={this.value || this.props.value}
          options={this.options}
          onChange={this.handleChange}
          onCreateOption={onCreate}
          onBackClick={onGoBack}
          closeMenuOnSelect={!!category || closeOnClick}
          controlShouldRenderValue={!!this.value || !!this.props.value}
          components={components}
          type={type}
          category={category}
          total={total}
          isValidNewOption={this.handleOptionValid}
          isClearable={!!this.value || !!this.props.value}
          loading={records.size?.() === 0 && loading}
          placeholder={placeholder}
          isSearchable
          forceValue
          forceOnChange
          creatable
          noOptionsMessage={this.getNoOptionsMessage}
          onMenuScrollToBottom={canLoadMore && onLoadMore}
          onInputChange={this.handleSearch}
        />
      </BUrlSelect>
    )
  }
}

export default withTranslation('settings')(UrlSelect)
