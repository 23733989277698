import styled, { css } from 'styled-components'
import { color, size, fontSize, fontWeight } from 'ui/themes/utils'

import I from 'ui/elements/I'

export const DatePickerInput = styled.div`
  width: 100%;
`

export const ClearButton = styled.div`
  right: 0;
  font-size: ${fontSize()};
  cursor: pointer;

  &:hover {
    ${I} {
      color: ${color('Text/Secondary')};
    }
  }

  ${I} {
    font-size: ${fontSize()};
  }
`

export const Input = styled.div`
  position: relative;
  box-sizing: border-box;
  border: ${size('borderWidth')} solid ${color('Misc/Divider')};
  border-radius: ${size('borderRadius')};
  width: 100%;
  display: inline-block;
  padding-left: ${props => props.showIcon && !props.timeOnly ? size('controlHeight')(props) : size('padding')(props)};
  padding-right: ${props => size('padding')(props)};
  height: ${size('controlHeight')};

  background: ${color('white')};

  &:hover {
    z-index: 1;
  }

  ${props => !props.error && !props.focused && css`
    &:hover {
      border-color: ${color('borderHover')};
    }
  `}

  ${props => props.focused && css`
    border-color: ${color('Primary/Base')};
    z-index: 1;
  `}

  ${props => props.error && css`
    border-color: ${color('Danger/Base')};
  `}

  ${props => props.disabled && css`
    &&& {
      background: ${color('Background/Base')};
      &, * { color: ${color('Text/Secondary')} !important; }
      pointer-events: none;
      cursor: default;
    }
  `}

  ${I} {
    left: 0;
    pointer-events: none;
    font-size: 1.25em;
  }

  ${I}, ${ClearButton} {
    position: absolute;
    height: ${size('controlHeight')};
    width: ${size('controlHeight')};
    font-style: normal;
    line-height: ${size('controlHeight')};
    text-align: center;
    color: ${color('Text/Tertiary')};
  }

  /* To make up for the border */
  input {
    margin-top: -1px;
  }
`

export const MobileWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background: ${color('white')};
  color: ${color('Text/Primary')};

  /* Same as ui/components/Popover/Controlled */
  z-index: 999999999;

  overflow-y: auto;

  .DayPicker-Day {
    /* Width is determined by window width - padding divided by number of columns (7 days in a week) */
    min-width: calc((100vw - 30px) / 7) !important;
    /* maintain aspect ration use same calculation but times 1.2 */
    height: calc(((100vw - 30px) / 7) * 1.2) !important;

    margin: 0 !important;
  }

  .DayPicker-Weekday {
    /* Width is determined by window width - padding divided by number of columns (7 days in a week) */
    min-width: calc((100vw - 30px) / 7) !important;

    border-right: ${size('borderWidth')} solid transparent;

    margin: 0 !important;
  }

  .DayPicker-Month {
    padding: 0 15px;
    margin-top: 30px;
  }
`

export const MobileHeader = styled.span`
  display: block;

  padding: ${size('padding', 'md')};

  color: ${color('Primary/Base')};

  border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};

  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('lighter')};

  > span {
    color: ${color('Text/Primary')};
  }
`
