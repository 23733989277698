import styled, { css } from 'styled-components'
import { color, size, fontSize } from 'ui/themes/utils'

export const Inner = styled.span`
  position: relative;
  display: block;
  flex-shrink: 0;

  background: ${color('white')};
  color: ${color('Text/Primary')};

  ${(props) => props.color === 'primary' && css`
    background: ${color('Primary/Base')};
  `}

  box-shadow: ${size('shadow', 'md')};

  border-radius: ${size('borderRadius', 'sm')};
  line-height: 1.2;
  font-size: ${fontSize('md')};

  z-index: 10;

  ${(props) => (!props.interactive || props.defaultPopoverStyling) && css`
    width: max-content;
    max-width: ${props.maxWidth || 230}px;

    padding: ${size('padding', 'sm')} ${size('padding', 'md')};

    text-align: left;

    ${(props) => props.center && css`
      text-align: center;
    `}
  `}

  ${({ noOverflow }) => noOverflow && css`
    overflow: hidden;
  `}
`

export const Arrow = styled.span`
  position: absolute;
  display: block;

  width: 15px;
  height: 100%;

  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${color('white')};
    box-shadow: ${size('shadow', 'md')};
    transform: rotate(45deg);
  }

  ${(props) => {
    if (props.direction) {
      switch (props.direction) {
        case 'left':
          return css`
            top: 50%;
            right: 0;

            transform: translateX(100%) translateY(-50%);

            &::after {
              top: 50%;
              left: 0;

              transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }
          `
        case 'right':
          return css`
            top: 50%;
            left: 0;

            transform: translateX(-100%) translateY(-50%);

            &::after {
              right: 0;
              top: 50%;

              transform: translateX(50%) translateY(-50%) rotate(45deg);
            }
          `
        case 'bottom':
          return css`
            top: 0;
            left: 50%;

            width: 100%;
            height: 15px;

            transform: translateX(-50%) translateY(-100%);

            &::after {
              bottom: 0;
              left: 50%;

              transform: translateX(-50%) translateY(50%) rotate(45deg);
            }
          `
        default:
          return css`
            bottom: 0;
            left: 50%;

            width: 100%;
            height: 15px;

            transform: translateX(-50%) translateY(100%);

            &::after {
              left: 50%;
              top: 0;

              transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }
          `
      }
    }
  }}

  @media screen and (max-width: 767px) {
    &::after {
      display: none;
    }
  }
`

export const Content = styled.span`
  position: absolute;
  display: flex;

  justify-content: center;

  opacity: 0;
  pointer-events: none;

  z-index: 100000000;

  ${(props) => props.disabled && css`
    opacity: 0 !important;
  `}

  ${(props) => {
    switch (props.direction) {
      case 'left':
        return css`
          top: 50%;
          left: -10px;

          transform: translateX(-100%) translateY(-50%);
        `
      case 'right':
        return css`
          top: 50%;
          right: -10px;

          transform: translateX(100%) translateY(-50%);
        `
      case 'bottom':
        return css`
          bottom: -10px;
          left: 50%;

          transform: translateX(-50%) translateY(100%);
        `
      default:
        return css`
          top: -10px;
          left: 50%;

          transform: translateX(-50%) translateY(-100%);
        `
    }
  }}

  ${(props) => !props.notFixed && css`
    @media screen and (max-width: 767px) {
      position: fixed;
      display: block;

      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }
  `}

  ${(props) => props.manual && css`
    position: fixed;

    opacity: 1;
    pointer-events: all;

    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
  `}

  ${({ transitions }) => transitions && css`
    transition: all 300ms ease;
    transition-delay: 300ms;
  `}

  ${({ tippy }) => tippy && css`
    position: relative;

    transform: none;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;

    opacity: 1;

    pointer-events: all;
  `}
`

export const Popover = styled.div`
  position: relative;
  width: 100%;

  ${(props) => props.inline && css`
    display: inline;
    width: auto;
    vertical-align: middle;
  `}

  ${(props) => props.inlineBlock && css`
    display: inline-block;
    width: auto;
    vertical-align: middle;
  `}

  &:hover ${Content} {
    opacity: 1;

    ${(props) => props.interactive && css`
      pointer-events: all;
    `}
  }

  ${props => props.open && css`
    ${Content} {
      pointer-events: all;
      opacity: 1;
      transition-delay: 0ms;
      transition: all 100ms ease;
    }
  `}

  ${props => props.open === false && css`
    ${Content} {
      opacity: 0 !important;
      transition-delay: 0ms;
      transition: all 100ms ease;
    }
  `}
`

export const TippyArrow = styled.span`
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: -1;

  &:before {
    content: '';
    transform: rotate(45deg);
    background: ${color('white')};
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: -1;

    ${(props) => props.color === 'primary' && css`
      background: ${color('Primary/Base')};
    `}
  }
`

export const Wrapper = styled.span`
  ${({ sticky, stickyConfig }) => sticky && stickyConfig && css`
    position: sticky;

    ${Object.entries(stickyConfig).map(([key, value]) => `${key}: ${value};`).join(' ')}
  `}
`
