// React
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'
import Yup from 'shared/utils/yup'

// Components
import Layout from 'signup/components/Layout'
import Button from 'ui/components/Button'
import Div from 'ui/elements/Div'
import Select from 'signup/components/Select'

// Shared
import withForm, { Form } from 'shared/form'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import SignupActions from 'signup/redux/actions/signup'
import {
  productCountData,
  orderCountData,
  percentageOnlineOrdersData,
  yesNoData,
  projectedRevenueData
} from './static/marketingData'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'

export class Step4 extends React.Component {
  static displayName = 'Step4'
  static propTypes = {
    t: PropTypes.func.isRequired,
    values: PropTypes.shape({
      product_count: PropTypes.string,
      order_count: PropTypes.string,
      percentage_online_orders: PropTypes.string,
      charge_orders: PropTypes.string,
      projected_revenue: PropTypes.string
    }),
    submitPromise: PropTypes.instanceOf(Promise)
  }

  componentDidMount = () => {
    EventTracker.page()
    EventTracker.track('Signup: Step 4')
  }

  render = () => {
    const { submitPromise, t } = this.props

    return (
      <Layout step={4}>
        <Form>
          <Select
            identifier="product_count"
            options={productCountData(t)}
          />

          <Select
            identifier="order_count"
            options={orderCountData(t)}
          />

          <Select
            identifier="percentage_online_orders"
            options={percentageOnlineOrdersData()}
          />

          <Select
            identifier="charge_orders"
            options={yesNoData(t)}
          />

          <Select
            identifier="projected_revenue"
            options={projectedRevenueData()}
          />

          <Div modifiers={'margin-top-lg margin-top-md-xl'}>
            <Button
              size="lg"
              type="submit"
              promise={submitPromise}
              block
              borderRadius={'mdplus'}
              modifiers={'margin-bottom-xl'}
            >
              {t('signup.continue')}
            </Button>
          </Div>
        </Form>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      product_count: state.signup.product_count || '',
      order_count: state.signup.order_count || '',
      percentage_online_orders: state.signup.percentage_online_orders || '',
      charge_orders: state.signup.charge_orders || '',
      projected_revenue: state.signup.projected_revenue || ''
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (values) => {
      return SignupActions.update(window.id, values)
    },
    validationSchema: Yup.object().shape({
      product_count: Yup
        .string()
        .required(),
      order_count: Yup
        .string()
        .required(),
      percentage_online_orders: Yup
        .string()
        .required(),
      charge_orders: Yup
        .string()
        .required()
        .oneOf(['yes', 'no']),
      projected_revenue: Yup
        .string()
        .required()
    }),
    serverSuccess: () => {
      RoutingUtils.navigate(`/${window.locale}/step-5`)
    }
  }
}

export default compose(
  withTranslation('signup'),
  reduxPureConnect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Step4)
