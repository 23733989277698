import styled, { css } from 'styled-components'
import { color, size } from 'ui/themes/utils'

import Input from 'ui/elements/Input'

export const Slider = styled.div`
  display: flex;
  align-items: center;

  gap: ${size('margin', 'xs')};
`

export const Range = styled.div`
  flex: 1;

  ${Input}[type="range"] {
    display: block;

    appearance: none;
    -webkit-appearance: none;

    background-color: ${color('Misc/Divider')};

    height: 4px;

    padding: 0;

    border: none;
    border-radius: ${size('borderRadius', 'xxs')};

    &::-webkit-slider-thumb {
      appearance: none;
      -webkit-appearance: none;

      width: 16px;
      height: 16px;

      margin-top: -7px;

      background-color: ${color('Primary/Base')};

      border-radius: 50%;

      cursor: grab;

      transition: transform 0.2s ease-in-out;

      &:active {
        transform: scale(1.1);

        cursor: grabbing;
      }
    }

    &::-webkit-slider-runnable-track {
      height: 4px;

      border: none;
      border-radius: ${size('borderRadius', 'xxs')};

      cursor: pointer;

      ${({ progress }) => css`
        background-image: linear-gradient(
          to right,
          ${color('Primary/Base')} 0%,
          ${color('Primary/Base')} ${progress}%,
          ${color('Misc/Divider')} ${progress}%,
          ${color('Misc/Divider')} 100%
        );
      `}
    }
  }
`
