import styled, { css } from 'styled-components'

import { size, color, fontSize } from 'ui/themes/utils'

export const PhoneInput = styled.span`
  .PhoneInput {
    vertical-align: middle;

    width: 100%;
    height: ${size('controlHeight')};

    padding: 0 ${size('padding')};

    color: ${color('Text/Primary')};
    background: ${color('white')};

    border: ${size('borderWidth')} solid ${color('Misc/Divider')};
    border-radius: ${size('borderRadius')};

    box-sizing: border-box;

    .PhoneInputCountryIcon {
      box-shadow: none;

      img {
        vertical-align: top;
      }
    }

    input {
      border: 0;
      font-size: ${fontSize()};
      font-weight: 400;
      line-height: 1;
      outline: 0;

      border-top: inherit;
      border-bottom: inherit;
      height: ${size('controlHeight')};
      border-radius: 0;

      &::placeholder {
        color: ${color('Text/Tertiary')} !important;
        font-size: ${fontSize()} !important;
      }
    }

    ${props => props.readOnly && css`
      color: ${color('Text/Tertiary')} !important;
      border-color: ${color('Misc/Divider')} !important;
    `}

    ${props => props.error && css`
      border-color: ${color('Danger/Base')};

      &:hover:not(:focus) {
          border-color: ${color('Danger/Base')};
      }

      &:hover {
          border-color: ${color('Danger/Base')};
      }

      &:focus {
        border-color: ${color('Danger/Base')};

        &:hover {
          border-color: ${color('Danger/Base')};
        }
      }
    `}

    ${props => !props.error && css`
      &:hover {
        border-color: ${color('borderHover')};
      }

      &:focus {
        border-color: ${color('Primary/Base')};

        &:hover {
          border-color: ${color('Primary/Base')};
        }
      }
    `}

    &--focus {
      border-color: ${color('Primary/Base')} !important;

      &:hover {
        border-color: ${color('Primary/Base')} !important;
      }
    }

    ${props => props.disabled && css`
      background: ${color('Background/Base')};

      pointer-events: none;
    `}

    ${props => props.valid && css`
      border-color: ${color('Success/Base')};

      &:hover {
        border-color: ${color('Success/Base')};
      }
    `}
  }
`
