// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BRadioGroup from 'ui/blocks/RadioGroup'
import Icon from 'ui/components/Icon'

// Shared

export default class RadioBox extends Component {
  static displayName = 'RadioBox'

  static propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    children: PropTypes.node,
    onClick: PropTypes.func,
    exclusive: PropTypes.bool,
    bordered: PropTypes.bool,
    'data-tid': PropTypes.string,
    modifiers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  }

  render () {
    const {
      checked,
      label,
      description,
      children,
      onClick,
      exclusive,
      bordered,
      modifiers,
      'data-tid': dataTid
    } = this.props

    const showChildren = (children && !exclusive) || (children && checked)

    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    return (
      <BRadioGroup.Radio
        modifiers={nestedModifiers.radioGroup}
        checked={checked}
        onClick={onClick}
        bordered={bordered}
        data-tid={dataTid}
      >
        <Icon icon="circle" modifiers={nestedModifiers.radioGroupIcon} />
        <BRadioGroup.Label modifiers={nestedModifiers.radioGroupLabel}>{label}</BRadioGroup.Label>
        {description &&
          <BRadioGroup.Description>{description}</BRadioGroup.Description>
        }
        {showChildren &&
          <BRadioGroup.ChildContainer>{children}</BRadioGroup.ChildContainer>
        }
      </BRadioGroup.Radio>
    )
  }
}
