// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BInput from 'ui/blocks/Input'
import Icon from 'ui/components/Icon'

// Shared

/**
 * Input with icon prepending
 *
 * @example
 *   <Input icon="user" field={field} error />
 */
export default class Input extends Component {
  static displayName = 'Input'

  static propTypes = {
    field: PropTypes.object,
    valid: PropTypes.bool,
    error: PropTypes.any,
    type: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    autoFocus: PropTypes.bool,
    inputRef: PropTypes.func,
    loading: PropTypes.bool
  }

  static defaultProps = {
    size: 'md',
    type: 'text'
  }

  render () {
    const { inputRef, field, type, size, error, valid, loading, icon, ...otherProps } = this.props
    // Necessary to tell react this input is controlled
    const value = field.value !== undefined ? field.value : ''
    const inputIcon = loading ? 'spinner-third' : icon

    return (
      <BInput size={size}>
        {icon &&
          <Icon icon={inputIcon} spin={loading} />
        }
        <BInput.Input
          ref={(ref) => {
            this.ref = ref
            inputRef && inputRef(ref)
          }}
          type={type}
          size={size}
          error={error}
          valid={valid}
          {...field}
          value={value}
          {...otherProps}
        />
      </BInput>
    )
  }
}
