import styled from 'styled-components'
import { injectModifiers } from 'ui/themes/utils'
import modifiers from 'ui/themes/modifiers'

const B = styled.b`
  font-weight: 700;
  
  ${({ theme }) => injectModifiers(modifiers(theme))}
`

export default B
