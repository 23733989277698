import styled, { css } from 'styled-components'

import { color, size, fontSize } from 'ui/themes/utils'

import Div from 'ui/elements/Div'

export const Paginator = styled(Div)`
  display: flex;
  align-items: center;
  height: ${size('controlHeight')};

  ${({ paginatePosition }) => paginatePosition === 'right' && css`
    float: right;
  `}
`

export const PageContainer = styled(Div)`
  display: flex;
  margin-right: ${size('margin', 'md')};
  font-size: ${fontSize('md')};

  ${({ paginatePosition }) => paginatePosition === 'right' && css`
    margin-left: ${size('margin', 'md')};
    margin-right: 0;
  `}
`

export const Metadata = styled(Div)`
  font-size: ${fontSize('sm')};
`

export const Page = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: ${size('padding')};
  border: 1px solid ${color('Misc/Divider')};
  background-color: ${color('white')};
  border-left: 0;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${color('Primary/Background2')};
  }
  &:first-child {
    border-radius: ${size('borderRadius')} 0 0  ${size('borderRadius')};
    border-left: 1px solid ${color('Misc/Divider')};
  }
  &:last-child {
    border-radius: 0 ${size('borderRadius')} ${size('borderRadius')} 0;
  }
  ${({ active }) => active && css`
    color: ${color('Text/Inverted')};
    background-color: ${color('Primary/Base')};
    &:hover {
      background-color: ${color('Primary/Base')}
    }
  `}
`
