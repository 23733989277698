import styled, { css } from 'styled-components'
import { color, fontSize, fontWeight, size, opacity } from 'ui/themes/utils'

import DescriptionElement from 'ui/elements/Description'
import LabelElement from 'ui/elements/Label'
import Span from 'ui/elements/Span'

export const Label = styled(Span)`
  display: inline-block !important;
  vertical-align: top;

  cursor: pointer;

  color: ${color('Text/Primary')};

  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('semibold')};
  line-height: 1.6em;
`

export const Description = styled(DescriptionElement)`
  width: 100%;
`

export const Input = styled.input.attrs(props => ({
  type: 'checkbox'
}))`
  display: none;
`

export const Checkbox = styled(LabelElement)`
  width: 100%;

  cursor: pointer;

  font-size: ${fontSize()};

  ${props => props.withLabel && css`
    padding-left: ${size('padding', 'lg')};

    svg:first-child {
      float: left;
      margin-left: -${size('padding', 'lg')};
      margin-top: ${size('padding', 'xxxs')};
    }
  `}

  svg {
    &:first-child {
      display: inline-block;
      vertical-align: top;

      width: 1.3em;
      height: 1.3em;

      padding: 0.3em;

      color: ${color('Text/Inverted')};
      background: ${color('Text/Inverted')};

      border: 1px ${color('Misc/Divider')} solid;
      border-radius: 4px;

      font-size: 0.7em;
      font-weight: ${fontWeight('bold')};
      line-height: calc(1.9em - 1px);
      text-align: center;
    }
  }

  &:hover {
    svg {
      &:first-child {
        background: ${color('light')};
      }
    }
  }

  ${props => props.disabled && css`
    pointer-events: none;
    opacity: ${opacity('disabled')};
  `}

  ${props => !props.value && css`
    svg {
      &:first-child::before {
        content: '';
      }
    }
  `}

  ${props => props.value && typeof props.value === 'boolean' && css`
    svg {
      &:first-child {
        background: ${color('Primary/Base')};
      }
    }

    &:hover {
      svg {
        &:first-child {
          background: ${color('Primary/Base')};
        }
      }
    }
  `}

  ${props => props.value && typeof props.value !== 'boolean' && css`
    svg {
      &:first-child {
        background: ${color('light')};
      }
    }

    &:hover {
      svg {
        &:first-child {
          background: ${color('light')};
        }
      }
    }
  `}
`

export const CheckboxChildren = styled.div`
  padding-left: 2em;
`
