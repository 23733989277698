// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import BUrlSelect from 'ui/blocks/UrlSelect'
import FocalImage from 'ui/components/FocalImage'
import Icon from 'ui/components/Icon'

// Libraries
import { components } from 'react-select'

// Shared
import NO_PRODUCT_IMAGE from '@assets/images/no-product-image.png'

const Product = (props) => {
  const isProduct = props.data.type === 'item'

  return (
    <BUrlSelect.Option
      {...props.innerProps}
      isSelected={props.isSelected}
      isFocused={props.isFocused}
      columns={['64px', '1fr']}
      isCollection
      borderBottom={!isProduct}
    >
      <BUrlSelect.OptionCell>
        {isProduct ? (
          <FocalImage
            size="40px"
            coordinates={props.data.photo?.coordinates ?? { x: 0, y: 0 }}
            url={props.data.photo?.original_url || NO_PRODUCT_IMAGE}
          />
        ) : (
          <Icon icon="box" />
        )}
      </BUrlSelect.OptionCell>
      <BUrlSelect.OptionCell>
        <components.Option {...props} />
      </BUrlSelect.OptionCell>
    </BUrlSelect.Option>
  )
}

Product.propTypes = {
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  // react-select internal props
  innerProps: PropTypes.object,
  data: PropTypes.shape({
    type: PropTypes.string,
    photo: PropTypes.shape({
      coordinates: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
      }),
      original_url: PropTypes.string
    })
  })
}

export default Product
