// React
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Libraries
import cuid from 'cuid'

// Components
import BCheckbox from 'ui/blocks/Checkbox'
import Icon from 'ui/components/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

// Shared

/**
 * A humble checkbox with optional label or description
 *
 * @example
 *   <Checkbox field={{ onChange, value, name }} label="Weather is nice" description="Only check this if the weather is nice" />
 */
export default class Checkbox extends Component {
  static displayName = 'Checkbox'

  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    labelImage: PropTypes.node,
    description: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.func
    ]),
    field: PropTypes.object,
    disabled: PropTypes.bool,
    'data-tid': PropTypes.string
  }

  constructor (props) {
    super(props)

    this.state = {
      id: props.id || cuid()
    }
  }

  get icon () {
    const value = this.props.field.value

    if (value && value === 'partial') {
      return regular('minus')
    } else {
      return regular('check')
    }
  }

  handleChange = (e) => {
    if (this.props.field && this.props.field.onChange) {
      this.props.field.onChange(e.target.checked)
    }
  }

  render () {
    const { label, labelImage, description, field, disabled, id, form, children, modifiers, ...otherProps } = this.props
    const inputId = id || this.state.id
    // If value is undefined set it to an empty string
    // to let react know the input is controlled
    const value = field.value || ''

    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    return (
      <Fragment>
        <BCheckbox
          modifiers={nestedModifiers.self}
          withLabel={label}
          htmlFor={inputId}
          disabled={disabled}
          value={field.value}
          {...otherProps}
        >
          <FontAwesomeIcon icon={this.icon} />
          <BCheckbox.Input id={inputId} name={field.name} onChange={this.handleChange} checked={value} />
          {labelImage && labelImage}
          {label &&
            <BCheckbox.Label modifiers={nestedModifiers.label} htmlFor={inputId}>{label}</BCheckbox.Label>
          }
          {description &&
            <BCheckbox.Description>{description}</BCheckbox.Description>
          }
        </BCheckbox>
        {children && (
          <BCheckbox.Children>
            {children}
          </BCheckbox.Children>
        )}
      </Fragment>
    )
  }
}
