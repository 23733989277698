// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import lowerCase from 'lodash/lowerCase'

// Components
import Div from 'ui/elements/Div'
import { Row, Col } from 'ui/components/Grid'
import Label from 'ui/elements/Label'
import Hr from 'ui/elements/Hr'

// Shared
import { Field } from 'shared/form'

/**
 * Company address settings.
 *
 * @example
 *   <LabelPersonalizationFields showIfValid={true} />
 */

class LabelPersonalizationFields extends Component {
  static displayName = 'LabelPersonalizationFields'

  static propTypes = {
    // Form
    fieldNamePrefix: PropTypes.string,
    fieldNameSuffix: PropTypes.string,
    showIfValid: PropTypes.bool,
    excludeLabels: PropTypes.array,
    t: PropTypes.func
  }

  static defaultProps = {
    fieldNamePrefix: '',
    fieldNameSuffix: '',
    excludeLabels: [],
    showIfValid: false
  }

  render () {
    const { fieldNamePrefix, fieldNameSuffix, showIfValid, excludeLabels, t } = this.props

    const defaultText = `(${lowerCase(t('common.default'))})`
    const labelOptions = [
      'customer',
      'order',
      'quote',
      'contract',
      'packing_slip',
      'start',
      'stop'
    ].filter(label => !excludeLabels.includes(label))
    const labelSelectOptions = {
      customer: [
        { label: `${t('common.label_personalization.options.customer')} ${defaultText}`, value: 'customer' },
        { label: t('common.resources.client_one'), value: 'client' },
        { label: t('common.resources.student_one'), value: 'student' }
      ],
      order: [
        { label: `${t('common.label_personalization.options.order')} ${defaultText}`, value: 'order' },
        { label: t('common.resources.booking_one'), value: 'booking' },
        { label: t('common.resources.reservation_one'), value: 'reservation' },
        { label: t('common.resources.project_one'), value: 'project' }
      ],
      quote: [
        { label: `${t('common.label_personalization.options.quote')} ${defaultText}`, value: 'quote' },
        { label: t('common.resources.proposal_one'), value: 'proposal' }
      ],
      contract: [
        { label: `${t('common.label_personalization.options.contract')} ${defaultText}`, value: 'contract' },
        { label: t('common.resources.waiver_one'), value: 'waiver' }
      ],
      packing_slip: [
        { label: `${t('common.label_personalization.options.packing_slip')} ${defaultText}`, value: 'packing_slip' },
        { label: t('common.resources.pull_sheet_one'), value: 'pull_sheet' }
      ],
      start: [
        { label: `${t('common.start_variants.pick_up.present')} ${defaultText}`, value: 'pick_up' },
        { label: t('common.start_variants.start.present'), value: 'start' },
        { label: t('common.start_variants.check_in.present'), value: 'check_in' }
      ],
      stop: [
        { label: `${t('common.stop_variants.return.present')} ${defaultText}`, value: 'return' },
        { label: t('common.stop_variants.stop.present'), value: 'stop' },
        { label: t('common.stop_variants.checkout.present'), value: 'checkout' }
      ]
    }

    return (
      labelOptions.map(function (object, index) {
        return <Div key={index}>
          <Row modifiers={'margin-bottom-sm no-margin-bottom-md'}>
            <Col md="7" modifiers={'padding-bottom-sm no-padding-bottom-md'}>
              <Label modifiers={'margin-top-sm'}>
                {t(`common.label_personalization.options.${object}`)}
              </Label>
              {t(`common.label_personalization.explanation.${object}`)}
            </Col>
            <Col md="5">
              <Field
                type="select"
                name={`${fieldNamePrefix}${object}${fieldNameSuffix}`}
                options={labelSelectOptions[object]}
                searchable={false}
                clearable={false}
                showIfValid={showIfValid}
                renderNativeOnMobile={false}
                data-tid={`Label ${object}`}
              />
            </Col>
          </Row>
          { (index + 1) !== labelOptions.length && <Hr modifiers={'display-none display-md-block'} /> }
        </Div>
      })
    )
  }
}

export default withTranslation('settings')(LabelPersonalizationFields)
