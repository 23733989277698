// React
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import { compose } from 'redux'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'
import Yup from 'shared/utils/yup'
import _isEmpty from 'lodash/isEmpty'
import i18n from 'i18next'

// Components
import Layout from 'signup/components/Layout'
import Checkbox from 'ui/components/Checkbox'
import Button from 'ui/components/Button'
import Link from 'ui/components/Link'
import BInputField from 'ui/blocks/InputField'
import Div from 'ui/elements/Div'
import Badge from 'ui/components/Badge'

// Shared
import withForm, { Form, Field } from 'shared/form'
import SignupActions from 'signup/redux/actions/signup'
import emailValidation from 'signup/utils/email_validation'
import customerReview from 'shared/utils/reviews'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'
import locales from 'shared/constants/locales'

class Step1 extends Component {
  static displayName = 'Step1'
  static propTypes = {
    t: PropTypes.func.isRequired,
    Trans: PropTypes.func.isRequired,
    // Data
    emailValid: PropTypes.bool,
    isEU: PropTypes.bool,
    values: PropTypes.shape({
      email: PropTypes.string,
      password: PropTypes.string,
      company_name: PropTypes.string
    }),
    fields: PropTypes.shape({
      agreement_accepted: PropTypes.bool,
      email_subscribed: PropTypes.bool
    }),
    industry: PropTypes.string,
    // Dispatch
    setInitialValues: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitPromise: PropTypes.instanceOf(Promise),
    errors: PropTypes.object
  }

  state = {
    review: {}
  }

  componentDidMount = () => {
    this.checkLocale()
    this.props.setInitialValues()

    SignupActions.identify().then((response) => {
      if (!response.payload.is_eu) {
        this.props.setFieldValue('agreement_accepted', true)
        this.props.setFieldValue('email_subscribed', true)
      }
    })

    EventTracker.page()

    // Sets the customer review based on the industry url parameter
    const industry = this.props.industry
    const context = industry ? 'general' : 'signup'

    this.setState({ review: customerReview(context, industry) })
  }

  checkLocale = () => {
    // Make sure the requested locale is supported
    // Otherwise fallback to 'en'
    const supportedLocales = locales.map(o => (o.value))

    if (!supportedLocales.includes(window.locale)) {
      window.locale = 'en'
      i18n.changeLanguage(window.locale).then((t) => {
        RoutingUtils.navigate(`/${window.locale}`)
      })
    }
  }

  get termsOfServiceLink () {
    return <Link
      noBlankIcon
      href="https://booqable.com/agreement"
      target="_blank"
      title={this.props.t('signup.agreement')}
    />
  }

  get privacyPolicyLink () {
    return <Link
      noBlankIcon
      href="https://booqable.com/privacy-policy"
      target="_blank"
      title={this.props.t('signup.privacy_policy')}
    />
  }

  render = () => {
    const { submitPromise, errors, t, Trans } = this.props
    const showAgreementError = Object.keys(errors).length === 1 && errors.agreement_accepted

    return (
      <Layout
        step={1}
        socialProof
        subtitleBadge
      >
        <Form noValidate>
          <Field
            name="email"
            type="email"
            label={t('signup.fields.email.label')}
            placeholder={t('signup.fields.email.placeholder')}
            autoComplete="email"
            initialValid={this.props.emailValid}
            autoFocus={!this.props.emailValid}
            showIfValid
          />

          <Field
            name="password"
            type="password"
            label={t('signup.fields.password.label')}
            placeholder={t('signup.fields.password.placeholder')}
            autoComplete="new-password"
            autoFocus={this.props.emailValid}
            showIfValid
          />

          <Field
            name="company_name"
            type="text"
            label={t('common.company_name')}
            placeholder={t('signup.fields.company_name.placeholder')}
            showIfValid
          />

          {this.props.isEU &&
            <Fragment>
              <Div>
                <Field
                  data-tid="Accept agreement"
                  component={Checkbox}
                  name="agreement_accepted"
                  label={
                    <Trans
                      i18nKey="signup.fields.agreement.label"
                      ns="signup"
                      components={{
                        TermsOfServiceLink: this.termsOfServiceLink,
                        PrivacyPolicyLink: this.privacyPolicyLink
                      }}
                    />
                  }
                  modifiers={{ label: showAgreementError ? 'text-Danger/Base' : '' }}
                />
                {showAgreementError && (
                  <BInputField.Error modifiers={'margin-left-lg no-margin-top margin-bottom-sm'}>
                    {t('signup.fields.agreement.error')}
                  </BInputField.Error>
                )}
              </Div>
              <Div>
                <Field
                  component={Checkbox}
                  name="email_subscribed"
                  label={t('signup.fields.newsletter.label')}
                />
              </Div>
            </Fragment>
          }

          {!this.props.isEU &&
            <Div className="agreement-text">
              <Trans
                i18nKey="signup.agreement_notice"
                ns="signup"
                components={{
                  TermsOfServiceLink: this.termsOfServiceLink,
                  PrivacyPolicyLink: this.privacyPolicyLink
                }}
              />
            </Div>
          }

          <Div modifiers={'margin-top-lg margin-top-md-xl'}>
            <Button
              size="lg"
              type="submit"
              promise={submitPromise}
              block
              borderRadius={'mdplus'}
              modifiers={'margin-bottom-md'}
            >
              {t('signup.start_trial')}
            </Button>

            <Div modifiers={'text-center'}>
              <Badge
                flatColor="light"
                modifiers={'text-Text/Secondary font-size-md font-weight-normal margin-bottom-xl'}
                multiLine
              >
                {t('signup.step_1.no_creditcard_required')}
              </Badge>
            </Div>
          </Div>
        </Form>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    isEU: typeof state.signup.is_eu !== typeof true || state.signup.is_eu,
    emailValid: Boolean(emailValidation(state.signup.email || props.searchParams.get('email'))),
    industry: props.searchParams.get('industry'),
    initialValues: {
      email: state.signup.email || '',
      password: state.signup.password || '',
      company_name: state.signup.company_name || '',
      agreement_accepted: false,
      email_subscribed: false
    },
    validateOnBlur: false,
    validateOnChange: false
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const checkCompanyNameAvailability = async (value, resolve) => {
    const response = await SignupActions.checkCompanyAvailability(value)
    const available = Boolean(response?.payload?.company_available)

    resolve(available)
  }

  return {
    setInitialValues: (signup) => {
      const values = {
        // A theme can be passed, which will be pre-installed on the account
        theme: props.searchParams.get('theme'),
        // Marketing
        source: props.searchParams.get('source'),
        medium: props.searchParams.get('medium'),
        campaign: props.searchParams.get('campaign'),
        keyword: props.searchParams.get('keyword'),
        ga_client_id: props.searchParams.get('ga_client_id')
      }

      // Save initial values if passed as url parameters
      if (values.theme || values.source || values.medium || values.campaign || values.keyword || values.ga_client_id) {
        SignupActions.setInitialValues(values)

        // Navigate to remove the parameters from the url
        // We need to use setTimeout to be on the next render tick
        setTimeout(() => {
          RoutingUtils.navigate(`/${window.locale}`)
        }, 0)
      }

      if (!window.created) {
        SignupActions.create(values)

        EventTracker.globalData = {
          theme: values.theme,
          marketing_source: values.source || 'None',
          marketing_medium: values.medium || 'None',
          marketing_campaign: values.campaign || 'None',
          marketing_keyword: values.keyword || 'None'
        }

        EventTracker.track('Signup: Step 1', { industry: props.searchParams.get('industry') })
      } else {
        SignupActions.update(window.id, values)
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup
        .string()
        .required()
        .email(),
      password: Yup
        .string()
        .required()
        .min(6),
      company_name: Yup
        .string()
        .required()
        .test(
          'company_name',
          props.t('signup.company_name_is_taken'),
          (value) => {
            if (_isEmpty(value)) {
              return new Promise(resolve => resolve())
            } else {
              return new Promise(resolve => checkCompanyNameAvailability(value, resolve))
            }
          }
        ),
      agreement_accepted: Yup
        .boolean()
        .required()
        .oneOf([true])
    }),
    handleSubmit: (values) => {
      return SignupActions.update(window.id, values)
    },
    serverSuccess: () => {
      RoutingUtils.navigate(`${window.locale}/step-2`)
    }
  }
}

export default compose(
  withTranslation('signup'),
  reduxPureConnect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Step1)
