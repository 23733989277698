// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import Button from 'ui/components/Button'

// Shared

export default class ButtonBox extends Component {
  static displayName = 'ButtonBox'

  static propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    icon: PropTypes.string
  }

  render () {
    const { checked, label, onClick, size, icon } = this.props

    return (
      <Button
        onClick={onClick}
        color={checked ? 'primary' : 'white'}
        size={size}
        data-tid={`Radio button: ${label}`}
        icon={icon}
      >
        {label}
      </Button>
    )
  }
}
