// React
import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'

// Libraries
import get from 'lodash/get'

// Components
import { Field as FormikField } from 'formik'
import InputField from 'ui/components/InputField'

// Shared

export default class Field extends Component {
  static displayName = 'Field'

  static propTypes = {
    component: PropTypes.any,
    name: PropTypes.string,
    validate: PropTypes.func
  }

  static defaultProps = {
    component: InputField
  }

  renderChild = (component, originalProps) => {
    return (fieldProps) => {
      const { field, form } = fieldProps

      const _field = Object.assign({}, field)

      const handleChange = (e) => {
        let value

        // TODO: Should we change selects and datepickers to
        // always return the value in `target`?
        if (e && e.target) {
          value = e.target.value
        } else {
          value = e
        }

        if (originalProps.onChange) {
          originalProps.onChange(value)
        } else {
          field.onChange({
            target: {
              value,
              name: field.name
            }
          })
        }

        if (originalProps.handleChange) {
          e.persist && e.persist()
          originalProps.handleChange(e)
        }

        if (originalProps.afterChange) {
          originalProps.afterChange(value)
        }
      }

      _field.onChange = handleChange

      const error = get(form.errors, originalProps.errorKey || field.name)
      const touched = get(form.touched, field.name)

      const noFieldValue = field.value === undefined || field.value === null

      if (originalProps.defaultValue && noFieldValue && !touched && !form.dirty) {
        form.setFieldTouched(field.name, true)
        form.setFieldValue(field.name, originalProps.defaultValue)
      }

      const element = createElement(
        component,
        {
          ...originalProps,
          field: _field,
          form,
          error,
          touched
        }
      )

      return element
    }
  }

  render () {
    const { component, name, validate, ...props } = this.props

    return (
      <FormikField
        name={name}
        validate={validate}
      >{this.renderChild(component, props)}</FormikField>
    )
  }
}
