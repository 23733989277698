import {
  DatePickerInput,
  Input,
  ClearButton,
  MobileWrapper,
  MobileHeader
} from './styles'

DatePickerInput.InputContainer = Input
DatePickerInput.ClearButton = ClearButton
DatePickerInput.MobileWrapper = MobileWrapper
DatePickerInput.MobileHeader = MobileHeader

export default DatePickerInput
