import styled, { css } from 'styled-components'
import { color, size } from 'ui/themes/utils'

export const BFocalImage = styled.div`
  width: ${props => props.size ? props.size : props.width};
  height: ${props => props.size ? props.size : props.height};

  background-color: ${color('white')};

  position: relative;

  z-index: 1;

  border: ${size('borderWidth', 'xs')} solid ${color('Misc/Divider')};

  border-radius: ${size('borderRadius', 'sm')};

  ${props => props.selected && css`
    border-color: ${color('Primary/Base')};
  `}

  ${props => props.inline && css`
    display: inline-block;
    vertical-align: middle;
  `}

  ${props => props.noBorder && css`
    border: none;
  `}

  ${props => props.noBorderRadius && css`
    border-radius: 0;
  `}

  ${props => props.maintainAspect && css`
    width: 100%;
    padding-bottom: 100% !important;
  `}

  ${props => props.cursor && css`
    cursor: ${props.cursor};
  `}
`

export const Image = styled.img.attrs(props => ({ loading: 'lazy' }))`
  max-width: initial;
  max-height: initial;
  width: initial;
  height: initial;
  min-width: initial;
  min-height: initial;

  ${props => props.isLoading && css`
    /* Hide the image while the component sets the proper focus */
    visibility: hidden;
  `}
`

export const Preview = styled.img`
  width: 100%;
  height: 100%;

  filter: blur(10px);
`
