// Assuming lodash/merge is available as an ES module.
import merge from 'lodash/merge'

class EventTracker {
  // Simple EventTracker system that tracks events by posting to a specific URL.
  constructor () {
    this.globalData = {}
  }

  // Track event.
  //
  // @example
  //   EventTracker.track('Signup', { company_name: 'Some name' }, callback)
  static track (name, data, callback) {
    data = merge({}, this.globalData, data)

    window.data = data

    if (window.environment === 'development') {
      console.log(`%c EventTracker track: ${name}, ${JSON.stringify(data)}`, 'color: #24ad4e')
    }

    this.__submit(name, data, callback)
  }

  static alias (anonymousId, callback) {
    window.analytics?.alias?.(anonymousId, callback) ?? callback?.()
  }

  static identify (id, data, callback) {
    window.analytics?.identify?.(id, data, callback) ?? callback?.()
  }

  static group (id, data, callback) {
    window.analytics?.group?.(id, data, callback) ?? callback?.()
  }

  static page () {
    window.analytics?.page?.()
  }

  // Private method for submitting data
  static __submit (name, data, callback) {
    window.analytics?.track?.(name, data, {}, callback) ?? callback?.()
  }
}

export default EventTracker
