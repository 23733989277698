// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import { withTranslation } from 'shared/utils/withTranslation'

// Components
import NoticeBar from 'ui/components/NoticeBar'
import Link from 'ui/components/Link'

export class ReadOnlyModeBar extends Component {
  static displayName = 'ReadOnlyModeBar'

  static propTypes = {
    t: PropTypes.func.isRequired,
    readOnlyMode: PropTypes.bool.isRequired
  }

  render () {
    const { t } = this.props

    if (!this.props.readOnlyMode) return null

    return (
      <NoticeBar icon="exclamation-triangle" color="warning" border disableScreen>
        {t('signup.maintenance.title')}{' '}
        <Link href="https://status.booqable.com/" target="_blank">
          {t('signup.maintenance.description')}
        </Link>
      </NoticeBar>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    readOnlyMode: state.signup.read_only_mode
  }
}

const DecoratedComponent = reduxPureConnect(mapStateToProps)(ReadOnlyModeBar)

export default withTranslation('signup')(DecoratedComponent)
