import styled, { css } from 'styled-components'
import { color, size, fontSize, fontWeight } from 'ui/themes/utils'

export const ReactSelect = styled.div`
  .Select__value-container--is-multi.Select__value-container--has-value {
    padding: 4px 0 6px;

    .Select__input {
      height: auto !important;

      & > input {
        height: auto !important;
      }
    }

    div:last-child {
      height: auto !important;
    }
  }

  .Select {
    &__indicator-separator {
      display: none;
    }

    &__menu {
      /* z-index bump because of .rx-toolbar-container in app/assets/stylesheets/interface/lib/redactor.scss:405 */
      z-index: 5;
    }

    &__indicators {
      height: ${size('controlHeight')};
    }

    &__single-value {
      overflow: visible;
      color: ${color('Text/Primary')};

      &--is-disabled {
        color: ${color('Text/Secondary')} !important;
      }
    }

    &__value-container {
      height: ${size('controlHeight')} !important;
      padding: 0;

      ${({ isMulti }) => isMulti && css`
        height: auto !important;
        min-height: ${size('controlHeight')} !important;
      `}
    }

    &__input {
      height: ${size('controlHeight')} !important;

      & > input {
        height: ${size('controlHeight')} !important;
      }
    }

    &__multi-value {
      margin: 0;
      margin-top: 4px;
      margin-right: 4px;
    }

    &__control {
      padding: 0 0 0 ${size('padding')};
      height: ${size('controlHeight')} !important;
      min-height: ${size('controlHeight')};

      color: ${color('Text/Primary')};
      background: ${color('white')};

      border: ${size('borderWidth')} ${color('Misc/Divider')} solid;
      border-radius: ${size('borderRadius')};

      font-size: ${fontSize()};

      overflow: visible !important;
      cursor: pointer;

      ${props => props.valid && css`
        border-color: ${color('Success/Base')} !important;

        box-shadow: none !important;
      `}

      ${props => props.error && css`
        border-color: ${color('Danger/Base')} !important;

        box-shadow: none !important;
      `}

      ${props => !props.error && css`
        &:hover {
          border-color: ${color('borderHover')} !important;
        }

        &:focus {
          border-color: ${color('Primary/Base')} !important;

          &:hover {
            border-color: ${color('Primary/Base')} !important;
          }
        }
      `}

      ${({ isMulti }) => isMulti && css`
        height: auto !important;
        min-height: ${size('controlHeight')} !important;
      `}

      &--is-focused {
        box-shadow: none;
        border-color: ${color('Primary/Base')} !important;

        &:hover {
          border-color: ${color('Primary/Base')} !important;
        }
      }
    }

    &-input , &-value-label, &-placeholder {
      line-height: calc(${size('controlHeight')} - 2px) !important;
      font-size: ${fontSize()};
      font-weight: ${fontWeight('lighter')};
    }

    &-value {
      padding: 0 0 0 ${size('padding')} !important;
    }

    &-placeholder {
      display: inline;
      padding: 0;
      position: static;
      color: ${color('Text/Tertiary')};
    }

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 0 0 ${size('padding')};
    }

    &.is-open {
      > .Select__indicators {
        border-color: ${color('Primary/Base')};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .Select-menu-outer {
        border-top-color: ${color('Primary/Base')};
      }
    }

    &--is-disabled {
      pointer-events: none;

      .Select__control {
        background: ${color('Background/Base')};
      }
    }
  }
`
