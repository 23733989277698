import styled from 'styled-components'
import { fontSize } from 'ui/themes/utils'

export const FontSelect = styled.div.attrs(({ font }) => ({
  style: {
    fontFamily: font
  }
}))`
  font-size: ${fontSize('mdplus')};
  font-family: sans-serif;
`
