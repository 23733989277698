import {
  ContentBlock,
  Title,
  TitleLink,
  Description,
  Content,
  Footer
} from './styles'

ContentBlock.Title = Title
ContentBlock.TitleLink = TitleLink
ContentBlock.Description = Description
ContentBlock.Content = Content
ContentBlock.Footer = Footer

export default ContentBlock
