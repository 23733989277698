import styled, { css } from 'styled-components'
import { size, color, fontSize, isDark } from 'ui/themes/utils'

import I from 'ui/elements/I'
import P from 'ui/elements/P'
import FocalImage from 'ui/blocks/FocalImage'

export const UrlSelect = styled.div`
  ${I} {
    position: relative;

    top: initial;
    left: initial;
    right: initial;
    bottom: initial;

    margin-right: ${size('margin', 'xs')};

    line-height: 32px;
  }
`

export const Value = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  flex: 1;

  & > .Select__single-value {
    overflow: hidden;
    width: 60%;

    .Select__single-value {
      overflow: hidden;
      width: 80%;
    }

    input {
      width: 100% !important;
    }
  }

  ${({ withIcon }) => withIcon && css`
    & > .Select__single-value {
      padding-left: ${size('padding', 'lg')};
      width: 60%;
    }
  `}

  ${({ isCategory }) => isCategory && css`
    .Select__single-value {
      color: ${color('Text/Tertiary')};
    }
  `}
`

export const Menu = styled.div`
  .Select__menu-list {
    padding: 0;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${size('padding', 'md')};
`

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;

  padding: 4px ${size('padding', 'sm')};

  border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};

  user-select: none;

  cursor: pointer;

  &:hover {
    background-color: ${color('light')}66;
  }

  ${P} {
    margin: 0;

    color: ${color('Text/Primary')};

    &:last-child {
      margin-left: auto;
      opacity: 0.6;
    }
  }
`

export const OptionCell = styled.div`
  display: flex;
  align-items: center;

  padding: ${size('padding', 'xs')} 0;
`

export const Option = styled.div`
  overflow-wrap: break-word;

  cursor: pointer;

  ${({ columns }) => columns && css`
    display: grid;
    grid-template-columns: ${columns.join(' ')};
  `}

  ${FocalImage}, ${I} {
    margin-left: ${size('margin', 'sm')};
  }

  .Select__option {
    background: transparent !important;
  }

  ${({ isFocused }) => isFocused && css`
    background-color: ${color('light')}66;
  `}

  ${({ isSelected }) => isSelected && css`
    background-color: ${color('Primary/Base', 0.4)};

    ${I} {
      ${(props) => {
        const fontColor = isDark(color('Primary/Base')(props)) ? 'Text/Inverted' : 'Text/Primary'

        return {
          color: `${color(fontColor)(props)} !important`
        }
      }}
    }
  `}

  ${({ noPadding }) => noPadding && css`
    ${OptionCell} {
      padding: 0;
    }
  `}

  ${({ isCollection }) => isCollection && css`
    ${OptionCell} ${I} {
      margin: 0 auto;

      font-size: ${fontSize('lg')};
    }

    .Select__option {
      padding-left: 0 !important;
    }
  `}

  ${({ borderBottom }) => borderBottom && css`
    border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};
  `}
`
