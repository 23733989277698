// React
import React from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import { Field } from 'shared/form'

// Shared
import { withTranslation } from 'shared/utils/withTranslation'

const Select = (props) => {
  const {
    identifier,
    options,
    t,
    tReady,
    i18n
  } = props

  const description = tReady &&
    i18n.exists(`signup:signup.fields.${identifier}.description`) &&
    t(`signup.fields.${identifier}.description`)

  return (
    <Field
      type="select"
      name={identifier}
      label={t(`signup.fields.${identifier}.label`)}
      description={description}
      descriptionTop
      options={options}
      searchable={false}
      clearable={false}
      showIfValid
      renderNativeOnMobile={false}
      data-tid={identifier}
      modifiers={{ label: 'line-height-lg height-auto padding-bottom-xs' }}
    />
  )
}

Select.displayName = 'Select'

Select.propTypes = {
  identifier: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tReady: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired
}

export default withTranslation('signup')(Select)
