import pureCompare from 'shared/lib/pure_compare'
import { connect as reduxConnect } from 'react-redux'

export default (mapStateToProps, mapDispatchToProps) => (WrappedComponent) => {
  return reduxConnect(mapStateToProps, mapDispatchToProps, undefined, {
    areStatePropsEqual: (nextStateProps, stateProps) => {
      return pureCompare(nextStateProps, stateProps)
    }
  })(WrappedComponent)
}
