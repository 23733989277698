// React
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Libraries
import _range from 'lodash/range'
import { withTranslation } from 'shared/utils/withTranslation'

// Components
import Icon from 'ui/components/Icon'
import BPaginator from 'ui/blocks/Paginator'
import B from 'ui/elements/B'

// Shared

export class Paginator extends Component {
  static displayName = 'Paginator'

  static propTypes = {
    page: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    per: PropTypes.number,
    padding: PropTypes.number,
    total: PropTypes.number,
    paginatePosition: PropTypes.oneOf(['left', 'right']),
    onPageChange: PropTypes.func,
    modifiers: PropTypes.string,
    Trans: PropTypes.func,
    size: PropTypes.string
  }

  static defaultProps = {
    per: 50,
    padding: 2
  }

  get from () {
    return ((this.props.page - 1) * this.props.per) + 1
  }

  get till () {
    return Math.min(this.from + this.props.per - 1, this.props.total)
  }

  get pages () {
    return Math.ceil(this.props.total / this.props.per)
  }

  get page () {
    return Math.min(this.props.page, this.pages)
  }

  handleClickPage = (page) => {
    this.props.onPageChange(page)
  }

  handleClickFirst = () => {
    this.props.onPageChange(1)
  }

  handleClickLast = () => {
    this.props.onPageChange(this.pages)
  }

  handleClickPrev = () => {
    this.props.onPageChange(this.page - 1)
  }

  handleClickNext = () => {
    this.props.onPageChange(this.page + 1)
  }

  renderPages = () => {
    const pages = this.pages
    const { padding, size } = this.props
    const allPages = _range(1, pages + 1)

    const show = (padding * 2) + 1

    let till = Math.min(pages, this.page + padding)
    const from = Math.max(0, till - show)

    till = from + show

    return allPages.slice(from, till).map((page) => {
      const active = page === this.page

      return (
        <BPaginator.Page
          key={page}
          active={active}
          size={size}
          onClick={active ? undefined : this.handleClickPage.bind(null, page)}
        >
          {page}
        </BPaginator.Page>
      )
    })
  }

  render () {
    if (this.props.total === 0) {
      return null
    }

    const { from, till, page, pages } = this
    const { total, paginatePosition, modifiers, size, Trans } = this.props
    const { PageContainer, Page, Metadata } = BPaginator

    return (
      <BPaginator modifiers={modifiers} paginatePosition={paginatePosition}>
        {paginatePosition === 'right' && <Metadata>
          <Trans
            i18nKey="common.pagination.showing"
            values={{ from, till, total }}
            components={{ Bold: <B /> }}
          />
        </Metadata>
        }
        {pages > 1 &&
        <PageContainer paginatePosition={paginatePosition}>
          {page !== 1 &&
            <Fragment>
              <Page onClick={this.handleClickFirst} size={size}>
                <Icon icon="angle-double-left" />
              </Page>
              <Page onClick={this.handleClickPrev} size={size}>
                <Icon icon="angle-left" />
              </Page>
            </Fragment>
          }
          {this.renderPages()}
          {page !== pages &&
            <Fragment>
              <Page onClick={this.handleClickNext} size={size}>
                <Icon icon="angle-right" />
              </Page>
              <Page onClick={this.handleClickLast} size={size}>
                <Icon icon="angle-double-right" />
              </Page>
            </Fragment>
          }
        </PageContainer>}
        {paginatePosition !== 'right' &&
          <Metadata>
            <Trans
              i18nKey="common.pagination.showing"
              values={{ from, till, total }}
              components={{ Bold: <B /> }}
            />
          </Metadata>
        }
      </BPaginator>
    )
  }
}

export default withTranslation()(Paginator)
