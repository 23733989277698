import styled from 'styled-components'
import { color, size, injectModifiers } from 'ui/themes/utils'
import modifiers from 'ui/themes/modifiers'

const Hr = styled.hr`
  border-color: ${color('Misc/Divider')};
  margin: ${size('margin')} 0;
  border-width: ${({ thick }) => thick ? '2px' : '1px'};

  ${({ theme }) => injectModifiers(modifiers(theme))}
`

export default Hr
