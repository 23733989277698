import styled, { css } from 'styled-components'

export const Disable = styled.div`
  ${props => props.disabled && css`
    opacity: 0.6;
  `}

  ${props => props.disabled && props.strategy === 'hide' && css`
    display: none;
  `}
  
  ${props => props.disabled && css`
    pointer-events: none;
  `}
`
