import React from 'react'

import PropTypes from 'prop-types'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'

const WithRouterComponent = (props) => {
  const params = useParams()
  const location = useLocation()
  const [searchParams, _setSearchParams] = useSearchParams()
  const { component: Component, ...otherProps } = props

  return (
    <Component
      key={location.pathname}
      {...otherProps}
      {...params}
      location={location}
      searchParams={searchParams}
    />
  )
}

WithRouterComponent.propTypes = {
  component: PropTypes.any.isRequired
}

export default (Component, extraProps) => {
  return (componentProps) => {
    return <WithRouterComponent {...componentProps} {...extraProps} component={Component} />
  }
}
