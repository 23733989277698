import styled, { css } from 'styled-components'
import { color, size, fontSize, fontWeight } from 'ui/themes/utils'

const Input = styled.input`
  /* Temoporary css specificity hack (overrides input[type=number] styling from booqable) */
  && {
    display: inline-block;
    vertical-align: middle;

    width: 100%;
    height: ${size('controlHeight')};

    padding: 0 ${size('padding')};
    margin: 0;

    color: ${color('Text/Primary')};
    background: ${color('white')};

    border: ${size('borderWidth')} solid ${color('Misc/Divider')};
    border-radius: ${size('borderRadius', 'sm')};

    outline: 0;

    font-size: ${fontSize()};
    font-weight: ${fontWeight('lighter')};
    line-height: 1;

    box-sizing: border-box;

    ${props => props.visibleOnHover && css`
      border-color: transparent;
      background: transparent;
    `}

    ${props => props.readOnly && css`
      color: ${color('Text/Tertiary')} !important;
      border-color: ${color('Misc/Divider')} !important;
    `}

    ${props => props.error && css`
      border-color: ${color('Danger/Base')};

      &:hover:not(:focus) {
         border-color: ${color('Danger/Base')};
      }

      &:hover {
         border-color: ${color('Danger/Base')};
      }

      &:focus {
        border-color: ${color('Danger/Base')};

        &:hover {
          border-color: ${color('Danger/Base')};
        }
      }
    `}

    ${props => !props.error && css`
      &:hover {
        border-color: ${color('borderHover')};
      }

      &:focus {
        border-color: ${color('Primary/Base')};

        &:hover {
          border-color: ${color('Primary/Base')};
        }
      }

      ${props => props.focused && css`
        border-color: ${color('Primary/Base')} !important;

        &:hover {
          border-color: ${color('Primary/Base')} !important;
        }
      `}
    `}

    ${props => props.disabled && css`
      background: ${color('Background/Base')};

      pointer-events: none;
    `}

    ${props => props.valid && css`
      border-color: ${color('Success/Base')};
    `}

    &::placeholder {
       color: ${color('Text/Tertiary')} !important;
       font-size: ${fontSize()} !important;
    }
  }
`

export default Input
