// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import cuid from 'cuid'
import isString from 'lodash/isString'

// Components
import BInputField from 'ui/blocks/InputField'
import Icon from 'ui/components/Icon'
import Link from 'ui/components/Link'
import Input from 'ui/components/Input'
import QuantityInput from 'ui/components/QuantityInput'
import Select from 'ui/components/Select'
import PriceInput from 'ui/components/PriceInput'
import TextArea from 'ui/components/TextArea'
import DatePickerInput from 'ui/components/DatePickerInput'
import Checkbox from 'ui/components/Checkbox'
import CheckboxGroup from 'ui/components/CheckboxGroup'
import InputDebounceWrapper from 'ui/components/InputDebouncer'
import ColorPicker from 'ui/components/ColorPicker'
import TimeInput from 'ui/components/TimeInput'
import RadioGroup from 'ui/components/RadioGroup'
import TimeSelect from 'ui/components/TimeSelect'
import PhoneInput from 'ui/components/PhoneInput'
import ContentEditor from 'ui/components/ContentEditor'
import UrlSelect from 'ui/components/UrlSelect'
import FontSelect from 'ui/components/FontSelect'
import CodeInput from 'ui/components/CodeInput'
import Duration from 'ui/components/Duration'
import MultiSelectInput from 'ui/components/MultiSelectInput'
import Slider from 'ui/components/Slider'

// Shared
import { Routing as RoutingUtils, stringIsLink } from 'client/v2/utils/utils'
import privateDataProps from 'shared/privateDataProps'
import { withTranslation } from 'shared/utils/withTranslation'

const types = {
  text: Input,
  number: Input,
  password: Input,
  email: Input,
  quantity: QuantityInput,
  select: Select,
  price: PriceInput,
  textarea: TextArea,
  color: ColorPicker,
  checkbox: Checkbox,
  checkboxGroup: CheckboxGroup,
  datepicker: DatePickerInput,
  time: TimeInput,
  buttonGroup: RadioGroup,
  radioGroup: RadioGroup,
  radio: RadioGroup,
  phone: PhoneInput,
  timeSelect: TimeSelect,
  contentEditor: ContentEditor,
  url: UrlSelect,
  fontPicker: FontSelect,
  code: CodeInput,
  search: Input,
  duration: Duration,
  multiInput: MultiSelectInput,
  range: Slider
}

const privateDataTypes = ['text', 'password', 'email']

/**
 * A wrapper for various form input fields
 * Includes error displaying and an optional label
 *
 * @example
 *   <InputField  type="text" field={field} label="Some label" error="Some error" />
 */
class InputField extends Component {
  static displayName = 'InputField'

  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.oneOf([
      'text',
      'textarea',
      'number',
      'password',
      'quantity',
      'select',
      'price',
      'email',
      'color',
      'time',
      'datepicker',
      'checkboxGroup',
      'buttonGroup',
      'radio',
      'radioGroup',
      'checkbox',
      'timeSelect',
      'phone',
      'contentEditor',
      'url',
      'font',
      'code',
      'search',
      'duration',
      'multiInput'
    ]),
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func,
      value: PropTypes.any
    }),
    link: PropTypes.string,
    href: PropTypes.string,
    settingsBackLink: PropTypes.node,
    debounce: PropTypes.number,
    debounceOptions: PropTypes.object,
    form: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    errorOnSubmit: PropTypes.bool,
    hideErrorLabel: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.node
    ]),
    description: PropTypes.node,
    descriptionTop: PropTypes.bool,
    touched: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoSelect: PropTypes.bool,
    fast: PropTypes.bool,
    required: PropTypes.bool,
    forceMargin: PropTypes.bool,
    noMargin: PropTypes.bool,
    showIfValid: PropTypes.bool,
    initialError: PropTypes.bool,
    disabled: PropTypes.bool,
    'data-tid': PropTypes.string,
    hideLabel: PropTypes.bool,
    hidePreviewLink: PropTypes.bool,
    autoComplete: PropTypes.string,
    display: PropTypes.bool,
    t: PropTypes.func,
    modifiers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  }

  static defaultProps = {
    type: 'text'
  }

  constructor (props) {
    super(props)

    this.state = {
      id: props.id || cuid(),
      value: props.field.value
    }
  }

  componentDidUpdate = (prevProps) => {
    const { fast, field } = this.props

    if (field.value === undefined ||
      (!isString(field.value) && (isNaN(field.value)))
    ) return

    if (fast && prevProps.field.value !== field.value) {
      this.handleUpdateValue(field.value)
    }
  }

  handleUpdateValue = (value) => {
    this.setState({ value })
  }

  fieldProps = () => {
    const {
      field,
      fast,
      form,
      error,
      type
    } = this.props

    let { autoSelect } = this.props

    // Always auto select quantity, number and price fields (unless specifically disabled)
    if (autoSelect !== false && ['quantity', 'number', 'price'].indexOf(type) !== -1) {
      autoSelect = true
    }

    let fieldProps = {}

    // If component should not update the whole form on each keystroke
    // only call change on blur or and when the Enter key is pressed.
    if (!error && fast) {
      const { onChange, onBlur, name } = field

      fieldProps.name = name

      // We keep track of the value in state as the form
      // component does not have to know about it yet.
      fieldProps.value = this.state.value

      // Swallow onChange event to only update it's value in
      // the state, except when the form is not dirty yet.
      fieldProps.onChange = (e) => {
        let value

        // TODO: Should we change selects and datepickers to
        // always return the value in `target`?
        if (e && e.target) {
          value = e.target.value
        } else {
          value = e
        }

        this.setState({ value })

        if (!form.dirty) {
          onChange(e)
        }
      }

      // Only trigger an update of the form component
      // when the field is blurred.
      fieldProps.onBlur = (e) => {
        onChange(e)
        onBlur(e)
      }

      // Trigger a change on onkeydown event just before form
      // submit is called, so form gets new values.
      fieldProps.onKeyDown = (e) => {
        if (e.key === 'Enter') {
          onChange(e)
        }
      }
    } else {
      fieldProps = field
    }

    // Auto select value in input when field is focused
    if (autoSelect) {
      const originalOnFocus = fieldProps.onFocus

      fieldProps.onFocus = (e) => {
        originalOnFocus && originalOnFocus()
        e.target.select()
      }
    }

    return fieldProps
  }

  render () {
    const {
      label,
      link,
      href,
      settingsBackLink,
      description,
      descriptionTop,
      error,
      type,
      touched,
      form,
      field,
      hideErrorLabel,
      debounce,
      debounceOptions,
      required,
      forceMargin,
      noMargin,
      showIfValid,
      initialError,
      disabled,
      autoComplete,
      display,
      hidePreviewLink,
      modifiers,
      errorOnSubmit,
      t,
      // eslint-disable-next-line react/prop-types
      tReady,
      ...otherProps
    } = this.props

    const previewLink = !!field?.value && typeof field.value === 'string' && type === 'url' && stringIsLink(field.value)
    const inputId = this.state.id
    const Component = types[type]
    const formDirty = form && form.dirty
    const hasSubmitted = form && form.submitCount > 0
    const displayError = errorOnSubmit
      ? (hasSubmitted && error)
      : (initialError && formDirty && error) || (hasSubmitted && error) || (touched && error)
    const displayValid =
      showIfValid && touched && !error && ((field.value && field.value.length > 0) || typeof field.value === 'boolean')
    const errorIsArray = error && typeof error === 'object'
    const errors = errorIsArray ? error : [error]

    let hideLabel = this.props.hideLabel || false

    // This makes sure the select input is validated.
    if (type === 'select') field.onBlur = () => form && form.setFieldTouched(field.name)

    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    const inputProps = {
      type,
      error: displayError,
      valid: displayValid,
      id: inputId,
      label,
      noMargin,
      ...otherProps,
      field: this.fieldProps(),
      autoComplete: autoComplete || 'off',
      modifiers: nestedModifiers.inputModifiers
    }

    // Checkbox/Datepicker requires different styling
    // let the component handle it
    if (type === 'checkbox') {
      inputProps.description = description
      hideLabel = true
    }

    if (privateDataTypes.includes(type)) {
      for (const [key, value] of Object.entries(privateDataProps)) {
        inputProps[key] = value
      }
    }

    if (displayError) inputProps['data-error'] = error

    if (!Component) {
      const error = new Error(`Component of type ${type} missing for InputField`)

      window.appsignal?.sendError(error)

      return null
    }

    // textarea complains about the `fast` prop
    if (type === 'textarea') delete inputProps.fast

    if (!!field.value && !!inputProps.defaultValue) delete inputProps.defaultValue

    const showDescription = description && !hideLabel

    return (
      <BInputField
        data-tid={this.props['data-tid']}
        data-force-margin={forceMargin}
        data-no-margin={noMargin}
        className={error ? 'error' : null}
        display={display}
        modifiers={nestedModifiers.self}
      >
        {(!hideLabel || (link && href) || settingsBackLink || previewLink) && (
          <BInputField.LabelContainer>
            {label && (
              <BInputField.Label
                modifiers={nestedModifiers.label}
                htmlFor={inputId}
                descriptionTop={descriptionTop}
              >
                {label} {required && '*'}
              </BInputField.Label>
            )}
            {link && href && (
              <BInputField.Link href={href} onClick={RoutingUtils.navigateLink}>{link}</BInputField.Link>
            )}
            {settingsBackLink && settingsBackLink}
            {previewLink && !hidePreviewLink && (
              <Link
                href={field.value}
                icon="external-link"
                iconColor="grey"
                target="_blank"
                title={t('common.resource_actions.preview', { resource: t('common.resources.link_one') })}
                noBlankIcon
              />
            )}
          </BInputField.LabelContainer>
        )}
        {descriptionTop && showDescription && (
          <BInputField.Description
            descriptionTop
            modifiers={nestedModifiers.description}
          >
            {description}
          </BInputField.Description>
        )}
        {debounce > 0 &&
          <InputDebounceWrapper
            component={Component}
            wait={debounce}
            debounceOptions={debounceOptions}
            disabled={disabled}
            {...inputProps}
          />
        }
        {!debounce &&
          <Component disabled={disabled} {...inputProps} />
        }
        {displayError && !hideErrorLabel &&
          <BInputField.Error><Icon icon="exclamation-triangle" style="far" /> {errors?.join(', ')}</BInputField.Error>
        }
        {displayValid &&
          <BInputField.Valid type={type}><Icon icon="check" /></BInputField.Valid>
        }
        {!descriptionTop && showDescription &&
          <BInputField.Description modifiers={nestedModifiers.description}>{description}</BInputField.Description>
        }
      </BInputField>
    )
  }
}

export default withTranslation()(InputField)
