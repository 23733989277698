// React
import React, { Component, cloneElement } from 'react'
import PropTypes from 'prop-types'

// Libraries
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import 'tippy.js/animations/shift-toward.css'

// Components
import PopoverContent from './Content'
import BPopover from 'ui/blocks/Popover'

/*
 * Automatic version of the Popover component. State is defined
 * within the component. Supports hover & click modes.
 *
 * <Popover method="hover" content="I'm a popover!">
 *   <span>Hover me!</span>
 * </Popover>
 */

const AVAILABLE_PLUGINS = {
  followCursor
}

export default class AutomaticPopover extends Component {
  static displayName = 'AutomaticPopover'

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
      PropTypes.string
    ]),
    content: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
      PropTypes.string
    ]),
    isMobile: PropTypes.bool,
    // Offset in pixels
    offset: PropTypes.number,
    direction: PropTypes.oneOf([
      'top',
      'right',
      'bottom',
      'left',
      'top-start',
      'top-end',
      'right-start',
      'right-end',
      'bottom-start',
      'bottom-end',
      'left-start',
      'left-end'
    ]),
    method: PropTypes.oneOf(['click', 'hover']),
    onOpen: PropTypes.func,
    onHide: PropTypes.func,
    fixed: PropTypes.bool,
    delay: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array
    ]),
    animation: PropTypes.string,
    useContentWrapper: PropTypes.bool,
    useArrow: PropTypes.bool,
    fallbackPlacements: PropTypes.array,
    zIndex: PropTypes.number,
    target: PropTypes.string,
    noContentOverflow: PropTypes.bool,
    interactive: PropTypes.bool,
    defaultPopoverStyling: PropTypes.bool,
    unmountOnClose: PropTypes.bool,
    sticky: PropTypes.bool,
    stickyConfig: PropTypes.object,
    innerRef: PropTypes.func,
    withInertia: PropTypes.bool,
    plugins: PropTypes.object
  }

  static defaultProps = {
    method: 'click',
    direction: 'right',
    animation: 'shift-toward',
    offset: 10,
    useArrow: true,
    useContentWrapper: true,
    interactive: true,
    unmountOnClose: true,
    onOpen: () => {},
    onClose: () => {},
    onHide: () => {},
    zIndex: 999999999
  }

  state = {
    opened: false
  }

  get modifiers () {
    const modifiers = []

    const { useArrow, fallbackPlacements } = this.props

    if (useArrow) {
      modifiers.push({
        name: 'arrow',
        options: {
          element: this.arrow
        }
      })
    }

    if (fallbackPlacements) {
      modifiers.push({
        name: 'flip',
        options: {
          fallbackPlacements
        }
      })
    }

    return modifiers
  }

  get plugins () {
    const { plugins } = this.props

    const componentPlugins = []
    const componentProps = {}

    for (const plugin in plugins) {
      if (!['followCursor'].includes(plugin)) return

      componentPlugins.push(AVAILABLE_PLUGINS[plugin])
      componentProps[plugin] = plugins[plugin]
    }

    return {
      plugins: componentPlugins,
      ...componentProps
    }
  }

  onOpen = () => {
    const { onOpen } = this.props

    // Keep track of opened state so child components
    // can fetch data onMount, if we don't do this the child
    // component will always be mounted. Note that this also leads
    // to better performance as child components are only rendered
    // when needed.
    this.setState({ opened: true })

    onOpen && onOpen()
  }

  onHidden = () => {
    const { onHide, unmountOnClose } = this.props

    unmountOnClose && this.setState({ opened: false })

    onHide?.()
  }

  handleCreate = (tippy) => {
    this.tippy = tippy
  }

  render () {
    const {
      children,
      content,
      direction,
      method,
      offset,
      isMobile,
      fixed,
      delay,
      useContentWrapper,
      animation,
      useArrow,
      zIndex,
      target,
      noContentOverflow,
      interactive,
      defaultPopoverStyling,
      sticky,
      stickyConfig,
      innerRef,
      withInertia
    } = this.props

    const trigger = method === 'hover' ? 'mouseenter focus' : method

    const contentProps = {
      handleClose: this.tippy && this.tippy.hide
    }

    const inertiaProps = {
      inertia: true,
      moveTransition: 'transform 250ms cubic-bezier(.860, 0, .070, 1)'
    }

    return (
      <Tippy
        ref={(ref) => innerRef?.(ref)}
        zIndex={zIndex}
        placement={direction}
        interactive={interactive}
        interactiveBorder={10}
        offset={[0, offset]}
        animation={animation}
        onCreate={this.handleCreate}
        onShow={this.onOpen}
        onHidden={this.onHidden}
        appendTo={document.body}
        trigger={trigger}
        delay={delay}
        {...(withInertia ? inertiaProps : {})}
        {...this.plugins}
        popperOptions={{
          strategy: fixed ? 'fixed' : 'absolute',
          modifiers: this.modifiers
        }}
        content={
          this.state.opened && content && (
            useContentWrapper ? (
              <PopoverContent
                content={content}
                contentProps={contentProps}
                useArrow={useArrow}
                target={target}
                isMobile={isMobile}
                noOverflow={noContentOverflow}
                defaultPopoverStyling={defaultPopoverStyling}
              />
            ) : typeof content === 'function' ? content(contentProps) : cloneElement(content, contentProps)
          )
        }
      >
        <BPopover.Wrapper sticky={sticky} stickyConfig={stickyConfig}>
          {children}
        </BPopover.Wrapper>
      </Tippy>
    )
  }
}
