import styled, { keyframes, css } from 'styled-components'
import { color, fontWeight, fontSize, isDark } from 'ui/themes/utils'

import DayPicker from 'react-day-picker'

const pulseOpacity = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`

export const StyledDayPicker = styled(DayPicker)`
  display: inline-block;

  font-family: ${(props) => props.theme.font.body};
  font-size: ${fontSize()};

  outline: none;

  * {
    outline: none;
  }

  .DayPicker-wrapper {
    position: relative;
    user-select: none;
    flex-direction: row;
  }

  .DayPicker-Month {
    display: block;
    float: left;

    border-collapse: collapse;
    border-spacing: 0;
    user-select: none;
    padding: 0 1.2rem;
    margin-bottom: 0.2em;

    &:first-child {
      border-right: 1px solid ${color('Misc/Divider')};
    }

    /* If there's no other months, cancel the border */
    &:last-child {
      border-right: 0;
      content: "";
      clear: both;
      display: table;
    }
  }

  .DayPicker-Caption {
    margin-top: 0.2em;
    padding: 0 0.5rem;
    display: table-caption;
    text-align: center;
  }

  .DayPicker-Caption > div {
    font-size: 16px;
    font-weight: ${fontWeight('semibold')};
  }

  .DayPicker-Weekdays {
    margin-top: 1rem;
    display: table-header-group;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.8rem 0;
    font-size: 13px;
    text-align: center;
    color: ${color('Text/Primary')};
    opacity: 0.6;
    padding-bottom: 1rem;
    text-transform: lowercase;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    position: relative;

    border-radius: 0 !important;

    height: 40px;
    min-width: 40px;

    display: table-cell;

    color: ${color('Text/Primary')};
    padding: 0;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;

    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5rem;
  }

  .DayPicker-TodayButton {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    color: ${color('Primary/Background2')};
    font-size: 0.875em;
  }

  /* Default modifiers */

  .DayPicker-Day:not(.DayPicker-Day--today) {
    font-weight: ${fontWeight('light')};
  }

  .DayPicker-Day--today {
    font-weight: ${fontWeight('bold')};
  }

  .DayPicker-Day--outside {
    color: ${color('Text/Tertiary')};
  }

  .DayPicker-Day--disabled.DayPicker-Day--outside {
    color: ${color('Text/Primary')};
    opacity: 0.1;
  }

  .DayPicker-Day--disabled {
    color: ${color('Text/Primary')};
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
    text-decoration: line-through;
  }

  /*  modifiers */
  .DayPicker-Day--blocked {
    color: ${color('Text/Primary')};
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }

  .DayPicker-Day--focused:not(.DayPicker-Day--from.DayPicker-Day--to):before {
    animation: ${pulseOpacity} 1.5s infinite ease-in-out;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    overflow: hidden;
    z-index: 1;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
    background-color: ${color('Background/Base')} !important;
  }

  .DayPicker-Day:before, .DayPicker-Day:after {
    top: 0;
    left: 0;

    height: 40px;
    width: 40px;
  }

  .DayPicker-Day:before {
    content: '';
    position: absolute;
    z-index: -1;
  }

  .DayPicker-Day:after {
    content: '';
    position: absolute;
    z-index: -2;
  }

  /* Selection logic */
  .DayPicker-Week .DayPicker-Day.DayPicker-Day--selection {
    &:after {
      background-color: ${color('light')} !important;
    }

    /* Selection that is not the start nor the end we use background color here since we don't need to round corners.
    The magic starts on from and to dates */
    &:not(.DayPicker-Day--selectionStart):not(.DayPicker-Day--selectionEnd) {
      background-color: ${color('light')} !important;
    }

    /* Selection start and end are on same day */
    &.DayPicker-Day--selectionEnd.DayPicker-Day--selectionStart:before {
      border-radius: 100% !important;
    }

    /* Selecting new fixed duration ends on from date */
    &.DayPicker-Day--selectionEnd.DayPicker-Day--from:not(.DayPicker-Day--selectionStart):not(.DayPicker-Day--to) {
      background-color: ${color('Background/Base')} !important;
    }

    /* Selecting new fixed duration starts on to date */
    &.DayPicker-Day--selectionStart.DayPicker-Day--to:not(.DayPicker-Day--selectionEnd):not(.DayPicker-Day--from) {
      background-color: ${color('Background/Base')} !important;

      &:after {
        background-color: ${color('light')} !important;
      }
    }

    /* Always round corners at foreground of start selection */
    &.DayPicker-Day--selectionStart:before, &.DayPicker-Day--selectionStart:after {
      border-top-left-radius: 100% !important;
      border-bottom-left-radius: 100% !important;
    }

    /* New selecting start is at current period end */
    &.DayPicker-Day--selectionStart.DayPicker-Day--to:after {
      background-color: ${color('light')} !important;
    }

    /* Alays round corners of foreground elements at every end of selection */
    &.DayPicker-Day--selectionEnd {
      &:after, &:before {
        border-top-right-radius: 100% !important;
        border-bottom-right-radius: 100% !important;
      }
    }

    /* Selection start is on to date with a previsouly selected from before the to date */
    &.DayPicker-Day--selectionStartWithPeviousPeriod {
      background: ${color('Background/Base')} !important;
    }
  }

  /* Selection that neither start nor end, we can use regular background */
  .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to) {
    background-color: ${color('Background/Base')} !important;
    color: ${color('Text/Primary')};
  }

  /* Current selection from */
  .DayPicker-Week .DayPicker-Day.DayPicker-Day--from {
    color: ${(props) =>
      isDark(color('Primary/Base')(props)) ? color('Text/Inverted')(props) : color('Text/Primary')(props)
    } !important;
    background-color: transparent;

    &::before {
      border-top-left-radius: 100% !important;
      border-bottom-left-radius: 100% !important;

      background-color: ${color('Primary/Base')} !important;
    }

    /* This make sure the from day is round when no selection yet, also works for single date pickers */
    ${props => !props.to && css`
      &::before {
        border-radius: 100% !important;
      }
    `}
  }

  /* Current selection to */
  .DayPicker-Week .DayPicker-Day.DayPicker-Day--to {
    background-color: transparent;
    color: ${(props) =>
      isDark(color('Primary/Base')(props)) ? color('Text/Inverted')(props) : color('Text/Primary')(props)
    } !important;

    &::before {
      border-top-right-radius: 100% !important;
      border-bottom-right-radius: 100% !important;

      background-color: ${color('Primary/Base')} !important;
    }
  }

  /* This is a default style override, we don't want the off theme light blue background on hover */
  &:not(.DayPicker--interactionDisabled) .DayPicker-Week
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: transparent;
  }

  /* New selection hover element, make it bold and give it the selection background */
  &:not(.DayPicker--interactionDisabled) .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled):hover {
    background-color: transparent;
    font-weight: ${fontWeight('bold')};

    &:not(.DayPicker-Day--from):not(.DayPicker-Day--to):before {
      background-color: ${color('light')};
      border-radius: 100% !important;
    }
  }
`
