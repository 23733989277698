// React
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import BFontSelect from 'ui/blocks/FontSelect'

// Libraries
import { components } from 'react-select'

const BASE_URL = 'https://fonts.bunny.net/css?family='

const SingleValue = (props) => (
  <Fragment>
    <link href={BASE_URL + props.data.value} rel="stylesheet" />
    <BFontSelect font={props.data.label}>
      <components.SingleValue {...props}>
        {props.children}
      </components.SingleValue>
    </BFontSelect>
  </Fragment>
)

SingleValue.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node
}

export default SingleValue
