import Cookies from 'js-cookie'
import axios from 'signup/lib/axios'
import store from '../store'

const SignupActions = {
  setInitialValues: (values) => (
    store.dispatch({
      type: 'SIGNUP_SET_INITIAL_VALUES_SUCCESS',
      payload: values
    })
  ),

  fetch: (id) => {
    return axios.get(`/signups/${id}`).then((response) => {
      return store.dispatch({
        type: 'SIGNUP_FETCH_COMPLETE',
        payload: response.data
      })
    })
  },

  create: (values) => {
    window.created = true
    values.identifier = window.id

    return axios.post('/signups', { signup: values }).then((response) => {
      return store.dispatch({
        type: 'SIGNUP_CREATE_SUCCESS',
        payload: values
      })
    })
  },

  autoSave: (id, values) => {
    return axios.patch(`/signups/${id}`, { signup: values })
  },

  update: (id, values) => {
    return axios.patch(`/signups/${id}`, { signup: values }).then((response) => {
      return store.dispatch({
        type: 'SIGNUP_UPDATE_SUCCESS',
        payload: response.data
      })
    })
  },

  checkCompanyAvailability: (companyName) => {
    return axios.post('/signups/company_available', { company_name: companyName }).then((response) => {
      return store.dispatch({
        type: 'SIGNUP_FETCH_COMPANY_AVAILABILITY_SUCCESS',
        payload: {
          company_available: response.data.available
        }
      })
    })
  },

  complete: (id, values) => {
    return axios.put(`/${window.locale}/signups/${id}/complete`, { signup: values }).then((response) => {
      return store.dispatch({
        type: 'SIGNUP_COMPLETE_SUCCESS',
        payload: values
      })
    })
  },

  isReady: (id) => {
    return axios.get(`/signups/${id}/ready`).then((response) => {
      return store.dispatch({
        type: 'SIGNUP_COMPLETE_SUCCESS',
        payload: response.data
      })
    })
  },

  identify: () => {
    return axios.get(`${window.booqableProtocol}://identify.${window.booqableUrl}/location.json`).then((response) => {
      Cookies.set('identification.location', response.data.country_code, { expires: 1 })

      return store.dispatch({
        type: 'SIGNUP_FETCH_IS_EU_SUCCESS',
        payload: {
          is_eu: response.data.is_eu
        }
      })
    })
  }
}

export default SignupActions
