import styled, { css } from 'styled-components'

export const FloatingColumn = styled.div`
  position: absolute;

  right: 0;

  ${({ top }) => top && css`
    top: ${top}px;
  `}

  display: grid;
  grid-template-columns: ${({ width }) => width}px;

  ${({ align, width }) => align && align === 'right' && css`
    grid-template-columns: ${width - 15}px;
  `}

  width: ${({ width }) => width}px;

  padding-left: 15px;

  overflow: hidden;

  z-index: 1;

  &::before {
    content: '';
    position: absolute;

    left: -5px;

    height: 100%;
    width: 5px;

    box-shadow: rgba(0, 0, 0, 0.2) 15px 0 6px 0px;

    z-index: -1;
  }

  ${({ show }) => !show && css`
    visibility: hidden;
  `}
`
