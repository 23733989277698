// React
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'

// Components
import BDateTimeInput from 'ui/blocks/DateTimeInput'

// Libraries
import cuid from 'cuid'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

// Shared
import moment from 'shared/lib/moment'
import humanizedDateFormat from 'shared/utils/dateFormat'
import isMobile from 'shared/utils/isMobile'
import { withTranslation } from 'shared/utils/withTranslation'

class DateTimeInput extends Component {
  static displayName = 'DateTimeInput'

  constructor (props) {
    super(props)

    this.state = {
      errors: {},
      focused: false,
      isValid: null,
      isDirty: false
    }

    this.splitFormat = this.props.format.split(/[- / :]/gm)
  }

  static defaultProps = {
    field: {}
  }

  static propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    onClick: PropTypes.func,
    /** Called when input is filled out */
    next: PropTypes.func,
    /** Datetime format ex: DD-MM-YYYY HH:mm */
    format: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    inputRef: PropTypes.func,
    onBlur: PropTypes.func,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
      ]),
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
      onInvalid: PropTypes.func,
      onKeyUp: PropTypes.func,
      /** Custom validation function should return invalid key or true
       * ex. validate = (date) => 'HH'
       */
      validate: PropTypes.func
    }),
    t: PropTypes.func
  }

  componentDidMount = () => {
    this.setStateFromProps()

    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.format !== this.props.format) {
      this.splitFormat = this.props.format.split(/[- :]/gm)

      return this.setStateFromProps()
    }

    if (prevProps.field.value !== this.props.field.value) {
      this.setStateFromProps()
    }
  }

  // Utilties
  /**
   * Maximum length of input by unit.
   *
   * @param {String} unit
   *
   * @return {Number}
   */
  valueLengthForUnit = (unit) => {
    // We only have to make an expection for AM/PM
    if (unit === 'A') {
      return 2
    } else {
      return unit.length
    }
  }

  /**
   * Is A in this.props.format
   *
   * @return {Boolean}
   */
  AMPMInFormat = () => {
    return !!this.props.format.match(/A/gm)
  }

  // Value flow
  /**
   * Format value with moment and then split it in parts
   */
  setStateFromProps = () => {
    // We got a (new) value from outside, assume it's valid
    const { field } = this.props
    const newState = { isValid: true, errors: {} }

    // Use now if there's no value
    const date = field.value ? moment(field.value) : moment()

    if (!date.isValid()) return

    this.splitFormat.forEach((key) => {
      newState[key] = date.format(key)
    })

    this.setState(newState)
  }

  /**
   * Handle change and prevent invalid input
   */
  handleChange = (unit, e) => {
    let value = e.target.value

    // Don't do anything if value is greater then the unit length
    if (value.length > this.valueLengthForUnit(unit)) {
      return true
    }

    // Don't allow non-numeral characters in date fields
    if (unit !== 'A' && !value?.match(/^[0-9]*$/)) {
      return true
    }

    // Don't let anything other than AM or PM in the A field
    if (unit === 'A' && !value?.match(/^[ap]m?$/i)) {
      return true
    }

    // Complete AM/PM after first character
    if (unit === 'A' && value?.match(/^[ap]$/i)) {
      value = value.toUpperCase() + 'M'
    }

    // Update the new state of the unit and validate fields when unit is the same as
    // the last one provided in format prop
    this.setState({ [unit]: value, isDirty: true }, () => {
      // Instead of validating field on each unit fill, we will do this
      // only on last one to prevent validation issues in some cases
      if (unit === this.splitFormat[this.splitFormat.length - 1]) {
        this.validateAndUpdateValue()
      }
    })

    // Skip to next input if possible
    if (value.length === this.valueLengthForUnit(unit)) {
      const index = this.splitFormat.indexOf(unit)
      const nextInput = this[index + 1 + '_input']

      if (nextInput) {
        window.setTimeout(() => {
          nextInput.focus()
        }, 0)
      } else {
        this.props.next?.()
      }
    }
  }

  /**
   * Validates date and calls onChange if valid
   * @param {Bool} sendValid - Trigger onChange when value already valid
   */
  validateAndUpdateValue = () => {
    // See: https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/15-is-valid/
    const key = [
      'YYYY',
      'MM',
      'DD',
      this.AMPMInFormat() ? 'hh' : 'HH',
      'mm'
    ]
    const { field, format } = this.props

    let value = this.props.format

    this.splitFormat.forEach((unit) => {
      value = value.replace(unit, this.state[unit])
    })

    const datetime = moment(value, format, true)
    const valid = datetime.isValid()

    // Should be true or a key
    let customValidation = true

    if (typeof field.validate === 'function') {
      customValidation = field.validate(datetime)
    }

    // All fields valid, send value to onChange
    if (valid && customValidation === true) {
      this.setState({ isValid: true, isDirty: false, errors: {} })
      field.onChange?.(datetime.toJSON())

      return
    }

    let invalidKey

    if (typeof customValidation === 'string') {
      invalidKey = customValidation
    } else {
      invalidKey = key[datetime.invalidAt()]
    }

    const state = { isValid: false }

    if (invalidKey) {
      state.errors = { [invalidKey]: 'invalid' }
    }

    this.setState(state)
    field.onInvalid?.()
  }

  // Presentation flow
  /**
   * Set focus when container is clicked
   */
  setFocus = (e) => {
    if (e.target.nodeName !== 'INPUT') {
      this.focus(e)
    }

    this.setState({ focused: true })

    this.props.onClick && this.props.onClick()
  }

  /**
   * Focus first input
   */
  focus = (e) => {
    const { field } = this.props

    field.onFocus?.(e)

    setTimeout(() => {
      const input = this['0_input']

      input && input.focus()
    }, 100)
  }

  /**
   *  Focus unit input
   */
  handleFocus = (unit, e) => {
    const { field } = this.props

    this.selectUnit(unit, e)
    field.onFocus && field.onFocus(e)
  }

  handleMouseUp = (unit, e) => {
    this.selectUnit(unit, e)
  }

  selectUnit = (unit, e) => {
    const index = this.splitFormat.indexOf(unit)
    const input = this[index + '_input']

    input && input.select()
  }

  /**
   * Blur component when there's a click outside.
   * (Only relevant when there's no value)
   */
  handleClickOutside = (e) => {
    const domNode = findDOMNode(this)

    if (this.state.focused && (!domNode || !domNode.contains(e.target))) {
      this.setState({ focused: false })
    }
  }

  // Input quality of life
  /**
   * Allow for deleting AM/PM (handleChange would otherwise prevent this).
   *
   * Keyboard navigation from input to input.
   */
  handleKeyUp = (unit, e) => {
    const { field } = this.props

    // Make sure it's easy to clear AM/PM
    if (unit === 'A' && e.key === 'Backspace') {
      const fakeEvent = {
        target: {
          value: ''
        }
      }

      this.handleChange('A', fakeEvent)
    }

    // Allow going from input to input using keyboard
    if (e.key === 'ArrowRight') {
      if (e.target.selectionStart === this.valueLengthForUnit(unit)) {
        const index = this.splitFormat.indexOf(unit)
        const input = this[index + 1 + '_input']

        input?.focus()
      }
    }

    if (e.key === 'ArrowLeft') {
      const index = this.splitFormat.indexOf(unit)
      const input = this[index - 1 + '_input']

      input?.focus()
    }

    field.onKeyUp?.(e)
  }

  /**
   * Adds leading zero
   */
  handleBlur = (unit, e) => {
    const { onBlur, field } = this.props

    // Put a zero before the value so we can still submit a complete date
    if (unit !== 'YY' && e.target.value.length < unit.length) {
      this.handleChange(unit, {
        target: {
          value: `0${e.target.value}`
        }
      })
    }

    // We validate on blur and update if necessary
    if (this.state.isDirty) {
      onBlur?.({
        target: {
          value: e.target.value,
          name: field.name
        }
      })

      this.validateAndUpdateValue()
    } else if (unit === this.splitFormat[this.splitFormat.length - 1]) {
      // The last unit
      onBlur?.({
        target: {
          value: e.target.value,
          name: field.name
        }
      })
    }
  }

  render () {
    const { field, format, placeholder, inputRef, disabled, size } = this.props
    const { focused, errors } = this.state
    const deviceIsMobile = isMobile()
    const otherProps = omit(this.props, [
      'value',
      'format',
      'placeholder',
      'inputRef',
      'onKeyUp',
      'onChange',
      'onClick',
      'onBlur'
    ])

    return (
      <BDateTimeInput
        size={size}
        ampm={this.AMPMInFormat()}
        ref={inputRef}
        onClick={this.setFocus}
        onFocus={this.setFocus}
        disabled={disabled}
        {...otherProps}
      >
        {field.value instanceof Date || !isEmpty(field.value) || focused
          ? format.match(/(\w+|[- / :])/gm).map((key) => {
            if (key.match(/\w/)) {
              // It's a unit
              const index = this.splitFormat.indexOf(key)

              return <BDateTimeInput.Input
                size={size}
                key={key}
                ref={(input) => { this[index + '_input'] = input }}
                type="text"
                name={key}
                value={this.state[key] || ''}
                invalid={errors[key]}
                placeholder={key}
                className={key}
                onChange={this.handleChange.bind(null, key)}
                onFocus={this.handleFocus.bind(null, key)}
                onMouseUp={this.handleMouseUp.bind(null, key)}
                onKeyUp={this.handleKeyUp.bind(null, key)}
                onBlur={this.handleBlur.bind(null, key)}
                onTouchEnd={this.handleFocus.bind(null, key)}
                disabled={deviceIsMobile || disabled}
                autoComplete="off"
              />
            } else {
              return <BDateTimeInput.Seperator
                size={size}
                lighter={key === ':'}
                key={cuid()}
              >
                {key}
              </BDateTimeInput.Seperator>
            }
          })
          : (
            <BDateTimeInput.Input
              size={size}
              onFocus={this.focus}
              placeholder={humanizedDateFormat(this.props.t, placeholder)}
              name={field.name || 'datetimeinput_placeholder'}
            />
            )
        }
      </BDateTimeInput>
    )
  }
}

export default withTranslation()(DateTimeInput)
