// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Automatic from './Automatic'
import Controlled from './Controlled'

const Popover = ({ method, ...otherProps }) => {
  const isManual = method === 'manual'

  return isManual ? (
    <Controlled {...otherProps} />
  ) : (
    <Automatic method={method} {...otherProps} />
  )
}

Popover.propTypes = {
  method: PropTypes.string
}

export default Popover
