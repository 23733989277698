// React
import React, { Component, Fragment } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

// Libraries
import { withTranslation } from 'react-i18next'

// Components
import BCleanstate from 'ui/blocks/Cleanstate'
import ContentBlock from 'ui/components/ContentBlock'
import Icon from 'ui/components/Icon'
import Spacer from 'ui/blocks/Spacer'
import Loader from 'ui/components/Loader'
import CurvedArrow from 'shared/lib/CurvedArrow'
import H1 from 'ui/elements/H1'
import H2 from 'ui/elements/H2'
import Handwriting from 'ui/blocks/Handwriting'
import HelpCenterLink from 'client/components/HelpCenterLink'

// Shared
import { color } from 'ui/themes/app'

class Cleanstate extends Component {
  static displayName = 'Cleanstate'

  static propTypes = {
    icon: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.func
    ]),
    loading: PropTypes.bool,
    iconStyle: PropTypes.string,
    arrow: PropTypes.object,
    title: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.func
    ]),
    description: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.func
    ]),
    link: PropTypes.string,
    modifiers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    noMinHeight: PropTypes.bool,
    handwriting: PropTypes.object,
    hideIfFoundSelector: PropTypes.string
  }

  componentWillUnmount () {
    if (this.timer) clearTimeout(this.timer)
  }

  render () {
    const {
      icon,
      loading,
      children,
      iconStyle,
      arrow,
      title,
      description,
      link,
      modifiers,
      noMinHeight,
      handwriting,
      hideIfFoundSelector,
      t
    } = this.props

    let showHandwriting = handwriting

    if (this.timer) clearTimeout(this.timer)

    if (handwriting && handwriting.hideIfFoundSelector) {
      // Same setTimeout interval as CurvedArrow, to hide arrow and handwriting when needed
      this.timer = setTimeout(() => {
        this.forceUpdate()
      }, 200)

      if (document.querySelector(handwriting.hideIfFoundSelector)) {
        showHandwriting = false
      }
    }

    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    return (
      <Fragment>
        {arrow && createPortal(
          <CurvedArrow
            color={color.black}
            width={2}
            zIndex={29}
            dynamicUpdate
            hideIfFoundSelector={hideIfFoundSelector}
            {...arrow}
          />,
          document.body
        )}
        <BCleanstate modifiers={nestedModifiers.self} noMinHeight={noMinHeight}>
          <ContentBlock noMax noBackground>
            {!loading && (
              <BCleanstate.BigIcon>
                <Icon icon={icon} style={iconStyle || 'far'} />
              </BCleanstate.BigIcon>
            )}
            {loading && (
              <Loader size="xxl" color="primary" />
            )}
            <Spacer size="md" />
            {title && <H1 modifiers={nestedModifiers.title}>{title}</H1>}
            {description && <H2 modifiers={nestedModifiers.description}>{description}</H2>}
            {showHandwriting && <Handwriting
              id="handwriting"
              top={handwriting.top}
              left={handwriting.left}
              right={handwriting.right}
              modifiers={'font-size-xl'}
            >
              {handwriting.text}
            </Handwriting>}
            {link && <Fragment>
              <Spacer />
              <HelpCenterLink href={link} modifiers={'font-size-mdplus'}>{t('common.learn_more')}</HelpCenterLink>
            </Fragment>}
            {children}
          </ContentBlock>
        </BCleanstate>
      </Fragment>
    )
  }
}

export default withTranslation()(Cleanstate)
