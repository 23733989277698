// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import BUrlSelect from 'ui/blocks/UrlSelect'
import Icon from 'ui/components/Icon'

// Libraries
import { components } from 'react-select'

// Shared
import { stringIsLink } from 'client/v2/utils/utils'
import { getIconNameForUrl } from 'ui/components/UrlSelect/utils'

const icons = {
  'external-link': 'external-link',
  email: 'envelope',
  phone: 'phone',
  products: 'box',
  pages: 'file',
  collections: 'box'
}

const Menu = (props) => {
  const isLink = stringIsLink(props.children)
  const iconName = icons[isLink ? getIconNameForUrl(props.children) : props.children.toLowerCase()]

  return (
    <BUrlSelect.Option
      {...props.innerProps}
      isSelected={props.isSelected}
      isFocused={props.isFocused}
      columns={isLink ? ['30px', 'calc(100% - 30px)'] : ['30px', '1fr', '30px']}
      onMouseEnter={isLink && props.onEnterOption}
      onMouseLeave={isLink && props.onLeaveOption}
      noPadding
    >
      <BUrlSelect.OptionCell>
        <Icon icon={iconName} />
      </BUrlSelect.OptionCell>
      <BUrlSelect.OptionCell>
        <components.Option {...props} />
      </BUrlSelect.OptionCell>
      {!props.data?.type && (
        <BUrlSelect.OptionCell>
          <Icon icon="chevron-right" />
        </BUrlSelect.OptionCell>
      )}
    </BUrlSelect.Option>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  onEnterOption: PropTypes.func,
  onLeaveOption: PropTypes.func,
  data: PropTypes.shape({
    type: PropTypes.string
  }),
  // react-select internal props
  innerProps: PropTypes.object
}

export default Menu
