import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from 'ui/components/Icon'
import DayPicker from 'ui/blocks/DayPicker'

export default class DayPickerNavigation extends Component {
  static displayName = 'DayPickerNavigation'
  static propTypes = {
    className: PropTypes.string,
    onPreviousClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    showNextButton: PropTypes.bool,
    showPreviousButton: PropTypes.bool
  }

  handleClickNext = () => {
    this.props.onNextClick()
  }

  handleClickPrevious = () => {
    this.props.onPreviousClick()
  }

  render () {
    const { showNextButton, showPreviousButton, className } = this.props

    return (
      <div
        className={className}
      >
        {showPreviousButton &&
        <DayPicker.NavigationPrevious tabIndex="0" role="button" onClick={this.handleClickPrevious}>
          <Icon icon="chevron-left" svg />
        </DayPicker.NavigationPrevious>}
        {showNextButton &&
        <DayPicker.NavigationNext tabIndex="0" role="button" onClick={this.handleClickNext}>
          <Icon icon="chevron-right" svg />
        </DayPicker.NavigationNext>}
      </div>
    )
  }
}
