import styled, { css } from 'styled-components'
import { shiftColor, size, fontSize, opacity, color as colorUtil, isValidHexColor } from 'ui/themes/utils'

import Icon from 'ui/blocks/Icon'
import Div from 'ui/elements/Div'

export const colorCss = (color) => (
  typeof color === 'object'
    ? css`
      color: ${color.color};
      background: ${color.background || `${color.color}33`};
      border: ${size('borderWidth')} solid ${color.border || color.color};
    `
    : css`
      color: ${shiftColor(color, 20)};
      background: ${shiftColor(color, -85)};
      border: ${size('borderWidth')} solid ${shiftColor(color, -20)};
    `
)

export const Badge = styled(Div)`
  display: inline-block;
  font-size: ${fontSize()};
  padding: 0 ${size('padding')};
  height: ${size('controlHeight')};
  border-radius: ${size('borderRadius')};
  line-height: calc(${size('controlHeight')} - 2px);
  margin: 0 ${size('margin')} ${size('margin')} 0;
  cursor: default;
  white-space: nowrap;
  position: relative;

  ${props => props.multiLine && css`
    white-space: inherit;
    height: auto;
  `}

  ${Icon} {
    line-height: 0;

    &:last-child {
      margin-left: 3px;
      display: inline-block;
    }

    &:first-child {
      margin-left: 0;
      margin-right: 3px;
    }
  }

  ${props => props.iconOnly && css`
    height: auto;
    padding: 6px 7px 3px;
    margin: 0;

    ${Icon} {
      margin: 3px !important;
      font-size: ${fontSize('mdplus')};
    }
  `}

  ${props => props.borderRadius && css`
    border-radius: ${size('borderRadius', props.borderRadius)};
  `}

  ${props => props.onClick && css`
    &:hover {
      cursor: pointer;
      opacity: ${opacity('destroying')};
    }
  `}

  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: ${opacity('disabled')};
  `}

  ${({ theme, color }) => {
    return isValidHexColor(color) || typeof color === 'object' ? colorCss(color) : colorCss(theme.color[color])
  }}

  ${props => props.flatColor && css`
    background: ${colorUtil(props.flatColor)};
    border-color: ${colorUtil(props.flatColor)};
  `}

  ${props => props.white && css`
    color: ${colorUtil('black')};
    background: ${colorUtil('white')};
    border-color: ${colorUtil('white')};;
  `}

  ${props => props.noBorder && css`
    border: none;
  `}
`
