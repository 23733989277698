// React
import React, { Component, cloneElement } from 'react'
import PropTypes from 'prop-types'

// Libraries
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import 'tippy.js/animations/shift-toward.css'

// Components
import PopoverContent from './Content'
import BPopover from 'ui/blocks/Popover'

/*
 * Controlled version of the Popover component. State is defined
 * from the outside by the "open" prop. Additionally, event listeners
 * are exposed to implement more behavior.
 *
 * <Popover open={open} content="I'm a popover!">
 *   <span onClick={() setState({ open: true })}>Click me!</span>
 * </Popover>
 */

const AVAILABLE_PLUGINS = {
  followCursor
}

export default class ControlledPopover extends Component {
  static displayName = 'ControlledPopover'

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.func
    ]),
    content: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.func
    ]),
    isMobile: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    forceOpen: PropTypes.bool,
    color: PropTypes.oneOf([
      'primary'
    ]),
    // Offset in pixels
    offset: PropTypes.number,
    direction: PropTypes.oneOf([
      'top',
      'right',
      'bottom',
      'left',
      'top-start',
      'top-end',
      'right-start',
      'right-end',
      'bottom-start',
      'bottom-end',
      'left-start',
      'left-end'
    ]),
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onHide: PropTypes.func,
    fixed: PropTypes.bool,
    animation: PropTypes.string,
    useContentWrapper: PropTypes.bool,
    useArrow: PropTypes.bool,
    fallbackPlacements: PropTypes.array,
    zIndex: PropTypes.number,
    target: PropTypes.string,
    noContentOverflow: PropTypes.bool,
    interactive: PropTypes.bool,
    defaultPopoverStyling: PropTypes.bool,
    sticky: PropTypes.bool,
    stickyConfig: PropTypes.object,
    innerRef: PropTypes.func,
    withInertia: PropTypes.bool,
    plugins: PropTypes.object
  }

  static defaultProps = {
    method: 'click',
    direction: 'right',
    animation: 'shift-toward',
    offset: 10,
    useArrow: true,
    useContentWrapper: true,
    interactive: true,
    open: false,
    onOpen: () => {},
    onClose: () => {},
    onHide: () => {},
    zIndex: 999999999
  }

  state = {
    opened: false
  }

  get modifiers () {
    const modifiers = []

    const { useArrow, fallbackPlacements } = this.props

    if (useArrow) {
      modifiers.push({
        name: 'arrow',
        options: {
          element: this.arrow
        }
      })
    }

    if (fallbackPlacements) {
      modifiers.push({
        name: 'flip',
        options: {
          fallbackPlacements
        }
      })
    }

    return modifiers
  }

  get plugins () {
    const { plugins } = this.props

    const componentPlugins = []
    const componentProps = {}

    for (const plugin in plugins) {
      if (!['followCursor'].includes(plugin)) return

      componentPlugins.push(AVAILABLE_PLUGINS[plugin])
      componentProps[plugin] = plugins[plugin]
    }

    return {
      plugins: componentPlugins,
      ...componentProps
    }
  }

  componentDidUpdate = (prevProps) => {
    if ((prevProps.open !== this.props.open && this.props.open)) {
      if (!this.state.opened) {
        this.setState({ opened: true })
      }
    }
  }

  render () {
    const {
      children,
      content,
      direction,
      offset,
      onOpen,
      onClose,
      onHide,
      isMobile,
      fixed,
      useContentWrapper,
      animation,
      useArrow,
      zIndex,
      target,
      noContentOverflow,
      sticky,
      stickyConfig,
      interactive,
      defaultPopoverStyling,
      innerRef,
      withInertia,
      forceOpen,
      color
    } = this.props

    const contentProps = {
      handleClose: onClose
    }

    const inertiaProps = {
      inertia: true,
      moveTransition: 'transform 250ms cubic-bezier(.860, 0, .070, 1)'
    }

    return (
      <Tippy
        ref={(ref) => innerRef?.(ref)}
        zIndex={zIndex}
        placement={direction}
        interactive={interactive}
        interactiveBorder={10}
        offset={[0, offset]}
        animation={animation}
        onShow={onOpen}
        onHidden={onHide}
        appendTo={document.body}
        visible={this.props.open}
        onClickOutside={onClose}
        {...(withInertia ? inertiaProps : {})}
        {...this.plugins}
        popperOptions={{
          strategy: fixed ? 'fixed' : 'absolute',
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                tether: false
              }
            },
            ...this.modifiers
          ]
        }}
        content={
          (this.state.opened || forceOpen) && content && (
            useContentWrapper ? (
              <PopoverContent
                content={content}
                contentProps={contentProps}
                useArrow={useArrow}
                target={target}
                isMobile={isMobile}
                noOverflow={noContentOverflow}
                color={color}
                defaultPopoverStyling={defaultPopoverStyling}
              />
            ) : typeof content === 'function' ? content(contentProps) : cloneElement(content, contentProps)
          )
        }
      >
        <BPopover.Wrapper sticky={sticky} stickyConfig={stickyConfig}>
          {children}
        </BPopover.Wrapper>
      </Tippy>
    )
  }
}
