// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Icon from 'ui/components/Icon'
import BUrlSelect from 'ui/blocks/UrlSelect'

// Libraries
import { components } from 'react-select'

// Shared
import { getIconNameForUrl } from 'ui/components/UrlSelect/utils'

const categoryIcons = {
  products: 'box',
  pages: 'file',
  collections: 'box'
}

const linkIcons = {
  'external-link': 'external-link',
  phone: 'phone',
  email: 'envelope'
}

const ValueContainer = (props) => {
  const isLink = props.selectProps?.type === 'link'
  const linkIconName = getIconNameForUrl(props.selectProps?.value?.value)

  const isCategory = props.selectProps?.category === props.selectProps?.value?.value
  const category =
    props.selectProps?.category || props.selectProps?.value?.value?.match(/booqable:\/\/(products)\//)?.[1]

  return (
    <BUrlSelect.Value
      {...props.innerProps}
      isLink={isLink}
      isCategory={isCategory}
      withIcon={isCategory || isLink || !!category}
    >
      {!props.hideLinkIcon && isLink && <Icon icon={linkIcons[linkIconName]} />}
      {category && !isLink && <Icon icon={categoryIcons[category]} />}
      <components.SingleValue {...props} />
    </BUrlSelect.Value>
  )
}

ValueContainer.displayName = 'UrlContainer'

ValueContainer.propTypes = {
  selectProps: PropTypes.shape({
    type: PropTypes.oneOf(['link']),
    category: PropTypes.string,
    value: PropTypes.shape({
      value: PropTypes.string
    })
  }),
  hideLinkIcon: PropTypes.bool,
  // This is a prop that react-select passes down to its components
  innerProps: PropTypes.object
}

export default ValueContainer
