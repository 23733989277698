// React
import React, { Component } from 'react'

// Components
import TextArea from 'ui/components/TextArea'

export default class CodeInput extends Component {
  static displayName = 'CodeInput'

  render () {
    return (
      <TextArea {...this.props} monospace />
    )
  }
}
