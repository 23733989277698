import styled, { css } from 'styled-components'
import { color, size, fontSize } from 'ui/themes/utils'

import Icon from 'ui/blocks/Icon'

export const Container = styled.div`
  position: relative;

  ${({ isMobile }) => isMobile && css`
    ${Icon} {
      position: absolute;
      pointer-events: none;

      top: 0;
      right: ${size('padding', 'xs')};
      color: ${color('Text/Tertiary')};

      line-height: ${size('controlHeight')};
    }
  `}
`

export const Select = styled.select`
  height: ${size('controlHeight')};
  padding: 0 ${size('padding')};

  font-family: ${(props) => props.theme.font.body};
  font-size: ${fontSize()};

  color: ${color('Text/Primary')};
  background: ${color('white')};

  border: ${size('borderWidth')} ${color('Misc/Divider')} solid;
  border-radius: ${size('borderRadius')};

  &:focus {
    border-color: ${color('Primary/Base')};
  }

  ${({ error }) => error && css`
    border-color: ${color('Danger/Base')};
  `}

  ${({ disabled }) => disabled && css`
    background: ${color('Background/Base')};
    pointer-events: none;
  `}

  ${({ isMulti }) => isMulti && css`
    height: auto;
    min-height: ${size('controlHeight')};
  `}

  outline: none;
  appearance: none;
  display: block;
  width: 100%;
`
