import styled, { css } from 'styled-components'
import { color, size, fontWeight, fontSize } from 'ui/themes/utils'

import BInputField from 'ui/blocks/InputField'
import FloatingColumn from './FloatingColumn'

const justifyAlignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
}

export const Cell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${size('padding', 'sm')};
  background: ${color('white')};
  justify-content: ${props => justifyAlignment[props.align]};
  color: ${color('Text/Primary')};

  &:hover {
    color: ${color('Text/Primary')};
  }

  ${({ border }) => border === 'left' && css`
    border-left: 1px solid ${color('Misc/Divider')};
  `}

  ${({ border }) => border === 'right' && css`
    border-right: 1px solid ${color('Misc/Divider')};
  `}

  ${({ colSpan }) => colSpan && css`
    grid-column: span ${colSpan};
  `}

  ${({ expandedCell }) => expandedCell && css`
    display: block;
  `}

  ${props => props.minWidth && `min-width: ${props.minWidth};`}

  > & {
    white-space: nowrap;
  }

  ${BInputField} {
    width: 100%;
  }
`

export const CellLink = Cell.withComponent('a')

export const HeadCell = styled.div`
  padding: ${size('padding', 'sm')};
  background: ${color('white')};
  border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};
  font-weight: ${fontWeight('bold')};
  text-align: ${props => props.align || 'left'};
  ${props => props.minWidth && `min-width: ${props.minWidth};`}

  ${props => props.noBorder && 'border: none !important;'}

  white-space: nowrap;

  ${({ headerVerticalAlign }) => headerVerticalAlign === 'center' && css`
    display: flex;
    align-items: center;
  `}

  ${({ cursor }) => cursor && css`
    cursor: ${cursor};
  `}
`

export const VisibleOnHover = styled.span``

export const Row = styled.div`
  display: contents;

  ${props => {
    if (props.columns) {
      let columns = props.columns.map(column => {
        if (column.width && typeof column.width === 'number') {
          return column.width + '%'
        }

        return column.width || '1fr'
      })

      if (props.select) {
        columns = ['max-content'].concat(columns)
      }

      return css`
        display: grid;
        grid-template-columns: ${columns.join(' ')};
      `
    }
  }}

  & > form {
    display: contents;
  }

  &:not(:last-child) ${Cell}, &:not(:last-child) ${CellLink}  {
    border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};
  }

  &:first-child ${Cell}, &:first-child ${CellLink} {
    border-top: ${size('borderWidth')} solid ${color('Misc/Divider')};
  }

  &.dragging {
    ${FloatingColumn} {
      visibility: hidden;
    }
  }

  ${props => props.hoverable && css`
    cursor: pointer;
    ${VisibleOnHover} {
      visibility: hidden;
    }
    &:hover {
      > * {
        background: ${color('Background/Base')};
      }
      ${VisibleOnHover} {
        visibility: inherit;
      }
    }
    @media  (max-width: 1280px) {
      ${VisibleOnHover} {
        visibility: inherit;
      }
    }
  `}

  &.dragging ${Cell}, &.dragging ${CellLink} {
    border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')} !important;
    border-top: ${size('borderWidth')} solid ${color('Misc/Divider')} !important;
  }

  ${props => props.expanded && css`
    &:hover > *, > *, label {
      background: ${color('Primary/Base')};
      color: ${color('Text/Inverted')};
    }
  `}
`

export const Body = styled.div`
  display: contents;
`

export const Head = styled.div`
  display: contents;
  /* Old CSS override */
  &::after {
    content: none !important;
  }
`

export const GroupHeader = styled.div`
  padding: ${size('padding', 'xs')} ${size('padding', 'lg')};

  background-color: ${color('Background/Base')};

  grid-column-start: 1;
  grid-column-end: inherit;
`

export const GroupHeaderTitle = styled.span`
  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('semibold')};
  line-height: 1.4em;

  color: ${color('Text/Secondary')};
`

export const GroupHeaderSubtitle = styled.span`
  font-size: ${fontSize('md')};
  line-height: 1.4em;
  font-weight: ${fontWeight('lighter')};

  color: ${color('Text/Secondary')};
`

export const Dragger = styled.span`
  margin-right: ${size('margin', 'sm')};

  ${({ noMargin }) => noMargin && css`
    margin-right: 0;
  `}

  cursor: pointer;
`

export const PositionContainer = styled.div`
  position: relative;
`

export const DraggableTable = styled.div`
  overflow-x: auto;

  ${({ hideScrollbar }) => hideScrollbar && css`
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `}

  ${({ normalOverflow }) => !normalOverflow && css`
    overflow: visible;
  `}

  ${props => props.rowComponent && css`
    ${Row} {
      & > * {
        display: contents;
      }
    }
  `}

  ${({ noBorderTop }) => noBorderTop && css`
    /* Fix for draggable tables having a double border at the top */
    ${Row}:first-child ${Cell}, ${Row}:first-child ${CellLink} {
      border-top: 0 !important;
    }
  `}

  ${({ borderBottom }) => borderBottom && css`
    ${Row}:last-child ${Cell}, ${Row}:last-child ${CellLink} {
      border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};
    }
  `}

  ${({ inverseBorders }) => inverseBorders && css`
    ${Row}:not(:first-child) ${Cell}, ${Row}:not(:first-child) ${CellLink} {
      border: none;
      border-top: ${size('borderWidth')} solid ${color('Misc/Divider')};
    }

    ${Row}:first-child ${Cell}, ${Row}:first-child ${CellLink} {
      border: none;
    }
  `}
`

export const Table = styled.div`
  ${({ normalOverflow }) => !normalOverflow && css`
    overflow-x: auto;
  `}

  ${({ hideScrollbar }) => hideScrollbar && css`
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `}

  ${({ borderBottom }) => borderBottom && css`
    ${Row}:last-child ${Cell}, ${Row}:last-child ${CellLink} {
      border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};
    }
  `}

  ${({ noBorderTop }) => noBorderTop && css`
    ${Row}:first-child ${Cell}, ${Row}:first-child ${CellLink} {
      border-top: 0 !important;
    }
  `}

  ${({ inverseBorders }) => inverseBorders && css`
    ${Row}:not(:first-child) ${Cell}, ${Row}:not(:first-child) ${CellLink} {
      border-top: ${size('borderWidth')} solid ${color('Misc/Divider')};
    }
  `}

  ${props => {
    if (props.columns) {
      let columns = props.columns.map(column => {
        if (column.width && typeof column.width === 'number') {
          return column.width + '%'
        }

        return column.width || '1fr'
      })

      if (props.select) {
        columns = ['max-content'].concat(columns)
      }

      return css`
        display: grid;
        grid-template-columns: ${columns.join(' ')};
        grid-column-end: ${columns.length + 1};

        label {
          margin-bottom: 0;

          i.fa-check {
            margin-right: 0 !important;
          }
        }
      `
    }
  }}

  ${props => props.rowComponent && css`
    ${Row} {
      & > * {
        display: contents;
      }
    }
  `}

  & > div:first-child > ${GroupHeader} {
    border-top: none;
  }
`

export const GroupContainer = styled.div`
  display: contents;
  grid-column-end: inherit;

  > ${GroupHeader} {
    border-top: ${size('borderWidth')} solid ${color('Misc/Divider')};
    border-bottom: ${size('borderWidth')} solid ${color('Misc/Divider')};

    grid-column-start: 1;
    grid-column-end: inherit;
  }
`
