/**
 * Encode field name string to avoid fomik seeing it as a path
 *
 * @example
 * encodeFieldName("a.b[1]") -> "a%2Eb%5B1%5D"
 *
 * @param
 * string String
 */
export const encodeFieldName = (string) => {
  return encodeURI(string).replaceAll('.', '%2E')
}

/**
 * Decode field name to human readable
 *
 * @example
 * decodeFieldName("a%2Eb%5B1%5D") -> "a.b[1]"
 *
 * @param
 * encodedString String
 */
export const decodeFieldName = (encodedString) => {
  return decodeURI(encodedString)
}
