import styled from 'styled-components'
import { fontSize, injectModifiers } from 'ui/themes/utils'
import modifiers from 'ui/themes/modifiers'

const H1 = styled.h1`
  font-size: ${fontSize('xxl')};
  font-weight: 100;

  ${({ theme }) => injectModifiers(modifiers(theme))}
`

export default H1
