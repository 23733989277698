// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import BUrlSelect from 'ui/blocks/UrlSelect'

// Libraries
import { components } from 'react-select'

// Shared

const Link = (props) => {
  return (
    <BUrlSelect.Option
      {...props.innerProps}
      isSelected={props.isSelected}
      isFocused={props.isFocused}
      onMouseEnter={props.onEnterOption}
      onMouseLeave={props.onLeaveOption}
      borderBottom={props.data?.divider}
    >
      <components.Option {...props} />
    </BUrlSelect.Option>
  )
}

Link.propTypes = {
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  onEnterOption: PropTypes.func,
  onLeaveOption: PropTypes.func,
  data: PropTypes.shape({
    divider: PropTypes.bool
  }),
  // react-select internal props
  innerProps: PropTypes.object
}

export default Link
