import styled, { css } from 'styled-components'
import { color, fontSize, fontWeight, injectModifiers } from 'ui/themes/utils'
import modifiers from 'ui/themes/modifiers'

const Label = styled.label`
  display: block;
  color: ${color('Text/Primary')};
  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('semibold')};
  line-height: ${fontSize('md')};
  margin-bottom: 5px;

  ${props => props.inline && css`
    display: inline;
    vertical-align: middle;
  `}

  ${props => props.cursor && css`
    cursor: ${props.cursor};
  `}

  ${({ theme }) => injectModifiers(modifiers(theme))}
`

export default Label
