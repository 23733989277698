import sample from 'lodash/sample'

import saskatoonPartyRentals from '@assets/images/reviews/saskatoon-party-rentals.png'
import bubbleHire from '@assets/images/reviews/bubble-hire.png'
import fomoRentals from '@assets/images/reviews/fomo-rentals.png'
import goldCoastAudio from '@assets/images/reviews/gold-coast-audio.png'
import hireLoveLogo from '@assets/images/reviews/hire-love.png'
import islandDogLogo from '@assets/images/reviews/island-dog.png'
import refinedRentals from '@assets/images/reviews/refined-rentals.png'
import sydneyPaHire from '@assets/images/reviews/sydney-pa-hire.png'
import treasuredVintage from '@assets/images/reviews/treasured-vintage.png'
import twoElements from '@assets/images/reviews/two-elements.png'
import equipmentCafe from '@assets/images/reviews/equipment-cafe.png'
import juistPirates from '@assets/images/reviews/juist-pirates.png'
import hookStudios from '@assets/images/reviews/hook-studios.png'
import mintSage from '@assets/images/reviews/mint-sage.png'
import rentForEvents from '@assets/images/reviews/rent-for-events.png'
import modernMusicianHire from '@assets/images/reviews/modern-musician-hire.png'
import laMelange from '@assets/images/reviews/la-melange.png'
import bikePacker from '@assets/images/reviews/bike-packer.png'
import deinKameraverleih from '@assets/images/reviews/dein-kameraverleih.png'
import tableTopPartyRentals from '@assets/images/reviews/table-top-party-rentals.png'
import theCottage from '@assets/images/reviews/the-cottage.png'
import eventEnvy from '@assets/images/reviews/event-envy.png'
import theJumpingPartyCo from '@assets/images/reviews/the-jumping-party-co.png'
import alpine from '@assets/images/reviews/alpine.png'
import ventureOut from '@assets/images/reviews/venture-out.png'
import johnFMcclellan from '@assets/images/reviews/john-f-mcclellan.png'

const REVIEWS = {
  general: [
    {
      key: 'treasured_vintage',
      name: 'Jane McPherson',
      company: 'Treasured Vintage Rentals',
      image: treasuredVintage,
      industry: 'Event / Party'
    },
    {
      key: 'sydney_pa_hire',
      name: 'Lee Wright',
      company: 'Sydney PA Hire',
      image: sydneyPaHire,
      industry: 'AV / Camera'
    },
    {
      key: 'refined_rentals',
      name: 'Hannah Davies',
      company: 'Refined Rentals LLC',
      image: refinedRentals,
      industry: 'Event / Party'
    },
    {
      key: 'two_elements',
      name: 'Chris Haysey',
      company: 'Two Elements',
      image: twoElements,
      industry: 'Bikes / Scooters'
    },
    {
      key: 'bubble_hire',
      name: 'Pete Fennell',
      company: 'Bubble Hire Essex',
      image: bubbleHire,
      industry: 'Event / Party'
    },
    {
      key: 'saskatoon_party_rentals',
      name: 'Lee Jones',
      company: 'Saskatoon Party Rentals',
      image: saskatoonPartyRentals,
      industry: 'Event / Party'
    },
    {
      key: 'gold_coast_audio',
      name: 'Thom Combe',
      company: 'Gold Coast Audio Hire',
      image: goldCoastAudio,
      industry: 'AV / Camera'
    },
    {
      key: 'island_dog',
      name: 'Julie Krontz',
      company: 'Island Dog Outdoors',
      image: islandDogLogo,
      industry: 'Sports / Activities'
    },
    {
      key: 'hire_love',
      name: 'David Baker',
      company: 'Hire Love UK',
      image: hireLoveLogo,
      industry: 'Event / Party'
    },
    {
      key: 'fomo_rentals',
      name: 'Jamie Bezencet',
      company: 'FOMO Rentals',
      image: fomoRentals,
      industry: 'AV / Camera'
    },
    {
      key: 'rent_for_events',
      name: 'Donella Yelavich',
      company: 'Rent for Events',
      image: rentForEvents,
      industry: 'Event / Party'
    },
    {
      key: 'modern_musician_hire',
      name: 'Nick Boron',
      company: 'Modern Musician Hire',
      image: modernMusicianHire,
      industry: 'Other'
    },
    {
      key: 'la_melange',
      name: 'Diana Safdie',
      company: 'La Melange',
      image: laMelange,
      industry: 'Event / Party'
    },
    {
      key: 'bike_packer',
      name: 'Olivia de Briey',
      company: 'Bike Packer',
      image: bikePacker,
      industry: 'Bikes / Scooters'
    },
    {
      key: 'dein_kameraverleih',
      name: 'Franz Lermer',
      company: 'Dein Kameraverleih',
      image: deinKameraverleih,
      industry: 'AV / Camera'
    },
    {
      key: 'table_top_party_rentals',
      name: 'Bernard Fraser',
      company: 'Table Top Party Rentals',
      image: tableTopPartyRentals,
      industry: 'Event / Party'
    },
    {
      key: 'the_cottage',
      name: 'Lisa Dowson',
      company: 'The Cottage Events',
      image: theCottage,
      industry: 'Event / Party'
    },
    {
      key: 'event_envy',
      name: 'Sheri Melanson',
      company: 'Event Envy',
      image: eventEnvy,
      industry: 'Event / Party'
    },
    {
      key: 'the_jumping_party_co',
      name: 'Abbey Kick',
      company: 'The Jumping Party Co',
      image: theJumpingPartyCo,
      industry: 'Event / Party'
    },
    {
      key: 'alpine',
      name: 'Zaineel Prasla',
      company: 'Alpine Rentals',
      image: alpine,
      industry: 'Event / Party'
    },
    {
      key: 'venture_out',
      name: 'Ashley MacLean',
      company: 'Venture Out Rentals',
      image: ventureOut,
      industry: 'Sports / Activities'
    },
    {
      key: 'john_f_mc_clellan',
      name: 'John F. McClellan',
      company: 'John F. McClellan Cinematographer',
      image: johnFMcclellan,
      industry: 'AV / Camera'
    }
  ],
  signup: [
    {
      key: 'gold_coast_audio',
      name: 'Thom Combe',
      company: 'Gold Coast Audio Hire',
      image: goldCoastAudio
    }
  ],
  barcodes: [
    {
      key: 'equipment_cafe',
      name: 'Jonathan Andel.',
      company: 'equipment.cafe',
      image: equipmentCafe
    }
  ],
  reports: [
    {
      key: 'hook_props',
      name: 'Zak M.',
      company: 'Hook Props',
      image: hookStudios
    },
    {
      key: 'juist_pirates_bikes',
      name: 'Carsten Peplow',
      company: 'Juist Pirates Bikes',
      image: juistPirates
    }
  ],
  coupons: [
    {
      key: 'mint_sage_events',
      name: 'Caitlin Taylor',
      company: 'Mint & Sage Events',
      image: mintSage
    }
  ]
}

/**
 * Return a random customer review
 * A context could be passed, to get a review for a specific context
 * Example: customerReview('barcodes')
 * General reviews can be filtered by industry
 * Example: customerReview('general', 'Event / Party')
 */
export default function customerReview (context = 'general', industry) {
  // Fallback to general reviews, if no specific context reviews are present
  if (!REVIEWS[context]) {
    context = 'general'
  }

  let pool = REVIEWS[context]

  if (context === 'general' && industry) {
    pool = pool.filter((review) => review.industry === industry)

    // Fallback when a industry has zero reviews, then we just take all general
    if (pool.length === 0) pool = REVIEWS[context]
  }

  const { key, name, company, image } = sample(pool)

  return {
    reviewKey: key,
    reviewName: name,
    reviewCompany: company,
    reviewImage: image
  }
}
