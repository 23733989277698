// React
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import Select from 'ui/components/Select'
import Spacer from 'ui/blocks/Spacer'
import Option from './Option'
import SingleValue from './SingleValue'

export default class FontSelect extends Component {
  static displayName = 'FontSelect'

  static propTypes = {
    field: PropTypes.object,
    pageSize: PropTypes.number
  }

  static defaultProps = {
    pageSize: 15
  }

  state = {
    page: 1,
    query: null,
    loading: false,
    options: []
  }

  get currentOption () {
    const { field } = this.props
    const { options } = this.state

    return options.find((option) => option.value.includes(field.value.split('_')[0]))
  }

  // get currentVariant () {
  //   if (!this.currentOption) return null

  //   const { field } = this.props

  //   const variant = field.value.split('_')?.[1]

  //   if (!variant) {
  //     return this.optionVariants[0]
  //   }

  //   return this.optionVariants.find((option) => option.value === variant)
  // }

  // get optionVariants () {
  //   const { options } = this.state

  //   if (!this.currentOption) return null

  //   return options
  //     .find((option) => option.value === this.currentOption.value)
  //     .variants.map((variant) => {
  //       if (variant.includes('i')) {
  //         return {
  //           value: `i${variant
  //             .replace('i', '')
  //             .slice(0, 1)}`,
  //           label: `${variant.replace('i', '')} Italic`
  //         }
  //       }

  //       return {
  //         value: `n${variant.slice(0, 1)}`,
  //         label: variant
  //       }
  //     })
  // }

  componentDidMount = () => {
    this.handleLoadOptions()
  }

  componentDidUpdate = (prevProps) => {
    const { field } = this.props

    if (field.value !== prevProps.field.value) {
      this.handleLoadOptions()
    }
  }

  handleLoadOptions = async () => {
    const { page, query } = this.state
    const { field, pageSize } = this.props

    this.setState({ loading: true })

    const raw = await import('./static/fonts.json').then((data) => data.default)
    const value = field.value ? raw.find((o) => o.value === field.value.split('_')[0]) : null

    if (query) {
      const filtered = [
        value,
        ...raw
          .filter((option) => {
            if (value) return option !== value && option.label.toLowerCase().includes(query.toLowerCase())

            return option.label.toLowerCase().includes(query.toLowerCase())
          })
          .slice(0, page * pageSize)
      ].filter((v) => !!v)

      return this.setState({ options: filtered, loading: false })
    }

    const options = [value, ...raw.filter((v) => v !== value).slice(0, page * pageSize)].filter((v) => !!v)

    this.setState({ options, loading: false })
  }

  handleScrollToBottom = () => {
    this.setState({ page: this.state.page + 1 }, this.handleLoadOptions)
  }

  handleSearch = (query) => {
    this.setState({ query, page: 1 }, this.handleLoadOptions)
  }

  handleFontChange = (option) => {
    const { field } = this.props

    field.onChange(option)
  }

  // handleVariantChange = (option) => {
  //   const { field } = this.props

  //   field.onChange(`${field.value.split('_')[0]}_${option}`)
  // }

  render () {
    const { options, loading } = this.state

    return (
      <Fragment>
        <Select
          field={{
            name: 'font',
            onChange: this.handleFontChange,
            value: this.currentOption?.value
          }}
          components={{ Option, SingleValue }}
          options={options}
          onMenuScrollToBottom={this.handleScrollToBottom}
          onInputChange={this.handleSearch}
          isLoading={loading}
        />
        {/* Component for selecting font variant */}
        {/* {!!this.optionVariants && (
          <Fragment>
            <Spacer size="sm" />
            <Select
              field={{
                name: 'variant',
                onChange: this.handleVariantChange,
                value: this.currentVariant?.value
              }}
              options={this.optionVariants}
              isLoading={loading}
              disabled={this.optionVariants.length === 1}
            />
          </Fragment>
        )} */}
      </Fragment>
    )
  }
}
