import styled, { css } from 'styled-components'
import { size, color, fontWeight, fontSize, isDark } from 'ui/themes/utils'

import DayPicker from 'react-day-picker'

export const StyledDayPicker = styled(DayPicker)`
  display: inline-block;

  font-family: ${(props) => props.theme.font.body};
  font-size: ${fontSize()};

  outline: none;

  * {
    outline: none;
  }

  .DayPicker-wrapper {
    position: relative;
    user-select: none;
    flex-direction: row;
  }

  .DayPicker-Month {
    display: block;

    user-select: none;
    padding: 0 1em;
    margin: 0;
    margin-top: 1em;

    &:first-child {
      border-right: 1px solid ${color('Misc/Divider')};
    }

    /* If there's no other months, cancel the border */
    &:last-child {
      border-right: 0;
    }
  }

  .DayPicker-Caption {
    margin-top: 0.2em;
    padding: 0 0.5rem;
    display: block;
    text-align: center;
  }

  .DayPicker-Caption > div {
    font-size: 16px;
    font-weight: ${fontWeight('semibold')};
  }

  .DayPicker-Weekdays {
    margin-top: 0;
    display: block;
  }

  .DayPicker-WeekdaysRow {
    display: flex;
  }

  .DayPicker-Weekday {
    display: block;
    padding: 0.8rem 0;
    font-size: 13px;
    text-align: center;
    color: ${color('Text/Primary')};
    opacity: 0.6;
    padding-bottom: 1rem;
    text-transform: lowercase;
    min-width: 50px;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: block;

    /* For disabled days */
    background: repeating-linear-gradient(
      315deg,
      white,
      white 6px,
      ${color('Misc/Divider')} 6px,
      ${color('Misc/Divider')} 7px
    );
  }

  .DayPicker-Week {
    display: flex;
  }

  /* Remove border from sides */

  /* Right side */
  .DayPicker-Week .DayPicker-Day:last-child {
    border-right: none;
  }

  /* Top side */
  .DayPicker-Week:first-child .DayPicker-Day {
    border-top: none;
    border-bottom: none;
  }

  .DayPicker-Day {
    position: relative;

    border-radius: 0;
    border: ${size('borderWidth')} solid ${color('Misc/Divider')};
    border-left: none;
    border-bottom: none;

    height: 60px;
    min-width: 50px;

    display: block;

    color: ${color('Text/Primary')};
    padding: 0;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;

    background: ${color('white')};

    z-index: 0;

    .DayPicker-Day__inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      height: 100%;
    }

    .DayPicker-Day__date {
      display: inline-block;

      padding: 2px 5px;
      margin-top: 7px;
      margin-bottom: 9px;

      font-size: 12px;

      border-radius: ${size('borderRadius', 'sm')} !important;

      @media (max-width: 375px) {
        margin-bottom: 10%;
      }
    }

    .DayPicker-Day__availability {
      width: 90%;
      height: 30%;

      border-radius: ${size('borderRadius', 'sm')} !important;

      line-height: ${fontSize('lg')};

      color: ${color('Text/Inverted')};

      &.available {
        background: ${color('Success/Outline')};
      }

      &.unavailable {
        background: ${color('Danger/Base')};
      }

      &.partial {
        background: ${color('Warning/Outline')};
      }

      &.loading {
        background: #000000;
        opacity: 0.15;

        animation: pulse 1.5s infinite ease-in-out;

        @keyframes pulse {
          0% { opacity: 0.05; }
          50% { opacity: 0.15; }
          100% { opacity: 0.05; }
        }
      }

      @media (max-width: 425px) {
        width: 80%;

        font-size: 13px;
      }
    }
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5rem;
  }

  .DayPicker-TodayButton {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    color: ${color('Primary/Background2')};
    font-size: 0.875em;
  }

  /* Default modifiers */

  .DayPicker-Day:not(.DayPicker-Day--today) {
    font-weight: ${fontWeight('light')};
  }

  .DayPicker-Day--today {
    font-weight: ${fontWeight('bold')};
  }

  .DayPicker-Day--outside {
    color: ${color('Text/Tertiary')};
  }

  .DayPicker-Day--disabled.DayPicker-Day--outside {
    color: ${color('Text/Primary')};
  }

  .DayPicker-Day--disabled {
    cursor: default;
    pointer-events: none;
    background: transparent !important;
    background-position: 1px 0px;
  }

  /*  modifiers */
  .DayPicker-Day--blocked {
    color: ${color('Text/Primary')};
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    z-index: 1;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
    &::after {
      background-color: ${color('Accent1/Background1')} !important;
      height: calc(100% + 1px);
    }
  }

  .DayPicker-Day:before, .DayPicker-Day:after {
    /* -1 so we're ontop of the cell border */
    top: -1px;
    left: -1px;

    box-sizing: content-box;

    height: 100%;
    width: calc(100% + 1px);
  }

  .DayPicker-Day:before {
    content: '';
    position: absolute;
  }

  .DayPicker-Day:after {
    content: '';
    position: absolute;
    z-index: -2;
  }

  /* Selection logic */
  .DayPicker-Week .DayPicker-Day.DayPicker-Day--selection {
    &:before {
      background-color: transparent !important;
    }

    &:after {
      background-color: ${color('Primary/Base')}1a !important;
      height: calc(100% + 1px);
    }

    /* Selection that is not the start nor the end we use background color here since we don't need to round corners.
    The magic starts on from and to dates */
    &:not(.DayPicker-Day--selectionStart):not(.DayPicker-Day--selectionEnd) {
      border-collapse: separate;
    }
  }

  /* Selection that neither start nor end, we can use regular background */
  .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to) {
    color: ${color('Text/Primary')};
    background: ${color('white')};

    &::before {
      background-color: ${color('Primary/Base')}0f;
      border-top: 1px solid ${color('Primary/Base')};
      border-bottom: 1px solid ${color('Primary/Base')};
    }
  }

  /* Current selection from */
  .DayPicker-Week .DayPicker-Day.DayPicker-Day--from {
    color: ${(props) =>
      isDark(color('Primary/Base')(props)) ? color('Text/Inverted')(props) : color('Text/Primary')(props)
    } !important;
    background: ${color('white')};

    &::before {
      border: 1px solid ${color('Primary/Base')};
      border-top-left-radius: ${size('borderRadius', 'md')} !important;
      border-bottom-left-radius: ${size('borderRadius', 'md')} !important;
      background-color: ${color('Primary/Base')}0f;
    }

    /* We still need the border if there's no to */
    ${props => props.to && css`
      &::before {
        border-right: 0;
      }
    `}

    /* This make sure the from day is round when no selection yet, also works for single date pickers */
    ${props => !props.to && css`
      &::before {
        width: 100% !important;
        border-radius: ${size('borderRadius', 'md')} !important;
      }
    `}

    .DayPicker-Day__date {
      background: ${color('Primary/Base')};
      font-weight: ${fontWeight('bold')};
    }
  }

  /* Current selection to */
  .DayPicker-Week .DayPicker-Day.DayPicker-Day--to {
    color: ${(props) =>
      isDark(color('Primary/Base')(props)) ? color('Text/Inverted')(props) : color('Text/Primary')(props)
    } !important;
    background: ${color('white')};

    &::before {
      border: ${size('borderWidth')} solid ${color('Primary/Base')};
      border-left: 0;
      border-top-right-radius: ${size('borderRadius', 'md')} !important;
      border-bottom-right-radius: ${size('borderRadius', 'md')} !important;
      background-color: ${color('Primary/Base')}0f;
    }

    .DayPicker-Day__date {
      background: ${color('Primary/Base')};
      font-weight: ${fontWeight('bold')};
    }
  }

  /* To and from on the same day */
  .DayPicker-Week .DayPicker-Day.DayPicker-Day--to.DayPicker-Day--from {
    &::before {
      width: 100%;
      border: ${size('borderWidth')} solid ${color('Primary/Base')} !important;
    }
  }

  /* This is a default style override, we don't want the off theme light blue background on hover */
  &:not(.DayPicker--interactionDisabled) .DayPicker-Week
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
    background-color: ${color('white')};
  }

  &:not(.DayPicker--interactionDisabled) .DayPicker-Week .DayPicker-Day--today:not(.DayPicker-Day--disabled) {
    font-weight: ${fontWeight('bold')};
  }

  /* Hover */
  &:not(.DayPicker--interactionDisabled) .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled) {
    cursor: pointer;

    &:hover {
      &:after {
        background-color: ${color('Primary/Base')}1a !important;
        height: calc(100% + 1px) !important;
      }
    }
  }
`
