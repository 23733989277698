import styled from 'styled-components'
import { size } from 'ui/themes/utils'

export const Duration = styled.div``

export const Fields = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  > * {
    margin-right: ${size('padding', 'sm')};
    width: 50%;
    &:last-child {
      margin-right: 0;
    }
  }
`
