// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BQuantityInput from 'ui/blocks/QuantityInput'
import InputGroup from 'ui/blocks/InputGroup'
import Icon from 'ui/components/Icon'
import Tooltip from 'ui/components/Tooltip'

// Shared

/**
 * Input for quantities or numbers
 *
 * @example
 *   <QuantityInput field={field} min="0" max={itemsAvailable}  />
 */
export default class QuantityInput extends Component {
  static displayName = 'QuantityInput'

  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    field: PropTypes.object,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    disabled: PropTypes.bool,
    disabledTooltip: PropTypes.object,
    autoFocus: PropTypes.bool,
    allowEmpty: PropTypes.bool
  }

  static defaultProps = {
    size: 'md',
    field: {}
  }

  state = {
    value: this.props.field.value
  }

  componentDidUpdate = (prevProps) => {
    let fieldValue = parseInt(this.props.field.value)

    if (prevProps.field.value !== this.props.field.value) {
      if (this.state.value !== fieldValue) {
        if (isNaN(fieldValue)) {
          fieldValue = this.props.field.value
        }

        this.setState({ value: fieldValue })
      }
    }
  }

  handleAdd = () => {
    const { onChange, value } = this.props.field
    const { max } = this.props

    let tempValue = value

    if (typeof value === 'string') {
      tempValue = parseInt(value)
    }

    if (max == null || tempValue + 1 <= max) {
      // Use uniary + to coerce value to number
      const value = +tempValue + 1

      onChange(value)
      this.setState({ value })
    }
  }

  handleSubtract = () => {
    const { onChange, value } = this.props.field
    const { min } = this.props

    let tempValue = value

    if (typeof value === 'string') {
      tempValue = parseInt(value)
    }

    if (min == null || tempValue - 1 >= min) {
      // Use uniary + to coerce value to number
      const value = +tempValue - 1

      onChange(value)
      this.setState({ value })
    }
  }

  handleChange = (e) => {
    const { onChange } = this.props.field
    const { min, max, allowEmpty } = this.props

    if (e.target.value === '') {
      if (allowEmpty) {
        onChange(e.target.value)
      }

      this.setState({ value: e.target.value })

      return
    }

    let value = e && Number(e.target.value)

    if (min !== undefined && (min > value)) {
      value = min
    } else if (max !== undefined && (max < value)) {
      value = max
    }

    onChange(value)
    this.setState({ value })
  }

  handleBlur = (e) => {
    const { value, onBlur } = this.props.field

    this.setState({ value })
    onBlur && onBlur(e)
  }

  render () {
    const { field, disabled, size, max, min, autoFocus, disabledTooltip, ...otherProps } = this.props
    const maxReached = field.value >= max
    const minReached = field.value <= min
    const { value } = this.state

    return (
      <BQuantityInput disabled={disabled} size={size}>
        <InputGroup preserveBorders>
          <BQuantityInput.Input
            max={max}
            min={min}
            {...field}
            {...otherProps}
            value={value}
            disabled={disabled}
            size={size}
            autoFocus={autoFocus}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
          <BQuantityInput.ControlContainer>
            <BQuantityInput.Control
              plus
              disabled={maxReached || disabled}
              onClick={!maxReached && !disabled ? this.handleAdd : undefined}
              size={size}
            >
              <Tooltip
                direction="top"
                content={(maxReached || disabled) && (disabledTooltip?.max || null)}
              >
                <Icon icon="plus" data-tid="Bump up order weight" />
              </Tooltip>
            </BQuantityInput.Control>
            <BQuantityInput.Control
              minus
              disabled={minReached || disabled}
              onClick={!minReached && !disabled ? this.handleSubtract : undefined}
              size={size}
            >
              <Tooltip
                direction="bottom"
                content={(minReached || disabled) && (disabledTooltip?.min || null)}
              >
                <Icon icon="minus" data-tid="Bump down order weight" />
              </Tooltip>
            </BQuantityInput.Control>
          </BQuantityInput.ControlContainer>
        </InputGroup>
      </BQuantityInput>
    )
  }
}
