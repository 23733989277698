import styled, { css } from 'styled-components'
import TextAreaElement from 'ui/elements/TextArea'

export const TextArea = styled(TextAreaElement)`
  ${({ nowrap }) => nowrap && css`
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
  `}

  ${({ monospace }) => monospace && css`
    font-family: monospace;
  `}
`
