import { prependProtocol } from 'client/v2/utils/utils'
import RedactorX from 'shared/lib/redactorx'

export function validateLink (url = null) {
  let html, block

  if (!url) {
    block = this.app.block.get()
    html = block.getHtml()
  } else {
    html = url
  }

  html = prependProtocol(html)

  if (url) return html

  block.setHtml(html)
}

export default function initLinkValidatePlugin () {
  if (window.linkValidatePluginInitialized) return

  RedactorX.add('plugin', 'linkValidate', {
    subscribe: {
      'link.add': function () {
        this.validateLink()
      },
      'link.change': function () {
        this.validateLink()
      }
    },
    validateLink
  })

  window.linkValidatePluginInitialized = true
}
