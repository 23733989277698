// React
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'
import Yup from 'shared/utils/yup'

// Components
import Layout from 'signup/components/Layout'
import Button from 'ui/components/Button'
import Div from 'ui/elements/Div'
import Select from 'signup/components/Select'

// Shared
import withForm, { Form, Field } from 'shared/form'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import SignupActions from 'signup/redux/actions/signup'
import { receiveOnlineOrdersData, pickupMethodsData, currentWebsiteData } from './static/marketingData'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'

export class Step5 extends React.Component {
  static displayName = 'Step5'
  static propTypes = {
    t: PropTypes.func.isRequired,
    values: PropTypes.shape({
      current_website: PropTypes.string,
      online_orders: PropTypes.string,
      pickup_methods: PropTypes.arrayOf(PropTypes.string)
    }),
    submitPromise: PropTypes.instanceOf(Promise)
  }

  componentDidMount = () => {
    EventTracker.page()
    EventTracker.track('Signup: Step 5')
  }

  render = () => {
    const { submitPromise, t } = this.props

    return (
      <Layout step={5}>
        <Form>
          <Select
            identifier="current_website"
            options={currentWebsiteData(t)}
          />

          <Select
            identifier="online_orders"
            options={receiveOnlineOrdersData(t)}
          />

          <Div modifiers={'margin-top-lg'}>
            <Field
              type="checkboxGroup"
              name="pickup_methods"
              errorOnSubmit
              label={t('signup.fields.pickup_methods.label')}
              description={t('signup.fields.pickup_methods.description')}
              descriptionTop
              bordered
              noInline
              options={pickupMethodsData(t)}
              modifiers={{ label: 'line-height-lg height-auto' }}
            />
          </Div>

          <Div modifiers={'margin-top-lg margin-top-md-xl'}>
            <Button
              size="lg"
              type="submit"
              promise={submitPromise}
              block
              borderRadius={'mdplus'}
              modifiers={'margin-bottom-xl'}
            >
              {t('signup.continue')}
            </Button>
          </Div>
        </Form>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  const pickupMethods = state.signup.pickup_methods || []
  const normalizedPickupMethods = Array.isArray(pickupMethods) ? pickupMethods : pickupMethods.toArray()

  return {
    initialValues: {
      current_website: state.signup.current_website || '',
      online_orders: state.signup.online_orders || '',
      pickup_methods: normalizedPickupMethods
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (values) => {
      return SignupActions.update(window.id, values)
    },
    validationSchema: Yup.object().shape({
      current_website: Yup
        .string()
        .required(),
      online_orders: Yup
        .string()
        .required(),
      pickup_methods: Yup
        .array()
        .required()
        // Prevent min validation message
        .min(1, props.t('common.errors.mixed.required'))
    }),
    serverSuccess: () => {
      RoutingUtils.navigate(`/${window.locale}/step-6`)
    }
  }
}

export default compose(
  withTranslation('signup'),
  reduxPureConnect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Step5)
