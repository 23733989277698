import styled, { css } from 'styled-components'

import I from 'ui/elements/I'
import InputElement from 'ui/elements/Input'

import { size, color, fontSize } from 'ui/themes/utils'

export const Input = styled(InputElement)`
  ${props => props.lineThrough && css`
    text-decoration: line-through;
  `}

  ${props => props.uppercase && css`
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  `}

  ${props => props.type === 'number' && props.hideArrows && css`
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `}
`

export const InputContainer = styled.span`
  &&& {
    display: block;

    position: relative;

    font-size: ${fontSize()};

    > ${I} {
      position: absolute;

      height: ${size('controlHeight')};
      width: ${size('controlHeight')};

      color: ${color('Text/Tertiary')};

      font-size: 1.25em;
      font-style: normal;
      line-height: ${size('controlHeight')};
      text-align: center;

      pointer-events: none;

      & + ${Input} {
        padding-left: ${size('controlHeight')};
      }

      &.fa-spinner-third {
        color: ${color('Primary/Base')};
      }
    }
  }
`
