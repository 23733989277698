import styled, { css } from 'styled-components'

import { size, color, fontSize } from 'ui/themes/utils'

import Icon from 'ui/blocks/Icon'

export const Option = styled.div`
  display: flex;

  padding: ${size('padding', 'xs')} ${size('padding', 'sm')};
  padding-right: ${size('padding', 'xs')};
  border-radius: ${size('borderRadius', 'xxl')};

  background-color: ${color('light')};

  position: relative;

  ${Icon} {
    position: relative;
    top: 0;
    right: 0;

    line-height: ${fontSize('md')};

    pointer-events: all;

    transition: color 0.2s ease-in-out;
  }

  ${({ invalid }) => invalid && css`
    background-color: ${color('Danger/Base')};
    color: ${color('Text/Inverted')};

    ${Icon} {
      color: ${color('Text/Inverted')};
    }
  `}
`

export const Label = styled.span`
  font-size: ${fontSize('md')};
  line-height: ${fontSize('mdplus')};

  padding: 0;
  margin: 0;

  margin-right: ${size('margin', 'xs')};
`

export const Remove = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${color('Text/Secondary')};
  }
`
