// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import pick from 'lodash/pick'
import forIn from 'lodash/forIn'

// Components
import BCol from 'ui/elements/Col'

// Shared

/**
 * Grid column component.
 *
 * @example
 *   <Col sm={6} xs="2">Content</Col>
 */

export default class Col extends Component {
  static displayName = 'col'

  static propTypes = {
    children: PropTypes.node
  }

  computeClassName () {
    const screenSizes = ['xs', 'sm', 'md', 'lg']
    const screenValues = pick(this.props, screenSizes)
    const screenOffsets = pick(this.props?.offset, screenSizes)
    const classes = []

    forIn(screenValues, (value, screenSize) => {
      classes.push(`col-${screenSize}-${value}`)
    })

    forIn(screenOffsets, (value, screenSize) => {
      classes.push(`col-${screenSize}-offset-${value}`)
    })

    return [...classes, ...(this.props.classes || [])].join(' ')
  }

  render () {
    const {
      children,
      xs,
      sm,
      md,
      lg,
      xl,
      ...otherProps
    } = this.props

    return (
      <BCol
        className={this.computeClassName()}
        children={children}
        {...otherProps}
      />
    )
  }
}
