// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect as reduxConnect } from 'react-redux'

// Components
import Collection from 'ui/components/Collection'
import Select from './Select'

// Shared
import { stringIsLink, prependProtocol } from 'client/v2/utils/utils'

class UrlSelectWrapper extends Component {
  static displayName = 'UrlSelectWrapper'

  static propTypes = {
    field: PropTypes.object,
    photos: PropTypes.object,
    fetchItems: PropTypes.func,
    fetchPages: PropTypes.func,
    fetchCollections: PropTypes.func
  }

  state = {
    category: null,
    type: null,
    fetchCollection: null
  }

  componentDidMount = () => {
    const { field } = this.props

    if (field.value) {
      this.handleSetCategory()
    }
  }

  componentDidUpdate = (prevProps) => {
    const { field } = this.props

    if (!!field.value && field.value !== prevProps.field.value) {
      this.handleSetCategory()
    }
  }

  handleGetFetchCollection = (category) => {
    switch (category) {
      case 'products':
        return this.props.fetchItems
      case 'pages':
        return this.props.fetchPages
      case 'collections':
        return this.props.fetchCollections
      default:
        return null
    }
  }

  handleGetCollection = (state) => {
    const { category } = this.state

    switch (category) {
      case 'products':
        return state.orm.items
      case 'pages':
        return state.orm.pages
      case 'collections':
        return state.orm.collections
      default:
        return null
    }
  }

  handleSetCategory = () => {
    const { field } = this.props

    if (!field.value) return

    if (stringIsLink(field.value)) {
      return this.setState({
        value: field.value,
        type: 'link'
      })
    }

    const category = field.value.match(/booqable:\/\/(products|pages|collections)\//)?.[1]
    const fetchCollection = this.handleGetFetchCollection(category)

    this.setState({ category, fetchCollection })
  }

  handleCreate = (option) => {
    option = prependProtocol(option)

    this.setState({
      type: 'link',
      value: { label: option, value: option },
      category: null,
      fetchCollection: null
    }, () => this.props.field.onChange?.(option))
  }

  handleSetValues = ({ category, type, value }) => {
    const state = { category, type, value: { ...value } }

    Object.keys(state).forEach(key => state[key] === undefined && delete state[key])

    if (category) {
      state.fetchCollection = this.handleGetFetchCollection(category)
    }

    this.setState(state)
  }

  handleGoBack = () => {
    const { field } = this.props
    const { type } = this.state

    const isLink = !!field.value && type === 'link'
    const state = { category: null }

    if (!isLink) {
      state.type = null
    }

    this.setState(state)
  }

  render () {
    const { field, photos } = this.props
    const { category, type, fetchCollection, value, placeholder } = this.state

    return (
      <Collection
        fetchCollection={fetchCollection}
        getCollectionFromState={this.handleGetCollection}
        fetchOnMount={false}
        strategy="append"
        hideLoadMore
      >
        <Select
          value={value}
          field={field}
          category={category}
          type={type}
          photos={photos}
          handleSetValues={this.handleSetValues}
          onCreate={this.handleCreate}
          onGoBack={this.handleGoBack}
          placeholder={placeholder}
        />
      </Collection>
    )
  }
}

const mapStateToProps = (state, props) => ({
  productGroups: state.orm.product_groups.getAll(),
  photos: state.orm.photos
})

const mapDispatchToProps = (dispatch, props) => ({
  // We will pass the actions to the UrlSelect component like this:
  // <Field
  //   type="url"
  //   ...
  //   fetchActions={{
  //     products: ProductGroupActions
  //   }}
  // />
  fetchPages: (options) => props.fetchActions.pages.fetchAll({
    fields: {
      pages: 'id,title,slug'
    },
    page: {
      number: options.page,
      per: options.per
    }
  }),
  fetchItems: ({ page, per, filters }) => {
    let filter = {
      show_in_store: true,
      type: ['ProductGroup', 'Bundle']
    }

    if (filters?.name?.match) {
      filter = {
        ...filter,
        ...filters
      }
    }

    return props.fetchActions.products.fetchAll({
      apiVersion: 'boomerang',
      stateKey: 'items',
      filter,
      fields: {
        product_groups: 'id,name,slug'
      },
      include: 'photo',
      page: {
        number: page,
        per
      }
    })
  },
  fetchCollections: (options) => {
    const params = {
      fields: {
        collection: 'id,name,slug'
      },
      page: {
        number: options.page,
        per: options.per
      }
    }

    if (options.filters?.name?.match) {
      params.filter = options.filters
    }

    return props.fetchActions.collections.fetchAll(params)
  }
})

export default reduxConnect(mapStateToProps, mapDispatchToProps)(UrlSelectWrapper)
