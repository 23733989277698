// React
import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

// Libraries

// Components

// Shared

export default class PortalWrapper extends Component {
  static displayName = 'PortalWrapper'

  static propTypes = {
    // Content
    children: PropTypes.node,
    // State
    open: PropTypes.bool.isRequired
  }

  static defaultProps = {
    open: false
  }

  constructor (props) {
    super(props)

    this.el = document.createElement('div')
  }

  componentDidMount () {
    document.body.appendChild(this.el)
  }

  componentWillUnmount () {
    document.body.removeChild(this.el)
  }

  render () {
    const { open, children } = this.props

    return open
      ? createPortal(
        children,
        this.el
      )
      : null
  }
}
