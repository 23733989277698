import React, { Fragment } from 'react'

// Libraries
import i18n from 'i18next'

// Components
import Div from 'ui/elements/Div'
import Icon from 'ui/components/Icon'
import Select from 'ui/components/Select'

// Shared
import locales from 'shared/constants/locales'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'
import EventTracker from 'client/v2/utils/event_tracker'

class LocaleSwitcher extends React.Component {
  static displayName = 'LocaleSwitcher'

  state = {
    locale: {}
  }

  componentDidMount = () => {
    if (Object.keys(this.state.locale).length === 0) this.determineLocale(window.locale)
  }

  determineLocale = (locale) => {
    const fallbackLocale = locales.find(obj => { return obj.value === 'en' })
    const selectedLocale = locales.find(obj => {
      return obj.value === locale
    }) || fallbackLocale

    this.setState({
      locale: selectedLocale
    })
  }

  handleLocaleChange = (locale) => {
    i18n.changeLanguage(locale)
    window.locale = locale

    const pathWithLocale = location.pathname.split('/')

    // Change the locale in the current path
    // Example: /pt_BR/step-1 -> /fr/step-1
    pathWithLocale[1] = locale

    const path = pathWithLocale.join('/')

    RoutingUtils.navigate(path)

    EventTracker.track('Switched signup locale', { locale: locale })
  }

  render () {
    const { value, short } = this.state.locale

    return (
      <Div modifiers={'float-right margin-top-md padding-top-xs margin-right-md margin-right-md-xxl width-100'}>
        <Select
          field={{ value: value }}
          value={{
            value: value,
            label: <Fragment>
              <Icon icon="globe" modifiers={'text-Text/Secondary'} /> {short}
            </Fragment>
          }}
          options={locales}
          isSearchable={false}
          onChange={this.handleLocaleChange}
          forceValue
          forceOnChange
          size={'sm'}
          data-tid={'Locale switcher'}
        />
      </Div>
    )
  }
}

export default LocaleSwitcher
