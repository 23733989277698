// React
import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'

// Libraries
import _compact from 'lodash/compact'
import _uniq from 'lodash/uniq'
import initAlignPlugin from './plugins/alignment'
import initLinkValidatePlugin from './plugins/linkValidation'

// Components
import RedactorX from 'shared/lib/redactorx'

// Shared

/**
 * Rich WYSIWYG content editor
 *
 * @example
 *   <Editor field={field} />
 */
export default class ContentEditor extends Component {
  static displayName = 'ContentEditor'

  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func
    }),
    autoFocus: PropTypes.bool,
    image: PropTypes.bool,
    inlineControls: PropTypes.bool,
    table: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object
    ]),
    embed: PropTypes.bool,
    value: PropTypes.string,
    plugins: PropTypes.array,
    onInitialize: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.editor = createRef()
  }

  get addBar () {
    const { embed } = this.props

    let image = this.props.image

    let table = this.props.table

    if (image === undefined) image = true
    if (table === undefined) table = true

    return _compact([
      'paragraph',
      image && 'image',
      table && 'table',
      embed && 'embed',
      'line'
    ])
  }

  get editorProps () {
    const {
      autoFocus,
      inlineControls,
      image,
      table,
      plugins
    } = this.props

    const controls = inlineControls
      ? { control: true, context: true, toolbar: false }
      : {}

    const editorProps = {
      source: false,
      format: ['p', 'h1', 'h2', 'h3', 'ul', 'ol'],
      image: {
        upload: false,
        url: image === undefined ? true : image
      },
      buttons: {
        addbar: this.addBar,
        context: ['bold', 'italic', 'mark', 'link']
      },
      subscribe: {
        'editor.keyup': this.handleChange,
        'editor.paste': this.handleChange,
        'editor.cut': this.handleChange,
        'link.add': this.handleChange,
        'link.remove': this.handleChange,
        'block.add': this.handleChange,
        'block.remove': this.handleChange,
        'block.format': this.handleChange,
        'inline.format': this.handleChange
      },
      plugins: ['linkValidate'],
      link: {
        size: 500
      },
      ...controls
    }

    if (table) {
      editorProps.table = table
    }

    if (autoFocus) {
      editorProps.editor = {
        focus: true
      }
    }

    if (plugins) {
      editorProps.plugins = _uniq([...editorProps.plugins, ...plugins])
    }

    return editorProps
  }

  componentDidMount = () => {
    initAlignPlugin()
    initLinkValidatePlugin()

    this.handleInitializeEditor()
  }

  componentWillUnmount = () => {
    this.app.stop()
  }

  handleInitializeEditor = () => {
    const { onInitialize } = this.props

    this.app = RedactorX(this.editor.current, this.editorProps)

    onInitialize?.(this.app)
  }

  handleChange = () => {
    const { field } = this.props
    const value = this.app.editor.getContent()

    field?.onChange(value)
  }

  render () {
    const { field } = this.props

    return (
      <textarea defaultValue={field.value || ''} ref={this.editor} />
    )
  }
}
