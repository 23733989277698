// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import ReactSelect, { createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { withTheme } from 'styled-components'
import _find from 'lodash/find'
import _merge from 'lodash/merge'
import { withTranslation } from 'shared/utils/withTranslation'

// Components
import BReactSelect from 'ui/blocks/ReactSelect'
import BSelect from 'ui/blocks/Select'
import Icon from 'ui/components/Icon'

// Shared
import { size, color, isDark } from 'ui/themes/utils'

/**
 * Wrapper for react-select applying styling and passing down props
 *
 * @example
 *   <Select field={field} options={{ value, label }} error />
 */

const getLabelColor = (state, props) => {
  if (state.isDisabled) return `${color('light')} !important`

  if (state.isSelected) {
    const fontColor = isDark(color('Primary/Base')(props)) ? 'Text/Inverted' : 'Text/Primary'

    return `${color(fontColor)(props)} !important`
  }

  return `${color('Text/Primary')(props)} !important`
}

const getBackgroundColor = (state, props) => {
  if (state.isSelected) return `${color('Primary/Base')(props)}66 !important`

  if (state.isFocused) return `${color('light')(props)}66 !important`

  return null
}

class Select extends Component {
  static displayName = 'Select'

  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    field: PropTypes.object,
    error: PropTypes.any,
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    usePortal: PropTypes.bool,
    inputRef: PropTypes.any,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    creatable: PropTypes.bool,
    forceValue: PropTypes.bool,
    forceOnChange: PropTypes.bool,
    renderNativeOnMobile: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
    theme: PropTypes.object,
    styles: PropTypes.object,
    'data-tid': PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    t: PropTypes.func
  }

  static defaultProps = {
    size: 'md',
    clearable: false,
    clearableValue: false,
    backspaceRemoves: false,
    placeholder: false,
    renderNativeOnMobile: true
  }

  // We style over here because when we use a portal we can't override styles
  get customStyles () {
    let styles = {
      input: () => ({
        height: `${size('controlHeight')(this.props)} !important`,
        margin: '-1px 0',
        padding: 0
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 10000001,
        fontFamily: this.props.theme.font.body
      }),
      menuList: (base) => ({
        ...base,
        fontSize: 14,
        fontWeight: 400
      }),
      option: (base, state) => ({
        ...base,
        cursor: state.isDisabled ? 'default' : 'pointer',
        color: getLabelColor(state, this.props),
        background: getBackgroundColor(state, this.props)
      })
    }

    if (this.props.styles) {
      styles = _merge(styles, this.props.styles)
    }

    return styles
  }

  get value () {
    if (this.props.forceValue) return this.props.value

    let value = null
    const { options, field } = this.props

    if (!options) return undefined

    if (Array.isArray(field.value)) {
      value = []

      field.value.forEach((v) => {
        value.push(this.optionFromValue(v))
      })
    } else {
      value = this.optionFromValue(field.value)
    }

    return value
  }

  optionFromValue = (value) => {
    const { options } = this.props

    let returnValue

    options.forEach(option => {
      if (option.options) {
        const nestedValue = _find(option.options, ['value', value])

        returnValue = returnValue || nestedValue
      } else if (option.value === value) {
        returnValue = option
      } else if (value && !returnValue) {
        returnValue = { value, label: value }
      }
    })

    return returnValue
  }

  isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
  }

  handleChange = (e) => {
    let value

    if (e && e.target) {
      value = e.target.value
    } else if (e) {
      if (Array.isArray(e)) {
        value = e.map((v) => v.value)
      } else {
        value = e.value
      }
    } else {
      value = null
    }

    if (this.props.onChange && this.props.forceOnChange) return this.props.onChange(value)

    // setTimeout to be on the next render tick
    // This is needed to fix an issue on mobile devices (even when renderNativeOnMobile=false)
    // The validation error would always show up after selecting an option
    setTimeout(() => {
      this.props.field.onChange(value)
    }, 0)
  }

  renderMobileSelect = () => {
    const { field, disabled, options, size, error, placeholder } = this.props
    const showBlank = !(field?.value?.length)
    const translatedPlaceholder = placeholder || this.props.t('common.select_placeholder')
    const tid = this.props['data-tid']

    return (
      <BSelect data-tid={tid} isMobile>
        <BSelect.Select size={size} value={field?.value} onChange={this.handleChange} disabled={disabled} error={error}>
          {showBlank && <option disabled selected value="">{translatedPlaceholder}</option>}
          {options && options.map((option) => <option key={option.value} value={option.value}>
            {option.label}
          </option>)}
        </BSelect.Select>
        <Icon icon="angle-down" />
      </BSelect>
    )
  }

  scrollSelectedOptionIntoView = () => {
    setTimeout(() => {
      const selectedOption = document.querySelector('.Select__option--is-selected')

      if (selectedOption) {
        const selectList = selectedOption.parentElement

        selectList.scrollTop = selectedOption.offsetTop
      }
    }, 0)
  }

  render () {
    if (this.isMobile() && this.props.renderNativeOnMobile) {
      return this.renderMobileSelect()
    }

    const {
      size,
      label,
      field,
      options,
      error,
      valid,
      inputRef,
      usePortal,
      disabled,
      creatable,
      isMulti,
      isSearchable,
      placeholder,
      t,
      ...otherProps
    } = this.props

    const Component = creatable ? CreatableSelect : ReactSelect
    const translatedPlaceholder = placeholder || t('common.select_placeholder')
    const tid = this.props['data-tid']

    return (
      <BSelect isMulti={isMulti} data-tid={tid}>
        <BReactSelect size={size} error={!disabled && error} valid={valid} isMulti={isMulti}>
          <Component
            options={options}
            {...field}
            {...otherProps}
            isMulti={isMulti}
            value={this.value || ''}
            classNamePrefix="Select"
            ref={(ref) => {
              this.ref = ref
              inputRef?.(ref)
            }}
            onChange={this.handleChange}
            styles={this.customStyles}
            menuPortalTarget={usePortal ? document.body : null}
            menuPosition="absolute"
            isDisabled={disabled}
            isSearchable={isSearchable !== undefined ? isSearchable : !this.isMobile()}
            placeholder={translatedPlaceholder}
            filterOption={createFilter({ stringify: option => option.label })}
            onMenuOpen={this.scrollSelectedOptionIntoView}
          />
        </BReactSelect>
      </BSelect>
    )
  }
}

export default withTranslation()(withTheme(Select))
