import styled, { css } from 'styled-components'

import Div from 'ui/elements/Div'

export const Handwriting = styled(Div)`   
  font-family: ${(props) => props.theme.font.handwriting};
  
  z-index: 27;
  position: absolute;
  
  ${props => props.width && css`
    width: ${props.width}px; 
  `}
  
  ${props => props.top && css`
    top: ${props.top}px;
  `}
  
  ${props => props.bottom && css`
    bottom: ${props.bottom}px;
  `}
  
  ${props => props.left && css`
    left: ${props.left}px;
  `}
  
  ${props => props.right && css`
    right: ${props.right}px;
  `}
  
  ${props => props.rotateCw && css`
    transform: rotate(${props.rotateCw}deg);
  `}
  
  ${props => props.rotateCcw && css`
    transform: rotate(-${props.rotateCcw}deg);
  `}
`
