import styled, { css } from 'styled-components'
import { color, size, fontSize } from 'ui/themes/utils'

import LabelElement from 'ui/elements/Label'
import DescriptionElement from 'ui/elements/Description'
import A from 'ui/elements/A'
import Div from 'ui/elements/Div'
import LinkElement from 'ui/blocks/Link'

import { Col } from 'ui/components/Grid'

export const InputField = styled(Col)`
  position: relative;
  margin-bottom: ${size('margin')};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ display }) => display && css`
    display: ${display};
  `}

  ${({ 'data-force-margin': forceMargin }) => forceMargin && css`
    margin-bottom: ${size('margin')} !important;
  `}

  ${({ 'data-no-margin': noMargin }) => noMargin && css`
    margin-bottom: 0;
  `}
`

export const Error = styled(Div)`
  margin-top: ${size('margin', 'sm')};

  font-size: ${fontSize()};

  color: ${color('Danger/Base')};
`

export const Valid = styled.span`
  position: absolute;
  bottom: 0;
  right: 0px;
  height: ${size('controlHeight')};
  width: ${size('controlHeight')};
  line-height: ${size('controlHeight')};
  text-align: center;

  color: ${color('Success/Base')};

  ${props => props.type === 'select' && css`
    display: none;
  `}
`

export const Label = styled(LabelElement)`
  height: 1.3em;
  width: auto;
  margin-bottom: ${size('margin', 'xs')};

  ${props => props.descriptionTop && css`
    margin-bottom: ${size('margin', 'xxs')};
  `}
`

export const Link = styled(LinkElement)`
  height: 1.3em;
  width: auto;
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${A} {
    margin-bottom: ${size('margin', 'xs')};
  }
`

export const Description = styled(DescriptionElement)`
  margin: 0.35em 0 0 0;

  ${props => props.descriptionTop && css`
    margin: 0 0 ${size('margin', 'xs')} 0;
  `}
`
