// React
import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

// Components
import BOption from 'ui/blocks/MultiSelectInput'
import Icon from 'ui/components/Icon'

// Shared
import { isEmail } from 'shared/utils/email'

const OptionComponent = (props) => {
  const isInvalid = useMemo(() => !isEmail(props.data.value.trim()), [props.data])

  return <BOption invalid={isInvalid}>{props.children}</BOption>
}

OptionComponent.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
}

export const Option = memo(OptionComponent)

const LabelComponent = (props) => <BOption.Label>{props.data.label}</BOption.Label>

LabelComponent.propTypes = {
  data: PropTypes.object.isRequired
}

export const Label = memo(LabelComponent)

const RemoveComponent = (props) => {
  const { onMouseDown, onClick, onTouchEnd } = props.innerProps

  return (
    <BOption.Remove
      onClick={onClick}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
      data-tid={`Remove ${props.data.label}`}
    >
      <Icon icon="times" />
    </BOption.Remove>
  )
}

RemoveComponent.propTypes = {
  innerProps: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export const Remove = memo(RemoveComponent)
