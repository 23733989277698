// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import omit from 'lodash/omit'
import accounting from 'accounting'

// Components
import BInput from 'ui/blocks/Input'

// Shared
import FormatUtils from 'shared/utils/format'

/**
 * Input for sums of currency
 *
 * @example
 *   <PriceInput field={field} cents />
 */
export default class PriceInput extends Component {
  static displayName = 'PriceInput'

  static propTypes = {
    id: PropTypes.string,
    currency: PropTypes.string,
    cents: PropTypes.bool,
    hidePrefix: PropTypes.bool,
    field: PropTypes.object.isRequired,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    className: PropTypes.string,
    error: PropTypes.any,
    autoFocus: PropTypes.bool,
    inputRef: PropTypes.func,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    size: 'md'
  }

  constructor (props) {
    super(props)

    this.state = {
      displayValue: undefined
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.field.value != null && this.props.field.value !== prevProps.field.value) {
      if (this.props.cents) {
        if (this.props.field.value !== FormatUtils.parsePriceToCents(this.state.displayValue)) {
          this.setState({ displayValue: FormatUtils.money(this.props.field.value, { format: '%v' }) })
        }
      } else {
        this.setState({ displayValue: this.props.field.value })
      }
    }
  }

  handleChange = (event) => {
    const { cents } = this.props
    const value = event.target.value
    const onChange = this.props.field.onChange

    if (isEmpty(trim(value))) {
      // Value is empty, return 0
      onChange(0)
      this.setState({ displayValue: '' })
    } else if (cents) {
      const valueInCents = FormatUtils.parsePriceToCents(value)

      onChange(valueInCents)
      this.setState({ displayValue: value })
    } else {
      onChange(value)
      this.setState({ displayValue: value })
    }
  }

  renderValue = (value) => {
    const { cents } = this.props
    const { displayValue } = this.state

    if (value === null || value === undefined) {
      return ''
    }

    if (displayValue !== null && displayValue !== undefined) {
      return displayValue
    }

    if (cents) {
      return FormatUtils.money(value, { symbol: '' })
    } else {
      return value
    }
  }

  render () {
    const { hidePrefix, size, error, id, className, inputRef, disabled, ...otherProps } = this.props
    const { name, ...fieldProps } = this.props.field
    const value = this.renderValue(this.props.field.value)
    const currency = this.props.currency || accounting.settings.currency.symbol

    // Omit autofocus as its' handled in mount
    const props = omit(otherProps, ['autoFocus'])

    return (
      <BInput size={size}>
        {currency && !hidePrefix &&
          <BInput.I size={size}>{currency}</BInput.I>
        }
        <BInput.Input
          id={id}
          ref={(ref) => {
            this.ref = ref
            inputRef && inputRef(ref)
          }}
          {...fieldProps}
          name={name}
          type="text"
          onChange={this.handleChange}
          value={value}
          size={size}
          error={error}
          className={className}
          disabled={disabled}
          {...props}
        />
      </BInput>
    )
  }
}
