// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BDuration from 'ui/blocks/Duration'
import Select from 'ui/components/Select'
import QuantityInput from 'ui/components/QuantityInput'
import Disable from 'ui/blocks/Disable'

// Shared
import Period from 'shared/utils/period'
import { withTranslation } from 'shared/utils/withTranslation'

/**
 * Renders a duration field
 *
 * @example
 *   <Duration field={field} options={{ value, label }} error />
 */

class Duration extends Component {
  static displayName = 'Duration'

  static propTypes = {
    name: PropTypes.string,
    field: PropTypes.object,
    error: PropTypes.any,
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    t: PropTypes.func.isRequired,
    size: PropTypes.string,
    allowZero: PropTypes.bool,
    min: PropTypes.number
  }

  static defaultProps = {
    size: 'md',
    options: [
      'hours',
      'days',
      'weeks',
      'months',
      'years'
    ]
  }

  get selectOptions () {
    const { t, options } = this.props

    return options.map((option) => {
      return {
        value: option,
        label: t(`common.periods.${option}`)
      }
    })
  }

  constructor (props) {
    super(props)

    const value = props.field.value

    if (value !== undefined) {
      const period = Period.fromSeconds(value, props.options)

      this.state = {
        period: (period.value === 0) ? 'hours' : period.period,
        quantity: period.value
      }
    } else {
      this.state = {
        quantity: 1,
        period: 'days',
        value: 86400
      }
      this.props.field.onChange(86400)
    }
  }

  componentDidUpdate = (prevProps) => {
    const value = this.props.field.value

    if (value !== prevProps.field.value && value !== this.state.value) {
      const period = Period.fromSeconds(value)

      this.setState({
        period: (period.value === 0) ? 'hours' : period.period,
        quantity: period.value
      })
    }
  }

  handleChangeQuantity = (quantity) => {
    const value = Period.fromPeriod(quantity, this.state.period)

    this.setState({ value, quantity })
    this.props.field.onChange(value)
  }

  handleChangePeriod = (period) => {
    const value = Period.fromPeriod(this.state.quantity, period)

    this.setState({ value, period })
    this.props.field.onChange(value)
  }

  render () {
    const { field, min, size, error, valid, disabled, allowZero, ...otherProps } = this.props
    const { quantity, period } = this.state

    let minimum

    if (min === 0) {
      minimum = 0
    } else {
      minimum = min || (allowZero ? 0 : 1)
    }

    return (
      <Disable disabled={disabled}>
        <BDuration.Fields {...otherProps}>
          <QuantityInput
            min={minimum}
            error={error}
            valid={valid}
            size={size}
            data-tid={`${field.name} quantity`}
            field={{
              onChange: this.handleChangeQuantity,
              value: quantity
            }}
          />

          <Select
            options={this.selectOptions}
            error={error}
            valid={valid}
            size={size}
            data-tid={`${field.name} period`}
            field={{
              onChange: this.handleChangePeriod,
              value: period
            }}
          />
        </BDuration.Fields>
      </Disable>
    )
  }
}

export default withTranslation('common')(Duration)
