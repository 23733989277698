import {
  any,
  bool,
  func,
  number,
  array,
  shape,
  string,
  object,
  oneOfType
} from 'prop-types'
import promisePropType from 'shared/prop_types/promise'

import _mapValues from 'lodash/mapValues'

// For formik 1.5.1
// Comments come from:
// https://jaredpalmer.com/formik/docs/api/formik#formik-render-methods-and-props

/**
 * Static formik PropTypes
 */
const formikStandardPropTypes = {
  // boolean
  dirty: bool.isRequired,

  // (e: any) => void
  handleBlur: func.isRequired,

  // (e: React.ChangeEvent<any>) => void
  handleChange: func.isRequired,

  // () => void
  handleReset: func.isRequired,

  // (e: React.FormEvent<HTMLFormEvent>) => void
  handleSubmit: func.isRequired,

  // boolean
  isSubmitting: bool.isRequired,

  // boolean
  isValid: bool.isRequired,

  // boolean
  isValidating: bool.isRequired,

  // undocumented
  registerField: func.isRequired,

  // (nextValues?: Values) => void
  resetForm: func.isRequired,

  // (fields: { [field: string]: string }) => void
  setErrors: func.isRequired,

  // (field: string, errorMsg: string) => void
  setFieldError: func.isRequired,

  // (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
  setFieldTouched: func.isRequired,

  // undocumented
  setFormikState: func.isRequired,

  // () => void
  submitForm: func.isRequired,

  // number
  submitCount: number,

  // (field: string, value: any, shouldValidate?: boolean) => void
  setFieldValue: func.isRequired,

  // (status?: any) => void
  setStatus: func.isRequired,

  // (isSubmitting: boolean) => void
  setSubmitting: func.isRequired,

  // (fields: { [field: string]: boolean }) => void
  setTouched: func.isRequired,

  // (fields: { [field: string]: any }) => void
  setValues: func.isRequired,

  // any
  status: any,

  // (values?: any) => Promise<FormikErrors<Values>>
  validateForm: func.isRequired,

  // (field: string) => void
  validateField: func.isRequired,

  // component
  // render
  // children

  // Values
  initialValues: object.isRequired,

  // (values: Values) => FormikErrors<Values> | Promise<any>
  validate: func,

  // boolean
  validateOnBlur: bool,

  // boolean
  validateOnChange: bool,

  // Schema | (() => Schema)
  validationSchema: oneOfType([object, func])
}

/**
 * PropTypes that contain field data, will be generated automatically
 */
const generateFormikFieldPropTypes = (fields) => ({
  // { [field: string]: string }
  errors: shape(_mapValues(fields, (f) => oneOfType([string, array]))).isRequired,

  // { [field: string]: boolean }
  touched: shape(_mapValues(fields, (f) => oneOfType([bool, array]))).isRequired,

  // values: see generateFormikFieldPropTypes(),
  values: shape(_mapValues(fields, (f) => any)).isRequired
})

/**
 * PropTypes that are specific to shared/form
 */
const booqablePropTypes = {
  submitPromise: promisePropType
}

export const buildFormPropTypes = (fieldPropTypes) => {
  const propTypes = {
    ...formikStandardPropTypes,
    ...generateFormikFieldPropTypes(fieldPropTypes),
    ...booqablePropTypes
  }

  return propTypes
}
