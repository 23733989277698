import { UrlSelect, Value, Menu, MenuHeader, LoaderContainer, Option, OptionCell } from './styles'

UrlSelect.Value = Value
UrlSelect.Menu = Menu
UrlSelect.MenuHeader = MenuHeader
UrlSelect.LoaderContainer = LoaderContainer
UrlSelect.Option = Option
UrlSelect.OptionCell = OptionCell

export default UrlSelect
