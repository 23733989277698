// React
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'
import Yup from 'shared/utils/yup'

// Components
import Layout from 'signup/components/Layout'
import Button from 'ui/components/Button'
import Div from 'ui/elements/Div'
import Select from 'signup/components/Select'

// Shared
import withForm, { Form } from 'shared/form'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import SignupActions from 'signup/redux/actions/signup'
import { personaDescriptionData, valuePriorityData } from './static/marketingData'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'

export class Step6 extends React.Component {
  static displayName = 'Step6'
  static propTypes = {
    t: PropTypes.func.isRequired,
    values: PropTypes.shape({
      persona_description: PropTypes.string,
      value_priority: PropTypes.string
    }),
    submitPromise: PropTypes.instanceOf(Promise)
  }

  componentDidMount = () => {
    EventTracker.page()
    EventTracker.track('Signup: Step 6')
  }

  render = () => {
    const { submitPromise, t } = this.props

    return (
      <Layout step={6} stepIndicator={t('signup.step_6.step_indicator')}>
        <Form>
          <Select
            identifier="persona_description"
            options={personaDescriptionData(t)}
          />

          <Select
            identifier="value_priority"
            options={valuePriorityData(t)}
          />

          <Div modifiers={'margin-top-lg margin-top-md-xl'}>
            <Button
              size="lg"
              type="submit"
              promise={submitPromise}
              block
              borderRadius={'mdplus'}
              modifiers={'margin-bottom-xl'}
            >
              {t('signup.continue')}
            </Button>
          </Div>
        </Form>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      persona_description: state.signup.persona_description || '',
      value_priority: state.signup.value_priority || ''
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (values) => {
      return SignupActions.update(window.id, values)
    },
    validationSchema: Yup.object().shape({
      persona_description: Yup
        .string()
        .required(),
      value_priority: Yup
        .string()
        .required()
    }),
    serverSuccess: () => {
      RoutingUtils.navigate(`/${window.locale}/step-7`)
    }
  }
}

export default compose(
  withTranslation('signup'),
  reduxPureConnect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Step6)
