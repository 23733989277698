import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment, { localMoment, localMomentInUTC } from 'shared/lib/moment'

import BDayPicker from 'ui/blocks/DayPicker'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'
import { ModifiersUtils } from "react-day-picker";


import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isDate from 'lodash/isDate'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

import Navigation from './Navigation'

const dayMatchingPropTypes = PropTypes.oneOfType([
  PropTypes.shape({ before: PropTypes.instanceOf(Date), after: PropTypes.instanceOf(Date) }),
  PropTypes.shape({ from: PropTypes.instanceOf(Date), to: PropTypes.instanceOf(Date) }),
  PropTypes.shape({ daysOfWeek: PropTypes.array }),
  PropTypes.func,
  // Can be an array of previously listed shapes
  PropTypes.array
])

// Get and set locale
let locale = 'en'

if (navigator.languages !== undefined) {
  locale = navigator.languages[0]
} else {
  locale = navigator.language
}

let firstDayOfWeek = 1

import('moment/locale/' + locale).then(() => {
  moment.locale(locale)
  firstDayOfWeek = moment.localeData(locale).firstDayOfWeek()

  if (window.booqableOptions) {
    window.booqableOptions.locale = locale.split('-')[0]
    window.booqableOptions.firstDayOfWeek = firstDayOfWeek
  }
}).catch(() => {
  // Locale not found, try to set the firstdayofweek anyways
  firstDayOfWeek = moment().locale(navigator.language).weekday(0).day()
})

export default class DayPicker extends Component {
  constructor (props) {
    super(props)

    this.state = {
      from: props.from || null,
      to: props.to || null,
      currentSelection: null,
      hover: null,
      currentMonth: null
    }
  }

  static displayName = 'DayPicker'
  static propTypes = {
    // Data
    /** From date */
    from: PropTypes.instanceOf(Date),
    /** To date */
    to: PropTypes.instanceOf(Date),
    currentMonth: PropTypes.instanceOf(Date),
    // Configuration
    /** Selector for blockedDays. See: http://react-day-picker.js.org/docs/matching-days */
    blockedDays: dayMatchingPropTypes,
    /** Apply custom modifiers to the daypicker. See: http://react-day-picker.js.org/docs/matching-days */
    modifiers: dayMatchingPropTypes,
    /** Component shouldn't focus "To" after "From" is clicked */
    cancelAutofocus: PropTypes.bool,
    /** Overrides default selection rendering function */
    firstDayOfWeek: PropTypes.number,
    /** Sets first day of week */
    translate: PropTypes.bool,
    /** Control current pulse selection from outside */
    currentSelection: PropTypes.string,
    /** If true, daypicker will use localized version if available */
    /**
     * @param {object} state Component state
     */
    selection: PropTypes.func,
    // Callbacks
    onChange: PropTypes.func,
    /** Exposes focusFrom and focusTo functions */
    onRef: PropTypes.func,
    forceUTC: PropTypes.bool
  }

  static defaultProps = {
    currentMonth: new Date(),
    cancelAutofocus: false,
    firstDayOfWeek: 1,
    forceUTC: true
  }

  componentDidMount = () => {
    const { focusFrom, focusTo } = this

    // Expose functions to onRef
    // Usage:
    // onRef={(ref) => (this.something = ref)}
    // this.something.focusFrom()
    this.props.onRef && this.props.onRef({ focusFrom, focusTo })

    // Expose clearDayStateCache()
    this.props.clearCacheRef && this.props.clearCacheRef(this.clearDayStateCache)
  }

  componentWillUnmount = () => {
    this.props.onRef && this.props.onRef(null)
  }

  // Update values if they've been changed from outside
  componentDidUpdate = (prevProps) => {
    const { from, to } = this.props
    const newState = {}

    if (from !== prevProps.from) {
      newState.from = from
    }

    if (to !== prevProps.to) {
      newState.to = to
    }

    // Only update state if something's actually changed
    if (newState.to !== undefined || newState.from !== undefined) {
      this.setState(newState)
    }
  }

  // Externaly available via onRef (see componentDidMount)
  /**
   * Set "From" as the active selection
  */
  focusFrom = () => {
    this.setState({ currentSelection: 'from' }, () => {
      this.ref.showMonth(this.state.from)
    })
  }

  /**
   * Set "To" as the active selection
  */
  focusTo = () => {
    this.setState({ currentSelection: 'to' }, () => {
      this.ref.showMonth(this.state.to)
    })
  }

  clearDayStateCache = () => {
    this.dayStateCache = {}
  }

  // Value flow
  /**
   *  Handles setting new value, switching focus between "From" (initial) and "To"
   *  as well as initiates calling onChange
   */
  handleDayClick = (day) => {
    const newState = {}
    const currentSelection = this.state.currentSelection || 'from'
    const { cancelAutofocus, forceUTC } = this.props

    // Make sure we use the correct time for new date
    const currentDate = this.state[currentSelection] || new Date()
    const currentTime = moment(currentDate).format('HH:mm')
    const newDate = forceUTC ? localMomentInUTC(day).format('YYYY-MM-DD') : moment(day).format('YYYY-MM-DD')

    day = moment(`${newDate} ${currentTime}`)

    newState[currentSelection] = day.toDate()

    // If current selection is From swap to To
    if (currentSelection === 'from') {
      if (!cancelAutofocus) {
        newState.currentSelection = 'to'
      }

      // Swap the currentMonth to To if To has a value
      if (this.state.to) {
        const parsedTo = moment(this.state.to)

        // Do not swap if difference in months is < 1
        if (parsedTo.diff(day, 'months', true) > 1) {
          newState.currentMonth = moment(this.state.to).subtract(1, 'month').toDate()
        }
      }

      // If From is now after To, Bump up To to the same date and at least 1 hour later
      if (day >= this.state.to) {
        const hours = moment(this.state.to).hour()
        const minutes = moment(this.state.to).minutes()

        // Try to set original to hour and minutes
        const newToOriginalTime = day.clone().hour(hours).minutes(minutes).toDate()
        const newToWithFromTime = day.clone().toDate()

        if (newToOriginalTime.getTime() > newToWithFromTime.getTime()) {
          newState.to = newToOriginalTime
        } else {
          newState.to = newToWithFromTime
        }
      }
    } else {
      if (!cancelAutofocus) {
        newState.currentSelection = 'from'
        newState.hover = null
      }
      // If To is now before From, Pull From back to the same date and at least 1 hour earlier
      if (day <= this.state.from) {
        const hours = moment(this.state.from).hour()
        const minutes = moment(this.state.from).minutes()

        // Try to set original to hour and minutes
        const newFromWithOriginalTime = day.clone().hour(hours).minutes(minutes).toDate()
        const newFromWithToTime = day.clone().subtract(1, 'hour').toDate()

        if (newFromWithOriginalTime.getTime() > newFromWithToTime.getTime()) {
          newState.from = newFromWithOriginalTime
        } else {
          newState.from = newFromWithToTime
        }
      }
    }

    this.setState(newState, this.handleChange)
  }

  handleChange = () => {
    const { from, to } = this.state

    if (from && to) {
      this.props.onChange && this.props.onChange({ from, to })
    }
  }

  // Presentation logic
  handleMouseEnter = (day) => {
    this.setState({ hover: day })
  }

  handleMouseLeave = (day) => {
    const { hover } = this.state
    const hoverDate = hover && hover.getTime()
    const leaveDate = day && day.getTime()

    if (hoverDate === leaveDate) {
      this.setState({ hover: null })
    }
  }

  /**
   * Get selection range to display
   */
  selectionToRange = (state) => {
    const { currentSelection, hover } = state
    let from = new Date(state.from && state.from.getTime())
    let to = new Date(state.to && state.to.getTime())

    if (hover === null || !from) {
      return {}
    }

    // Make sure we ignore times for calculations
    if (from) {
      // Add one hour for daylight savings
      from = moment(from).startOf('day').add(1, 'hour').toDate()
    }

    if (to) {
      to = moment(to).endOf('day').toDate()
    }

    if ((this.props.currentSelection || currentSelection || 'from') === 'from') {
      // We are outside the to range
      if (hover > to) {
        return {}
      }

      return {
        from: hover,
        to: to
      }
    } else {
      return {
        to: hover,
        from: from
      }
    }
  }

  /**
   * Offset modifiers if forceUTC is set. This properly offsets modifiers set in UTC to the local timezone.
   */
  offsetModifiers = (modifiers) => {
    if (!this.props.forceUTC) return modifiers

    if (isFunction(modifiers)) {
      return (date) => {
        return modifiers(localMomentInUTC(date))
      }
    } else if (isDate(modifiers)) {
      return localMoment(modifiers, true).toDate()
    } else if (isArray(modifiers)) {
      return map(modifiers, (value) => {
        return this.offsetModifiers(value)
      })
    } else if (isObject(modifiers)) {
      return mapValues(modifiers, (value) => {
        return this.offsetModifiers(value)
      })
    } else {
      return modifiers
    }
  }

  /**
   * Day open/closed calculation is slow and needs to be run against every day we want to render so we need to cache it
   *
   * Takes disableDays (function for calculating day state), pases it to this.offsetModifiers which should return a function or array of functions
   * then execute that with the date passed in from the picker, cache the result and return
   *
   * Returns boolean
   */
  isDayDisabled = ({ disabledDays, modififierFrom }, date) => {
    const currentSelection = this.state.currentSelection || this.props.currentSelection

    let cacheKey

    this.dayStateCache = this.dayStateCache || {}

    if (currentSelection === 'from') {
      // From selection being open isn't dependent on value
      // we can cache just based on date
      cacheKey = date.getTime()
    } else {
      // 'to' selection needs adjusted 'from' to
      // block off days that are before 'from'
      // so cache key involves 'from' value
      cacheKey = `${modififierFrom.getTime()}-${date.getTime()}`
    }

    if (this.dayStateCache[cacheKey]) {
      // Cache hit
      return this.dayStateCache[cacheKey]
    } else {
      // Execute open/close check
      let modifiers = this.offsetModifiers(disabledDays)

      const state = ModifiersUtils.dayMatchesModifier(date, modifiers)

      // Cache
      this.dayStateCache[cacheKey] = state

      return state
    }
  }

  render () {
    const { currentSelection, from, to } = this.state
    const { currentMonth, modifiers, blockedDays, selection, translate, forceUTC, withAvailability, ...otherProps } = this.props

    const selectionToRange = selection || this.selectionToRange

    const selectionRange = selectionToRange(this.state)

    const selectionStart = selectionRange?.from
    const selectionEnd = selectionRange?.to

    // Add 1 hour for daylight savings
    const modififierFrom = moment(from).startOf('day').add(1, 'hour').toDate()

    const renderModifiers = {
      from: modififierFrom,
      to: to,
      selection: selectionRange,
      selected: { from: modififierFrom, to },
      selectionStart,
      selectionEnd,
      past: { before: new Date() },
      ...modifiers
    }

    // New selection starts on previous to date,
    // with a period selected before that date
    //
    // We use this to set the selected background on this element
    if (moment(selectionStart).isSame(moment(to), 'day') && from < selectionStart) {
      renderModifiers.selectionStartWithPeviousPeriod = selectionStart
    }

    // Only pulse when we have a to date
    if (to) {
      let focusedDay = this.state[this.props.currentSelection || currentSelection || 'from']
      if (focusedDay) {
        // Add 1 hour for daylight savings
        focusedDay = moment(focusedDay).startOf('day').add(1, 'hour').toDate()
      }
      renderModifiers.focused = focusedDay
    }

    let disabledDays = [].concat(blockedDays)

    if (currentSelection === 'to') {
      disabledDays = disabledDays.concat([{ before: modififierFrom }])
    }

    // Get first day of week from prop or infer from locale
    firstDayOfWeek = this.props.firstDayOfWeek != null
      ? parseInt(this.props.firstDayOfWeek)
      : parseInt((translate ? firstDayOfWeek : '1'))

    // Which calendar to render
    const Component = withAvailability ? BDayPicker.AvailabilityPicker : BDayPicker.SimpleDayPicker

    return (
      <Component
        navbarElement={Navigation}
        modifiers={this.offsetModifiers(renderModifiers)}
        disabledDays={this.isDayDisabled.bind(null, { disabledDays, modififierFrom })}
        onDayClick={this.handleDayClick}
        month={this.state.currentMonth || currentMonth}
        {...otherProps}
        onDayMouseEnter={(day) => {
          this.handleMouseEnter && this.handleMouseEnter(day)
          this.props.handleMouseEnter && this.props.handleMouseEnter(day)
        }}
        onDayMouseLeave={(day) => {
          this.handleMouseLeave && this.handleMouseLeave(day)
          this.props.handleMouseLeave && this.props.handleMouseLeave(day)
        }}
        ref={(ref) => (this.ref = ref)}
        localeUtils={MomentLocaleUtils}
        locale={window.locale}
        firstDayOfWeek={firstDayOfWeek}
        showOutsideDays
        fixedWeeks
        tabIndex={-1}
      />
    )
  }
}
