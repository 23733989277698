// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'
import Yup from 'shared/utils/yup'

// Components
import Layout from 'signup/components/Layout'
import Button from 'ui/components/Button'
import { Row, Col } from 'ui/components/Grid'
import Div from 'ui/elements/Div'
import Select from 'signup/components/Select'

// Shared
import withForm, { Form, Field } from 'shared/form'
import SignupActions from 'signup/redux/actions/signup'
import countries from 'shared/constants/countries'
import { selectOptions } from 'shared/utils/selectOptions'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'
import { roleData } from './static/marketingData'

class Step2 extends Component {
  static displayName = 'Step2'

  static propTypes = {
    // Data
    t: PropTypes.func.isRequired,
    values: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      country: PropTypes.string,
      role: PropTypes.string
    }),
    // Dispatch
    submitPromise: PropTypes.instanceOf(Promise)
  }

  componentDidMount = () => {
    EventTracker.page()
    EventTracker.track('Signup: Step 2')
  }

  render = () => {
    const { submitPromise, t } = this.props

    return (
      <Layout step={2}>
        <Form>
          <Row>
            <Col xs="6">
              <Field
                name="firstname"
                type="text"
                label={t('signup.fields.firstname.label')}
                placeholder={t('signup.fields.firstname.placeholder')}
                autoComplete="given-name"
                autoFocus
                forceMargin
                showIfValid
              />
            </Col>

            <Col xs="6">
              <Field
                name="lastname"
                type="text"
                label={t('signup.fields.lastname.label')}
                placeholder={t('signup.fields.lastname.placeholder')}
                autoComplete="family-name"
                forceMargin
                showIfValid
              />
            </Col>
          </Row>

          <Select
            identifier="country"
            options={selectOptions(countries)}
          />

          <Select
            identifier="role"
            options={roleData(t)}
          />

          <Div modifiers={'margin-top-lg margin-top-md-xl'}>
            <Button
              id="submit-button"
              size="lg"
              type="submit"
              promise={submitPromise}
              block
              borderRadius={'mdplus'}
              modifiers={'margin-bottom-xl'}
            >
              {t('signup.continue')}
            </Button>
          </Div>
        </Form>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      firstname: state.signup.firstname || '',
      lastname: state.signup.lastname || '',
      country: state.signup.country || '',
      role: state.signup.role || ''
    },
    validateOnBlur: false,
    validateOnChange: false
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    validationSchema: Yup.object().shape({
      firstname: Yup
        .string()
        .required(),
      lastname: Yup
        .string()
        .required(),
      country: Yup
        .string()
        .required()
        .oneOf(countries),
      role: Yup
        .string()
        .required()
    }),
    handleSubmit: (values) => {
      return SignupActions.update(window.id, values)
    },
    serverSuccess: () => {
      RoutingUtils.navigate(`/${window.locale}/step-3`)
    }
  }
}

export default compose(
  withTranslation('signup'),
  reduxPureConnect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Step2)
