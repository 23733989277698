import isEqualWith from 'lodash/isEqualWith'
import Immutable from 'immutable'

const isReact = (value) => {
  return isClassComponent(value) || isFunctionComponent(value) || isReactElement(value)
}

const isClassComponent = (component) => {
  return (
    typeof component === 'function' &&
    !!component.prototype.isReactComponent
  )
}

const isFunctionComponent = (component) => {
  return (
    typeof component === 'function' &&
    String(component).includes('return React.createElement')
  )
}

// Check if element is a react element by checking $$typeof
// https://github.com/facebook/react/blob/65bbda7f169394005252b46a5992ece5a2ffadad/packages/shared/ReactSymbols.js#L14
const isReactElement = (element) => {
  if (!element) return false

  const hasSymbol = typeof Symbol === 'function' && Symbol.for
  const type = hasSymbol ? Symbol.for('react.element') : 0xeac7

  return element.$$typeof === type
}

const isElement = (element) => {
  return element instanceof HTMLElement
}

const pureCompare = (objectA, objectB) => {
  const debug = false

  debug && console.log('----------------------------')

  return isEqualWith(objectA, objectB, (a, b) => {
    // Skip functions
    if (typeof a === 'function' || typeof b === 'function') {
      debug && console.log('  - Skipping function!')

      return true
    }

    // Skip HTML elements
    if (isElement(a) || isElement(b)) {
      debug && console.log('  - Skipping HTML element!')

      return true
    }

    // A react node/component/children are passed
    if ((a && isReact(a)) || (b && isReact(b))) {
      if (a.props || b.props) {
        // React component has props, compare those and return the result
        return pureCompare(a.props, b.props)
      } else {
        debug && console.log('  - Skipping react component!')

        // There are no props to compare, always return false
        return false
      }
    }

    debug && console.log('  - Checking', a, b)

    if (a && a.__iterate) {
      if (a && a.is && b && b.is) {
        debug && console.log('  - Immutable collection result:', a.is(b))

        return a.is(b)
      } else {
        debug && console.log('  - Immutable object result:', Immutable.is(a, b))

        return Immutable.is(a, b)
      }
    }

    if (a && a._isAMomentObject) {
      // Object is a moment
      return a.diff(b) === 0
    }

    debug && console.log('----------------------------')

    return undefined
  })
}

export default pureCompare
