// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BRow from 'ui/elements/Row'
import Anchor from 'ui/components/Anchor'

// Shared

/**
 * Row component.
 *
 * @example
 *   <Row><div></div></Row>
 */

export default class Row extends Component {
  static displayName = 'Row'

  static propTypes = {
    children: PropTypes.node,
    anchor: PropTypes.bool,
    id: PropTypes.string
  }

  render () {
    const { children, anchor, id, ...otherProps } = this.props

    return (
      <BRow className="row" id={id} {...otherProps}>
        {anchor && <Anchor id={id} />}
        {children}
      </BRow>
    )
  }
}
