// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BTextArea from 'ui/blocks/TextArea'

// Shared

/**
 * Input with icon prepending
 *
 * @example
 *   <TextArea field={field} />
 */
export default class Input extends Component {
  static displayName = 'TextArea'

  static propTypes = {
    field: PropTypes.object,
    size: PropTypes.oneOf(['sm', 'md', 'lg'])
  }

  static defaultProps = {
    size: 'md'
  }

  render () {
    const { field, size, ...otherProps } = this.props

    // `value` prop on `textarea` should not be null
    field.value = field.value || ''

    // `noMargin`, `Trans` and `tOrEmptyString` are not valid props on a `textarea` DOM element
    delete otherProps.noMargin
    delete otherProps.Trans
    delete otherProps.tOrEmptyString

    return (
      <BTextArea size={size} {...field} {...otherProps} />
    )
  }
}
