// React
import React, { useEffect, useState, cloneElement, useRef } from 'react'
import PropTypes from 'prop-types'

// Libraries
import { FocusedImage } from 'image-focus'
import _isEmpty from 'lodash/isEmpty'

// Components
import BFocalImage from 'ui/blocks/FocalImage'

const FocalImage = (props) => {
  const [loading, setLoading] = useState(true)

  const ref = useRef()

  useEffect(() => {
    setLoading(true)
    onImageLoad()
  }, [props.coordinates])

  const onImageLoad = () => {
    if (ref.current && ref.current.src) {
      const coordinates = _isEmpty(props.coordinates) ? { x: 0, y: 0 } : props.coordinates

      /* eslint-disable-next-line no-new */
      new FocusedImage(ref.current, {
        focus: coordinates
      })

      setLoading(false)
    }
  }

  return (
    <BFocalImage
      size={props.size}
      width={props.width}
      height={props.height}
      selected={props.selected}
      noBorder={props.noBorder}
      noBorderRadius={props.noBorderRadius}
      maintainAspect={props.maintainAspect}
      onClick={props.onClick}
      data-tid={props.target}
      data-tip={props.tip}
      cursor={props.cursor}
      inline={props.inline}
    >
      {props.children && cloneElement(props.children)}
      {props.preview && loading && <BFocalImage.Preview src={`data:image/jpeg;base64,${props.preview}`} />}
      <BFocalImage.Image isLoading={loading} src={props.url} ref={ref} onLoad={onImageLoad} alt={props.alt} />
    </BFocalImage>
  )
}

FocalImage.displayName = 'FocalImage'

FocalImage.propTypes = {
  coordinates: PropTypes.object,
  url: PropTypes.string,
  selected: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  noBorder: PropTypes.bool,
  noBorderRadius: PropTypes.bool,
  preview: PropTypes.string,
  maintainAspect: PropTypes.bool,
  onClick: PropTypes.func,
  target: PropTypes.string,
  tip: PropTypes.string,
  cursor: PropTypes.string,
  alt: PropTypes.string,
  inline: PropTypes.bool
}

export default FocalImage
