import styled from 'styled-components'
import { size, color } from 'ui/themes/utils'

import EInput from 'ui/elements/Input'
import I from 'ui/elements/I'

export const ColorPicker = styled.div`
  position: relative;

  ${I} {
    position: absolute;
    right: calc(${size('padding')} / 2);
    top: 15px;
    width: calc(${size('controlHeight')} - ${size('padding')});
    height: calc(${size('controlHeight')} - ${size('padding')});
    cursor: pointer;
  }
`

export const Input = styled(EInput)``

export const Preview = styled.div.attrs(props => ({
  style: {
    background: props.value
  }
}))`
  position: absolute;
  right: calc(${size('padding')} / 2);
  top: calc(${size('padding')} / 2);
  width: calc(${size('controlHeight')} - ${size('padding')});
  height: calc(${size('controlHeight')} - ${size('padding')});
  border-radius: 4px;
  border: ${size('borderWidth')} solid ${color('Misc/Divider')};
  cursor: pointer;
`

export const Picker = styled.div`
  max-width: calc(200px + ${size('padding', 'sm')} * 2);

  padding: ${size('padding', 'sm')};
`

export const Palette = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  gap: 3px;
`

export const Color = styled.div`
  width: ${size('controlHeight', 'xs')};
  height: ${size('controlHeight', 'xs')};

  border-radius: ${size('borderRadius', 'xs')};

  cursor: pointer;

  background: ${props => props.value};
`
