// Libraries
import styled, { css } from 'styled-components'
import { size, color } from 'ui/themes/utils'

// Elements
import CheckBox from 'ui/blocks/Checkbox'
import I from 'ui/elements/I'

const CheckBoxLabel = CheckBox.Label

export const CheckboxGroup = styled.div`
  width: 100%;

  ${CheckBox} {
    width: auto;
    display: inline-block;
    margin-bottom: 0;

    ${props => !props.noInline && css`
      &:not(:last-child) {
        margin-bottom: ${size('margin')};
        margin-right: ${size('margin')};

        ${props => props.compact && css`
          margin-bottom: ${size('margin', 'sm')};
          margin-right: ${size('margin', 'sm')};
        `}
      }
    `}

    ${props => props.noInline && css`
      display: block;
      margin-bottom: ${size('margin')};
    `}

    ${props => props.bordered && css`
      position: relative;

      padding: ${size('padding', 'sm')}
              ${size('padding', 'sm')}
              ${size('padding', 'sm')}
              calc(5px + ${size('padding', 'xl')});
      border: 1px ${color('Misc/Divider')} solid !important;
      border-radius: ${size('borderRadius', 'sm')};
      margin-bottom: ${size('margin', 'xs')};

      ${I}:first-child {
        position: absolute;
        left: 36px;

        ${props => props.withOptionDescriptions && css`
          top: 20px;
        `}
      }
    `}
  }

  ${CheckBoxLabel} {
    display: inline-block;

    ${props => props.noInline && css`
      display: block;
    `}
  }
`

export const Checkbox = styled.div`
  position: relative;
`
