import React from 'react'

// Components
import Div from 'ui/elements/Div'
import Link from 'ui/components/Link'
import BLogo from 'signup/blocks/Logo'

// Assets
import logo from '@assets/images/logo.svg'

export default function Logo () {
  let url = 'https://booqable.com'

  if (window.locale !== 'en') {
    url = `https://booqable.com/${window.locale}`
  }

  return (
    <Div modifiers={'float-left'}>
      <Link href={url} modifiers={'display-inline-block v-align-middle'}>
        <BLogo.Image alt="Booqable Logo" src={logo} />
        <BLogo.Title>Booqable</BLogo.Title>
      </Link>
    </Div>
  )
}
