// React
import React from 'react'
import PropTypes from 'prop-types'

// Libraries
import { Transition } from 'react-transition-group'

// Components
import BBar from 'ui/blocks/Bar'

// Shared

/**
 * Render a bar.
 */
const Bar = ({ show, className, type, icon, noIcon, title, description, children, margin, square, ...otherProps }) => {
  const displayIcon = icon || {
    'danger': 'times-circle',
    'warning': 'exclamation-triangle',
    'success': 'check-circle',
    'info': 'info-circle'
  }[type] || 'question-circle'

  return (
    <Transition in={show} timeout={{ enter: 0, exit: 250 }}>
      {(state) => {
        return (
          <BBar state={state} type={type} className={className} margin={margin} square={square} {...otherProps}>
            {!noIcon && <BBar.Icon icon={displayIcon} />}
            <BBar.Container>
              <BBar.Title children={title} />
              <BBar.Description children={description} />
            </BBar.Container>
            {children}
          </BBar>
        )
      }}
    </Transition>
  )
}

Bar.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'danger', 'success']).isRequired,
  title: PropTypes.string,
  noIcon: PropTypes.bool,
  description: PropTypes.node,
  square: PropTypes.string,
  margin: PropTypes.bool
}

export default Bar
