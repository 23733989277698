import { NavigationNext, NavigationPrevious } from './base'
import { StyledDayPicker as AvailabilityPicker } from './availability'
import { StyledDayPicker as SimpleDayPicker } from './simple'

const StyledDayPicker = {}

StyledDayPicker.NavigationNext = NavigationNext
StyledDayPicker.NavigationPrevious = NavigationPrevious
StyledDayPicker.AvailabilityPicker = AvailabilityPicker
StyledDayPicker.SimpleDayPicker = SimpleDayPicker

export default StyledDayPicker
