// React
import React, { Fragment } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'

// Libraries
import moment, { localMomentInUTC } from 'shared/lib/moment'

// Components
import BDatePickerInput from 'ui/blocks/DatePickerInput'
import DayPicker from 'ui/components/DayPicker'
import DateTimeInput from 'ui/components/DateTimeInput'
import Icon from 'ui/components/Icon'
import Popover from 'ui/components/Popover'
import PortalWrapper from 'ui/components/PortalWrapper'

// Shared
import SettingsUtils from 'shared/utils/settings'

/**
 * Renders a nice datetime input with a picker attached
 *
 * @example
 *   <DatePickerInput
 *     {...field}
 *     format="MM-DD-YYYY"
 *   />
 */

export default class DateTimePickerInput extends React.Component {
  static displayName = 'DateTimePickerInput'

  static propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.node
    ]),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    timeOnly: PropTypes.bool,
    translate: PropTypes.bool,
    format: PropTypes.string,
    brandingColor: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    field: PropTypes.object,
    usePortal: PropTypes.bool,
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    isMobile: PropTypes.bool,
    showIcon: PropTypes.bool,
    /** Selector for blockedDays. See: http://react-day-picker.js.org/docs/matching-days */
    blockedDays: PropTypes.any,
    placeholder: PropTypes.string,
    forceUTC: PropTypes.bool,
    inputRef: PropTypes.func,
    // Footer slot to optionally render legend
    footer: PropTypes.node,
    // Callbacks
    onFocus: PropTypes.func
  }

  static defaultProps = {
    field: {},
    direction: 'bottom',
    clearable: true,
    forceUTC: true
  }

  constructor (props) {
    super(props)

    const value = moment(this.props.field.value || '').toJSON()

    this.state = {
      value,
      focused: false,
      valid: false,
      currentMonth: value,
      hover: null,
      popoverOpen: false
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  componentDidUpdate = (prevProps) => {
    const value = moment(this.props.field.value || '').toJSON()

    if (prevProps.field.value !== this.props.field.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value })
    }
  }

  defaultValue = () => {
    let newState = {}

    if (!this.state.value) {
      newState = {
        value: new Date().toJSON()
      }
    }

    return newState
  }

  handleClickOutside = (e) => {
    const domNode = findDOMNode(this)

    if (this.state.focused) {
      if (!domNode || !domNode.contains(e.target)) {
        this.setState({ focused: false })
      }
    }
  }

  handleDayClick = (value, handleClose) => {
    const { field, forceUTC } = this.props

    value = forceUTC ? localMomentInUTC(value).toJSON() : moment(value).toJSON()

    field.onChange && field.onChange(value, true)

    this.setState({ value, focused: false }, () => {
      handleClose && handleClose()
    })

    field.onBlur && field.onBlur({
      target: {
        name: field.name,
        value
      }
    })
  }

  handleChangeInput = (value) => {
    const { field } = this.props

    const newState = {
      value,
      currentMonth: value,
      valid: true
    }

    // Make sure we work with UTC dates
    value = moment(value).toJSON()

    this.setState(newState)
    field.onChange && field.onChange(value)
  }

  handleFocusInput = (e) => {
    // Only focus on second click on mobile devices
    if (this.state.focused) {
      if (this.isMobile()) {
        setTimeout(() => {
          document.activeElement.blur()
          e.target && e.target.blur()
        }, 10)
      }
    }

    // Ensure callback
    this.props.onFocus && this.props.onFocus(e)

    setTimeout(() => this.setState({ focused: true, popoverOpen: true }))
  }

  handleClear = () => {
    const { field } = this.props

    this.setState({
      value: null,
      focused: false,
      currentMonth: ''
    })

    this.setState({ value: null, focused: false })
    field.onChange && field.onChange(null)
    field.onBlur && field.onBlur({
      target: {
        name: field.name
      }
    })
  }

  handlePopoverClose = () => {
    this.setState({ popoverOpen: false })

    this.handleFieldBlur()
  }

  handleFieldBlur = () => {
    const { field } = this.props

    this.setState({ focused: false })

    field.onBlur?.({
      target: {
        name: field.name
      }
    })
  }

  isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
  }

  renderDatePicker = (props) => {
    const {
      brandingColor,
      translate,
      timeOnly,
      blockedDays,
      forceUTC,
      footer,
      ...otherProps
    } = this.props

    if (timeOnly) return null

    const {
      value,
      currentMonth,
      hover
    } = this.state

    const currentMonthAsDate = (currentMonth && moment(currentMonth).toDate()) || new Date()

    return (
      <Fragment>
        <DayPicker
          brandingColor={brandingColor}
          numberOfMonths={1}
          blockedDays={blockedDays}
          from={value && new Date(value)}
          onDayClick={(value) => this.handleDayClick(value, this.handlePopoverClose)}
          onDayTouchEnd={(value, _, e) => { e.preventDefault(); this.handleDayClick(value, this.handlePopoverClose) }}
          onDayMouseEnter={(day) => {
            this.setState({ hover: day })
          }}
          onDayMouseLeave={(day) => {
            if (hover.getTime() === day.getTime()) {
              this.setState({ hover: null })
            }
          }}
          enableOutsideDaysClick={false}
          firstDayOfWeek={SettingsUtils.get('dates.first_day_of_week', 1)}
          month={value ? new Date(value) : currentMonthAsDate}
          translate={translate}
          forceUTC={forceUTC}
          {...otherProps}
        />
        {footer}
      </Fragment>
    )
  }

  renderInput = () => {
    const {
      field,
      size,
      error,
      timeOnly,
      placeholder,
      disabled,
      clearable,
      showIcon,
      inputRef
    } = this.props

    const {
      value,
      focused
    } = this.state

    const format = this.props.format || SettingsUtils.get('dates.format')

    const fieldProps = {
      name: field.name,
      onChange: this.handleChangeInput,
      onFocus: this.handleFocusInput,
      onInvalid: () => { this.setState({ valid: false }) },
      value
    }

    inputRef?.(this.handleFocusInput)

    return (
      <BDatePickerInput size={size}>
        <BDatePickerInput.InputContainer
          focused={focused}
          size={size}
          error={!disabled && error}
          timeOnly={timeOnly}
          showIcon={showIcon}
          disabled={disabled}
        >
          {showIcon && !timeOnly &&
            <Icon icon="calendar" />
          }

          {value && !disabled && clearable &&
            <BDatePickerInput.ClearButton onClick={this.handleClear} size={size}>
              <Icon icon="times" />
            </BDatePickerInput.ClearButton>
          }

          <DateTimeInput
            size={size}
            format={format}
            placeholder={placeholder || format}
            field={fieldProps}
            focused={focused}
            onBlur={this.handleFieldBlur}
            disabled={disabled}
          />
        </BDatePickerInput.InputContainer>
      </BDatePickerInput>
    )
  }

  renderMobile (open) {
    const { label } = this.props

    return (
      <Fragment>
        {this.renderInput()}
        <PortalWrapper open={open}>
          <BDatePickerInput.MobileWrapper>
            <BDatePickerInput.MobileHeader onClick={this.handlePopoverClose}>
              <Icon icon="chevron-left" style="fas" />  <span>{label}</span>
            </BDatePickerInput.MobileHeader>
            {this.renderDatePicker()}
          </BDatePickerInput.MobileWrapper>
        </PortalWrapper>
      </Fragment>
    )
  }

  render () {
    const {
      timeOnly, isMobile, disabled, direction
    } = this.props

    const isOpen = !timeOnly && !disabled && this.state.popoverOpen

    if (isMobile) {
      return (
        this.renderMobile(isOpen)
      )
    } else {
      return (
        <Popover
          direction={direction}
          content={!disabled && this.renderDatePicker}
          open={isOpen}
          onClose={this.handlePopoverClose}
          fallbackPlacements={['top', 'right', 'left']}
          method="manual"
          fixed
        >
          {this.renderInput()}
        </Popover>
      )
    }
  }
}
