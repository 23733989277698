// React
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'
import EventTracker from 'signup/utils/event_tracker'
import Yup from 'shared/utils/yup'

// Components
import Layout from 'signup/components/Layout'
import Button from 'ui/components/Button'
import Div from 'ui/elements/Div'
import Select from 'signup/components/Select'

// Shared
import withForm, { Form, Field } from 'shared/form'
import reduxPureConnect from 'shared/decorators/redux_pure_connect'
import SignupActions from 'signup/redux/actions/signup'
import {
  marketData,
  productData,
  yearBusinessStartData,
  teamSizeData,
  locationSizeData
} from './static/marketingData'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'

export class Step3 extends React.Component {
  static displayName = 'Step3'
  static propTypes = {
    t: PropTypes.func.isRequired,
    values: PropTypes.shape({
      market: PropTypes.string,
      products: PropTypes.arrayOf(PropTypes.string),
      year_business_start: PropTypes.string,
      team_size: PropTypes.string,
      location_size: PropTypes.string
    }),
    submitPromise: PropTypes.instanceOf(Promise)
  }

  componentDidMount = () => {
    EventTracker.page()
    EventTracker.track('Signup: Step 3')
  }

  render = () => {
    const { submitPromise, values, t } = this.props

    return (
      <Layout step={3}>
        <Form>
          <Field
            type="select"
            name="market"
            label={t('signup.fields.market.label')}
            options={marketData(t)}
            showIfValid
            creatable
            formatCreateLabel={(value) => t('signup.add_value', { value })}
            renderNativeOnMobile={false}
            data-tid={'industry'}
          />

          <Field
            type="select"
            name="products"
            label={t('signup.fields.products.label')}
            options={productData(t)[values.market]}
            placeholder={
              !this.props.values.market
                ? t('signup.fields.products.choose_industry')
                : t('signup.fields.choose')
            }
            noOptionsMessage={() => t('signup.fields.products.no_options')}
            description={t('signup.fields.products.description')}
            descriptionTop
            showIfValid
            isMulti
            creatable
            isClearable={false}
            disabled={!this.props.values.market}
            formatCreateLabel={(value) => t('signup.add_value', { value })}
            renderNativeOnMobile={false}
            data-tid={'products'}
          />

          <Select
            identifier="year_business_start"
            options={yearBusinessStartData(t)}
          />

          <Select
            identifier="team_size"
            options={teamSizeData(t)}
          />

          <Select
            identifier="location_size"
            options={locationSizeData(t)}
          />

          <Div modifiers={'margin-top-lg margin-top-md-xl'}>
            <Button
              size="lg"
              type="submit"
              promise={submitPromise}
              block
              borderRadius={'mdplus'}
              modifiers={'margin-bottom-xl'}
            >
              {t('signup.continue')}
            </Button>
          </Div>
        </Form>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  const products = state.signup.products || []
  const normalizedProducts = Array.isArray(products) ? products : products.toArray()

  return {
    initialValues: {
      market: state.signup.market || '',
      products: normalizedProducts,
      year_business_start: state.signup.year_business_start || '',
      team_size: state.signup.team_size || '',
      location_size: state.signup.location_size || ''
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (values) => {
      return SignupActions.update(window.id, values)
    },
    validationSchema: Yup.object().shape({
      market: Yup
        .string()
        .required(),
      products: Yup
        .array()
        .required()
        // Prevent min validation message
        .min(1, props.t('common.errors.mixed.required'))
        .nullable(),
      year_business_start: Yup
        .string()
        .required(),
      team_size: Yup
        .string()
        .required(),
      location_size: Yup
        .string()
        .required()
    }),
    serverSuccess: () => {
      RoutingUtils.navigate(`/${window.locale}/step-4`)
    }
  }
}

export default compose(
  withTranslation('signup'),
  reduxPureConnect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Step3)
