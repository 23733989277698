// React
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect as reduxConnect } from 'react-redux'

// Libraries

// Components
import Bar from 'ui/components/Bar'
import BNoticeBar from 'ui/blocks/NoticeBar'
import Icon from 'ui/components/Icon'

class NoticeBar extends Component {
  static displayName = 'NoticeBar'

  static propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    border: PropTypes.bool,
    icon: PropTypes.string,
    disableScreen: PropTypes.bool,
    onClick: PropTypes.func,
    toggleBar: PropTypes.func
  }

  componentDidMount = () => {
    this.props.toggleBar(true)
  }

  componentWillUnmount = () => {
    this.props.toggleBar(false)
  }

  render () {
    const { children, color, border, icon, disableScreen, onClick } = this.props

    return (
      <BNoticeBar onClick={onClick}>
        {disableScreen && <BNoticeBar.Overlay />}
        {border && <BNoticeBar.Border color={color} />}

        <Bar
          description={
            <Fragment>
              {icon && <Icon icon={icon} />}{' '}
              {children}
            </Fragment>
          }
          type={color}
          notice
          show
          fixedHeight="40px"
          fixedWidth="100vw"
          center
          square
          noIcon
        />
      </BNoticeBar>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggleBar: (value) => dispatch({ type: 'SET_SHOW_BAR', payload: { showBar: value } })
  }
}

export default reduxConnect(null, mapDispatchToProps)(NoticeBar)
