import {
  Bar,
  Container,
  Title,
  Description
} from './styles'

import Icon from 'ui/components/Icon'

Bar.Icon = Icon
Bar.Container = Container
Bar.Title = Title
Bar.Description = Description

export default Bar
