import styled from 'styled-components'
import { fontSize } from 'ui/themes/utils'

const NavigationButton = styled.span`
  position: absolute;
  top: 1.05em;
  font-size: ${fontSize()} !important;

  cursor: pointer;

  transition: opacity .2s ease-in-out;
  will-change: opacity;

  &:hover {
    opacity: 0.3;
  }
`

export const NavigationPrevious = styled(NavigationButton)`
  left: 5%;
`

export const NavigationNext = styled(NavigationButton)`
  right: 5%;
`
