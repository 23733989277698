import styled from 'styled-components'
import { color, unit, size, fontSize, fontWeight } from 'ui/themes/utils'

import I from 'ui/elements/I'
import ContentBlock from 'ui/blocks/ContentBlock'
import H1 from 'ui/elements/H1'
import H2 from 'ui/elements/H2'
import Div from 'ui/elements/Div'

export const Cleanstate = styled(Div)`
  position: relative; /* For possible handwriting */

  ${ContentBlock} {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${props => props.noMinHeight ? '0' : '250'}px;
    margin-bottom: 0;
    text-align: center;
  }

  ${H1} {
    font-size: ${fontSize('xl')};
    font-weight: ${fontWeight('semibold')};
    margin: ${size('margin', 'sm')} 0;
  }

  ${H2} {
    font-size: ${fontSize('lg')};
    font-weight: ${fontWeight('lighter')};
    color: ${color('Text/Secondary')};
  }
`

export const BigIcon = styled.div`
  ${I} {
    color: ${color('Text/Secondary')};
    font-size: ${unit('12')};
  }
`
