import styled, { css } from 'styled-components'
import { size, color, fontWeight, fontSize } from 'ui/themes/utils'

export const DateTimeInput = styled.div`
  /* Set width to 100% + padding variable size to make whole input touchable */
  width: calc(100% + ${size('padding')});

  height: ${size('controlHeight')};

  /* Add padding and negative margin to clear parent padding */
  margin-left: -${size('padding')};
  padding-left: ${size('padding')};

  &&& {
    box-sizing: border-box;

    display: inline-flex;
    align-items: center;

    cursor: pointer;

    ${props => props.disabled && css`
      opacity: 0.9;

      pointer-events: none;
      user-select: none;
    `}
  }
`

export const Input = styled.input`
  &&& {
    box-sizing: border-box;

    display: inherit;
    width: 100%;

    padding: ${size('padding')} 0px ${size('padding')} 2px;
    margin: 0;
    height: ${size('controlHeight')};
    color: ${color('Text/Primary')} !important;

    text-align: center;

    background: none;

    font-size: ${fontSize()};
    font-weight: ${fontWeight('light')};

    border: none;
    border-radius: 0;

    cursor: pointer;
    appearance: textfield;

    &.DD, &.MM, &.HH, &.hh, &.mm, &.YY {
      min-width: 1px;
      width: 1.5em;
      opacity: 1;
    }

    &.A {
      min-width: 1px;

      width: 1.7em;
    }

    &.YYYY {
      min-width: 1px;

      width: 2.6em;
    }

    ::placeholder {
      color: ${color('Text/Tertiary')};
      opacity: 1;
      text-align: left;
    }

    ::-ms-input-placeholder {
      color: ${color('Text/Tertiary')};
      text-align: left;
    }

    :-ms-input-placeholder {
      color: ${color('Text/Tertiary')};
      text-align: left;
    }

    &:focus {
      box-shadow: none;
      background: none;
      outline: none;
    }

    &:hover:not(:focus) {
      box-shadow: none;
      background: none;
    }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outter-spin-button {
      display: none;
      appearance: none;
      margin: 0;
    }

    ${props => props.invalid && css`
      color: ${color('Danger/Base')} !important;
    `}
  }
`

export const Seperator = styled.span`
  box-sizing: border-box;

  font-size: ${fontSize()};

  ${props => props.lighter && css`
    opacity: 0.6;
  `}
`
