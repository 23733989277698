import styled from 'styled-components'

import Div from 'ui/elements/Div'
import Img from 'ui/elements/Img'
import H1 from 'ui/elements/H1'

export const Logo = styled(Div)``

export const Title = styled(H1)`
  /* The title is hidden, but it's still there for SEO purposes */
  font-size: 0;
  line-height: 0;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
`

export const Image = styled(Img)`
  height: 35px;
  margin: 25px 0 0 75px;

  @media (max-width: 625px) {
    margin: 25px 0 0 25px;
  }

  @media (max-width: 800px) {
    margin-top: 30px;
    margin-bottom: 24px;
    width: 170px;
    height: auto;
  }
`
